import { TemplateRef, Type } from '@angular/core';
import { ModelState, PopoverStaticPosition } from '../enums/enums'

export const ROLETITLES = {
    AKSIA_EMPLOYEE: 'AksiaEmployee',
    ADMIN: 'O2-Admin',
    LEGAL_DOC: 'O2-LegalDocTeam',
    DATA: 'O2-DataTeam',
    ONE_PAGER: 'O2-OnePagerTeam',
    INVESTMENT_RESEARCH: 'O2-InvestmentResearchTeam',
    RISK: 'O2-RiskTeam' 
} as const;

export type RoleTitle = typeof ROLETITLES[keyof typeof ROLETITLES];

export const PERMISSIONS = {
    NONE: 'None',
    VIEW: 'View',
    WRITE: 'Write'
} as const;

export type Permission = typeof PERMISSIONS[keyof typeof PERMISSIONS];

export type Credentials = {
    maxusername: string;
    maxpassword: string;
    IPVerificationCode: number;
}

export type User = {
    username: string;
    fullName: string;
    roles: Array<RoleTitle>;
}

export type PopoverCloseEvent<T = any> = {
    type: 'backdropClick' | 'close';
    data: T;
}
  
export type PopoverContent = TemplateRef<any> | Type<any> | string;

export type PopoverParams<T> = {
    origin: HTMLElement;
    content: PopoverContent;
    data?: T;
    width?: string | number;
    height: string | number;
    settings?: PopoverSettings
}
  
export type PopoverSettings = {
    hasBackdrop?: boolean;
    position?: PopoverStaticPosition;
    canDrag?: boolean;
}

export type StateItem = {
    savedValue: any;
    section: string;
    sectionState: ModelState;
    visitsFrom: string;
  }
  

export type ValidationItem = {
    required?: boolean;
    requires?: Array<string>;
    inaudible?: boolean;
    softMin?: any;
    hardMin?: any;
    softMax?: any; 
    hardMax?: any;
    softChange?: any;
    hardChange?: any;
    softEmpty?: boolean;
    hardEmpty?: boolean;
    lessThan?: any;
    moreThan?: any;
    maxChars?: number;
    originalValue?: any;
    currentValue?: any;
    previousValue?: any;
    isDirty?: boolean;
    group?: string;
    visitsFrom?: string;
    hasError?: boolean;
    isSoft?: boolean;
    message?: string;
}
export class validationError {
    message:string
    isSoft:boolean
}

export type InjectInput = {
    name: string,
    type?: any,
    value?: any
}

export type InjectItem = {
    component: Type<any>,
    inputs?: Array<InjectInput>,
    functions?: Array<string>
}

export type CacheItem = {
    cacheInfo: string,
    data: unknown
}

