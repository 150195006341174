import { Transform, Type } from 'class-transformer';
import { AUMRelationEnum, AUMSourceEnum, EntityTypeEnum } from '../../enums/enums';
import { Cell, DefaultValidationMessages, IMetaState, inMetaFactory, meta, Meta, ModelState, SpreadSheetSettings, toEndOfMonth, ValidationStyleEnum } from '@aksia-monorepo/shared-ui';
import { Worksheet } from 'exceljs';
import { HttpErrorResponse } from '@angular/common/http';

@inMetaFactory
export class AUM extends Meta implements IMetaState {
    entityId!: number;
    entityTypeId!: number;
    
    @Type(() => Date)
    @Transform(({value}) => toEndOfMonth(value), { toClassOnly: true })
    @meta({ alias: 'As Of' })
    asOfDate: Date;
    
    @meta({ 
        alias: 'Source', 
        hardNotEmpty: {
            type: ValidationStyleEnum.Hard,
            defaultMessage: DefaultValidationMessages.hardNotEmpty,
            validateRule: (self,value,target,prop) => {
                if (target['amount'] && !value){
                    return self.message = self.defaultMessage;
                }
                else {
                    return self.message = null;
                }
            }
        }
    })
    source?: AUMSourceEnum;

    @meta({ alias: 'Currency' })
    currency: string = 'USD';
    
    @Type(() => Date)
    @Transform(() => new Date())
    effectiveAsOfDate: Date = new Date();

    @meta({
        alias: 'Amount',
        updates: (self, value) => {
            if (self instanceof AUM){
                self.validate('source');
            }
        }
    })
    amount: number;

    get bgColorFromClassification(): string {
        if (this.markedForDeletion) return 'cell-bg-color-red';
        return '';
    }
    
    isDeleted: boolean = false;

    entityIds: string;

    relation: AUMRelationEnum;

    isNew: boolean;

    //TODO: Delete after refactor
    key: number;

    constructor(entityId?: number, entityTypeId?: number, asOf?: Date) {
        super();
        this.entityId = entityId;
        this.entityTypeId = entityTypeId;
        this.asOfDate = asOf;
        this.state = ModelState.Ready;
    }
}









