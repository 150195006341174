import { Component, Input, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {

  @Input() title: string;
  @Input() icon: any;
  @Input() isToggle: boolean = false;
  showContent: boolean = false;
  private popUpClosed$: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleContent() {
    if (this.isToggle){
      this.showContent = !this.showContent;
    }
    else if (!this.showContent){
      fromEvent(document, 'click')
      .pipe(
        takeUntil(this.popUpClosed$),
        skip(1)
      )
      .subscribe((event: MouseEvent) => {
        this.showContent = false;
        this.popUpClosed$.next(true);
      })
      this.showContent = true;
    }
  }

}
