import { InjectionToken, Injector, Pipe, PipeTransform, Type } from '@angular/core';

@Pipe({
  name: 'audit'
})
export class AuditPipe implements PipeTransform {

  public constructor(private injector: Injector){}

  transform(value: unknown, pipeToken: InjectionToken<any> | 'enum', ...args: unknown[]): unknown {
    if (!value) {
      return '-';
    }
    if (!pipeToken) {
      return value;
    }
    if (pipeToken === 'enum') {
      if (isNaN(parseInt(value as string))){
        return (value as string)?.replace(/([A-Z])([a-z])/g, ' $1$2').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/_/g, ' ');
      }
      else if (parseInt(value as string) <= 0){
        return '-';
      }
      else {
        const collection = args[0] as Array<{value: any, key: string}>;
        return collection.find( item => item.key.toString() === value )?.value;
      }
    }
    else {
      let pipe = this.injector.get(pipeToken);
      return pipe.transform(value, ...args);
    }
  }

}
