import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseComponent, UIControlSettings } from '../base/base.component';

@Component({
  selector: 'check-v2',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent extends BaseComponent {

  @Input() 
  set settings(value: UIControlSettings){
    for (const property in value) {
      this._settings[property] = value[property] ?? this._settings[property];
    }
    if (this._settings.e2eId){
      this.attach_e2e_attributes(this._settings.e2eId);
    }
  }

  get settings() {
    return this._settings;
  }

  //#region Overrides

  focusout(){
    super.focusout(!this.value$.value);
  }

  //#endregion
}
