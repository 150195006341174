import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  transform(collection: Array<unknown>, prop: string): unknown {
    prop = 'value._group';
    let values = collection.map( kv => kv['value']);
    values.sort((a,b) => { 
      let a1 = a['_group'] == 'Unmapped' ? '' : `${a['entityTypeId'] + a['_group']}`;
      let b1 = b['_group'] == 'Unmapped' ? '' : `${b['entityTypeId'] + b['_group']}`;
      
      return a1 > b1 ? 1 : -1;
    });
    return values;
  }
}
