/* eslint-disable no-prototype-builtins */
import { getAbbr, toLocalISOString } from '../functions/helpers';
import 'reflect-metadata';
import { BehaviorSubject } from 'rxjs';
import { ModelState, ValidationStyleEnum } from '../enums/enums';
import { ClassConstructor } from 'class-transformer';

export enum DefaultValidationMessages {
    hardNotEmpty = 'Should not be empty',
    hardNotLessThan = 'Should not be less than',
    hardNotLessOrEqualTo = 'Should not be less or equal to',
    hardNotMoreThan = 'Should not be more than',
    hardNotMoreOrEqualTo = 'Should not be more or equal to',
    softNotEmpty = 'Is usually not empty',
    softEqual = 'Is usually equal to',
    softNotLessThan = 'Is usually more than',
    softNotLessOrEqualTo = 'Is usually more or equal to',
    softNotMoreThan = 'Is usually less than',
    softNotMoreOrEqualTo = 'Is usually less or equal to'
};

export type ValidationStructure = {
    value?: string | number, 
    type: ValidationStyleEnum,
    defaultMessage?: string, 
    message?: string, 
    validateRule: (self, value, target?, prop?) => string  
};

export type ValidationResult = Pick<ValidationStructure, "type" | "message">;

export type Validation = ValidationStructure | number | boolean;

export const defaultValidations = {
    hardNotEmpty: {
        value: undefined, 
        type: ValidationStyleEnum.Hard, 
        defaultMessage: DefaultValidationMessages.hardNotEmpty, 
        validateRule: (self, value, target) => { return target.markedForDeletion || value ? self.message = null : self.message = self.defaultMessage; } 
    } as ValidationStructure,
    hardNotLessThan: { 
        value: undefined, 
        type: ValidationStyleEnum.Hard, 
        defaultMessage: DefaultValidationMessages.hardNotLessThan, 
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || (typeof value === 'number' ? value : value?.length) >= self.value ? self.message = null : self.message = `${self.defaultMessage} ${getAbbr(self.value, true)}`; } 
    } as ValidationStructure,
    hardNotLessOrEqualTo: {
        value: undefined, 
        type: ValidationStyleEnum.Hard, 
        defaultMessage: DefaultValidationMessages.hardNotLessOrEqualTo, 
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || (typeof value === 'number' ? value : value?.length) > self.value ? self.message = null : self.message = `${self.defaultMessage} ${getAbbr(self.value, true)}`; } 
    } as ValidationStructure,
    hardNotMoreThan: { 
        value: undefined, 
        type: ValidationStyleEnum.Hard, 
        defaultMessage: DefaultValidationMessages.hardNotMoreThan, 
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || (typeof value === 'number' ? value : value?.length) <= self.value ? self.message = null : self.message = `${self.defaultMessage} ${getAbbr(self.value, true)}`; } 
    } as ValidationStructure,
    hardNotMoreOrEqualTo: { 
        value: undefined, 
        type: ValidationStyleEnum.Hard, 
        defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo, 
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || (typeof value === 'number' ? value : value?.length) < self.value ? self.message = null : self.message = `${self.defaultMessage} ${getAbbr(self.value, true)}`; } 
    } as ValidationStructure,
    softNotEmpty: { 
        value: undefined, 
        type: ValidationStyleEnum.Soft, 
        defaultMessage: DefaultValidationMessages.softNotEmpty,
        validateRule: (self, value, target) => { return target.markedForDeletion || value ? self.message = null : self.message = self.defaultMessage; }  
    } as ValidationStructure,
    softEqual: {
        value: undefined,
        type: ValidationStyleEnum.Soft,
        defaultMessage: DefaultValidationMessages.softEqual,
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || value === self.value ? self.message = null : self.message = `${self.defaultMessage} ${self.value}`; }
    } as ValidationStructure,
    softNotLessThan: { 
        value: undefined, 
        type: ValidationStyleEnum.Soft, 
        defaultMessage: DefaultValidationMessages.softNotLessThan, 
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || (typeof value === 'number' ? value : value?.length) >= self.value ? self.message = null : self.message = `${self.defaultMessage} ${getAbbr(self.value, true)}`; } 
    } as ValidationStructure,
    softNotLessOrEqualTo: { 
        value: undefined, 
        type: ValidationStyleEnum.Soft, 
        defaultMessage: DefaultValidationMessages.softNotLessOrEqualTo, 
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || (typeof value === 'number' ? value : value?.length) >= self.value ? self.message = null : self.message = `${self.defaultMessage} ${getAbbr(self.value, true)}`; } 
    } as ValidationStructure,
    softNotMoreThan: { 
        value: undefined, 
        type: ValidationStyleEnum.Soft, 
        defaultMessage: DefaultValidationMessages.softNotMoreThan, 
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || (typeof value === 'number' ? value : value?.length) <= self.value ? self.message = null : self.message = `${self.defaultMessage} ${getAbbr(self.value, true)}`; } 
    } as ValidationStructure,
    softNotMoreOrEqualTo: { 
        value: undefined, 
        type: ValidationStyleEnum.Soft, 
        defaultMessage: DefaultValidationMessages.softNotMoreOrEqualTo, 
        validateRule: (self, value, target) => { return target.markedForDeletion || !value || (typeof value === 'number' ? value : value?.length) <= self.value ? self.message = null : self.message = `${self.defaultMessage} ${getAbbr(self.value, true)}`; } 
    } as ValidationStructure,
};

const getDefaultValidation = (metaProp: string, value: number | string) => {
    const defaultValidation = {...defaultValidations[metaProp]};
    defaultValidation.value = value; 
    return defaultValidation; 
}; 

export interface IMetaRequest {
    historyLength?: number;
    navigation?: boolean;
    group?: string;
    additionalState?: string;
    alias?: string;
    aliasAlt?: string;
    fullAlias?: string;
    dtoName?: string;
    isInaudible?: boolean;
    type?: any;
    isGlobalAuditor?: boolean;
    auditRoute?: string;
    auditResponseProp?: string;
    source?: Array<{key: any, value: string}>;
    hardNotEmpty?: Validation;
    hardNotLessThan?: Validation;
    hardNotLessOrEqualTo?: Validation;
    hardNotMoreThan?: Validation;
    hardNotMoreOrEqualTo?: Validation;
    softNotEmpty?: Validation;
    softEqual?: Validation;
    softNotLessThan?: Validation;
    softNotLessOrEqualTo?: Validation;
    softNotMoreThan?: Validation;
    softNotMoreOrEqualTo?: Validation;
    
    updates?: (self: unknown, value: unknown) => void;
    adds?: ((self: Meta, value: string, ...args) => void) | string;
    removes?: ((self: unknown, value: string, index: number) => void) | 'soft' | 'hard' | string;
};

export interface IMetaResponse {
    history?: Array<unknown>;
}

export interface IMetaState {
    __metaState?: ModelState;
    updateState?: (state: ModelState, additionalState?: string) => void;
}

export interface IMeta {
    __metaId?: number;
    __metaGroup?: string;
    __metaParent?: IMeta | Array<IMeta>;
    __metaParentProp?: string;
    __metaGrandParent?: IMeta | Array<IMeta>;
    __metaPrevious?: IMeta;
    __metaNext?: IMeta;
    __metaIndex?: number;
    __metaErrorsAndWarnings$?: BehaviorSubject<{ metaId: number, prop: string, list: Array<ValidationResult> }>;
    __metaErrorsAndWarnings?: Array<ValidationResult>;
    __metaExcludeFromNotePath?: boolean
    isDeleted?: boolean;
    markedForDeletion?: boolean;
    
    toJSON?: () => void;
    toPlain?: () => string;
    toClass?: () => void;
    setClassMeta?: (meta: string, metaValue: unknown, prop?: string | symbol) => void;
    getClassMeta?: (meta: string, prop?: string | symbol) => unknown;
    setInstanceMeta?: (meta: string, metaValue: unknown, prop?: string | symbol) => void;
    getInstanceMeta?: (meta: string, prop?: string | symbol) => unknown;
    add?: (prop: string, setModified?: boolean) => void;
    remove?: (prop: string, index: number, setModified?: boolean) => void;
    removeAll?: (prop: string) => void;
    resetIds?: (model?: Meta) => void;
    validate?: (prop: string) => void;
    validateAll?: (entity?: Meta) => void;
    getValidationSummary?:() => ValidationSummary;
    hasValidations?: (prop: string) => boolean;
}

export abstract class Meta implements IMeta {
    __metaId?: number;
    __metaGroup?: string;
    __metaParent?: IMeta | Array<IMeta>;
    __metaParentProp?: string;
    __metaGrandParent?: IMeta | Array<IMeta>;
    __metaPrevious?: IMeta;
    __metaNext?: IMeta;
    __metaIndex?: number;
    __metaErrorsAndWarnings$?: BehaviorSubject<{ metaId: number, prop: string, list: Array<ValidationResult> }>;
    __metaErrorsAndWarnings?: Array<ValidationResult>;
    __metaExcludeFromNotePath?: boolean
    isDeleted?: boolean;
    markedForDeletion?: boolean;
    modifiedOn?: Date;

    //#region Setters/Getters

    get metaId() {
        return this.__metaId;
    }

    set metaId(value) {
        this.__metaId = value;
    }

    get group(){
        return this.__metaGroup;
    }

    set group(value){
        this.__metaGroup = value;
    }

    get parent() {
        return this.__metaParent;
    }

    set parent(value) {
        this.__metaParent = value;
    }

    get parentProp() {
        return this.__metaParentProp;
    }

    set parentProp(value) {
        this.__metaParentProp = value;
    }

    get grandParent() {
        return this.__metaGrandParent;
    }

    set grandParent(value) {
        this.__metaGrandParent = value;
    }

    get previous() {
        return this.__metaPrevious;
    }

    set previous(value) {
        this.__metaPrevious = value;
    }

    get next() {
        return this.__metaNext;
    }

    set next(value) {
        this.__metaNext = value;
    }

    get index() {
        return this.__metaIndex;
    }

    set index(value) {
        this.__metaIndex = value;
    }

    get state() {
        return (this as IMetaState).__metaState;
    }

    set state(value){
        (this as IMetaState).__metaState = value;
    }

    get errorsAndWarnings$(){
        return this.__metaErrorsAndWarnings$;
    }

    get errorsAndWarnings() {
        return this.__metaErrorsAndWarnings$.value;
    }

    get excludeFromNotePath() {
        return this.__metaExcludeFromNotePath;
    }

    set excludeFromNotePath(value) {
        this.__metaExcludeFromNotePath = value;
    }

    //#endregion

    //#region Methods

    setModified(prop: string) {
        this.setClassMeta('modified', true, prop);
    }

    hasModified(prop: string): boolean {
        return this.getClassMeta('modified',prop);
    }

    hasValidations(prop: string) {
        return this.getClassMeta('hasValidations', prop);
    }

    toJSON() {
        const jsonObj: unknown = Object.assign({}, this);
            for (const key of Object.getOwnPropertyNames(this)) {
                if (key.startsWith('__meta')) {
                    delete jsonObj[key];
                }
                else if (key.startsWith('__')) {
                    const dtoName = this.getClassMeta('dtoName',key.replace('__',''));
                    const dtoKey = dtoName ?? key.replace('__','');
                    jsonObj[dtoKey] = jsonObj[key] instanceof Date ? toLocalISOString(jsonObj[key]) : jsonObj[key];
                    delete jsonObj[key];
                }
                else if (key === 'modifiedOn') {
                    jsonObj[key] = toLocalISOString(new Date());
                }
                else if (jsonObj[key] instanceof Date){
                    jsonObj[key] = toLocalISOString(jsonObj[key]);
                }
            }
            return jsonObj;
    }

    static toClass(classRef: { new (...args: any[]) }, payload: object, ctrOptions: any) {
        //TODO: Decide if it will be implemented
        const instance = Object.assign(new classRef(), payload);
        for (let key in instance){
            
        }
        return Object.assign(new classRef, payload);
    }

    toPlain() {
        return JSON.parse(JSON.stringify(this));
    }

    setClassMeta(meta: string, metaValue: unknown, prop: string | symbol = null) {
        SharedMeta.setMeta(`${meta}`,metaValue,this,prop);
    }

    getClassMeta(meta: string, prop?: string | symbol) {
        return SharedMeta.getMeta(`${meta}`, this, prop);
    }

    setInstanceMeta(meta: string, metaValue: unknown, prop: string | symbol = null) {
        SharedMeta.setMeta(`${meta}_${this.__metaId}`,metaValue,this,prop);
    }

    getInstanceMeta(meta: string, prop?: string | symbol) {
        return SharedMeta.getMeta(`${meta}_${this.__metaId}`, this, prop);
    }

    updateState(state: ModelState, additionalState?: string) {
        const ancestorWithState = this.updateAncestorTreeState(state, additionalState);
    }

    private updateAncestorTreeState(state: ModelState, additionalState: string = undefined, generation: number = 0, generationDepth: number = 5): IMetaState | undefined {
        if (this.hasOwnProperty('__group') || !generation){
            this.state = state;
        }
        if ((additionalState && this.hasOwnProperty(additionalState))) {
            this[additionalState] = state;
        }
        else if (generation === generationDepth || !this.parent) {
            return;
        }
        else {
            Array.isArray(this.parent) ? (this.grandParent as Meta).updateAncestorTreeState(state, additionalState, generation + 1) : (this.parent as Meta).updateAncestorTreeState(state, additionalState, generation + 1);    
        }
    }

    getAncestor(generation: number = 0, generationDepth: number = 5) {
        if (generation === generationDepth || !this.parent){
            return this;
        }
        return (this.parent as Meta).getAncestor(generation + 1);
    }

    getAncestorPath(prop: string) {
        if (this.parentProp && !this.excludeFromNotePath){
            prop = Array.isArray(this.parent) ? `${this.parentProp}[${this.index}].${prop}` : `${this.parentProp}.${prop}`;
        }
        if (Array.isArray(this.parent) && this.grandParent) {
            return (this.grandParent as Meta).getAncestorPath(prop);
        }
        else if (this.parent) {
            return (this.parent as Meta).getAncestorPath(prop);
        }
        return prop;
    }

    add(prop: string, setModified: boolean = false, ...args){
        const adds = SharedMeta.getMeta('adds', this, prop);

        if (setModified){
            this.setModified(prop);
        }

        if (typeof adds === 'string'){
            const itemClass = SharedMeta.classStore.get(adds);
            this[prop] = [...(this[prop] ?? []), new itemClass()];
            if (this.hasModified(prop)){
                this[prop].forEach( item => {
                    item.validateAll();
                    item.updateState(ModelState.IsDirty);
                });
            }
            
        }
        else if (typeof adds === 'function'){
            adds(this, prop, args);
        }
    }

    remove(prop: string, index: number, setModified: boolean = false, additionalState: string = undefined){
        const removes = SharedMeta.getMeta('removes', this, prop);

        if (setModified){
            this.setModified(prop);
        }

        if (!removes){
            throw new Error(`Class type of \'${this.constructor.name}\' does not implement 'Removes' in Meta`);
        }
        if (typeof removes === 'string'){
            let collection = [...(this[prop] ?? [])];
            const hardDeleteItem = collection.filter( item => !item.markedForDeletion)[index];
            const realIndex = collection.findIndex(item => item === hardDeleteItem);

            if (removes.includes('soft')){
                const id = removes.split(':')[1] ?? 'id';
                if (collection[realIndex] && collection[realIndex][id]){
                    collection[realIndex].markedForDeletion = true;
                    collection[realIndex].isDeleted = true;
                    this[prop] = collection;
                }
                else {
                    
                    this[prop] = collection.filter( (item, i) => i !== realIndex );
                }
            }
            else {
                this[prop] = collection.filter( (item, i) => i !== index );
            }

            //if (this.hasModified(prop)){
                this[prop].forEach( item => {
                    item.validateAll();
                    item.updateState(ModelState.IsDirty, additionalState);
                });
            //}
        }
        else if (typeof removes === 'function'){
            removes(this,prop,index);
        }
    }
    removeAll(prop: string){
        const removes = SharedMeta.getMeta('removes', this, prop);
        if (typeof removes !== 'string'){
            throw new Error(`Class type of \'${this.constructor.name}\' does not implement 'Removes' in Meta or has a custom implementation`);
        }
        if (removes === 'soft'){
            let collection = [...(this[prop] ?? [])];
            this[prop] = collection.filter( item => item.id);
            this[prop].forEach( item => {
                item.markedForDeletion = true;
                item.isDeleted = true;
            });
        }
        else {
            this[prop] = undefined;
        }
    }

    resetIds(model?: Meta){
        model = model ?? this;
        Object.getOwnPropertyNames(model).forEach(prop => {
            if (prop.startsWith('__meta')){
                return;
            }
            if (prop === 'id') {
                model[prop] = 0;
            }
            else if (Array.isArray(model[prop])){
                model[prop].forEach( item => this.resetIds(item));
            }
            else if (model[prop] instanceof Meta) {
                this.resetIds(model[prop]);
            }
        });
    }

    validate(prop: string) {
        SharedMeta.runValidations(this[prop],this,prop);
    }
    
    validateAll(entity?: Meta) {
        entity = entity ?? this;
        SharedMeta.runAllValidations(entity);
    }

    getValidationSummary(debug: boolean = false){
        const list = { groupEntity: this['classType'], groupName: this[this.group], entity: this['classType'], state: this.state, fields: [], children: [] };
        return SharedMeta.getValidationSummary(this, list, debug);
    }

    //#endregion
    
    constructor(classRef?: { new (...args: any[]) }) {
        this.__metaId = SharedMeta.newUid;
        if (classRef){
            SharedMeta.classStore.set(classRef['classType'],classRef);
        }
        this.modifiedOn = new Date();
    }
}

export interface ValidationPropSummary {
    alias: string,
    errors: Array<string>
}

export interface ValidationSummary {
    groupEntity?: string;
    groupName?: string;
    entity: string, 
    state: ModelState, 
    fields: Array<ValidationPropSummary>,
    children?: ValidationSummary[]
}

export function meta<T = unknown>(meta: IMetaRequest) {
    return (target: T, key: string): void => {
        SharedMeta.updateMetaRequest(meta, target, key);
        const originalDescriptor = Object.getOwnPropertyDescriptor(target, key);
            Object.defineProperty(target, key, {
                enumerable: true,
                set(value) {
                    const instance = this;
                    const currentValue = SharedMeta.getMeta('currentValue', instance, key);
                    
                    if ((currentValue === value) || ((currentValue === undefined || currentValue === null) && (value === undefined || value === null))) {
                        return;
                    }

                    SharedMeta.setMeta('currentValue', value, instance, key);
                    
                    if (originalDescriptor) {
                        originalDescriptor.set.call(instance, value);
                    }

                    if (instance.hasOwnProperty(key) || Object.getPrototypeOf(instance).hasOwnProperty(key)){
                        instance[`__${key}`] = value;
                        SharedMeta.updateMetaResponse(value,instance,key);
                    }
                },
                get() {
                    const instance = this;

                    if (originalDescriptor) {
                        return originalDescriptor.get.call(instance);
                    }
                    return instance[`__${key}`];
                }
            });
        
    }
}

export class SharedMeta {
    static guid = 0;
    static globalAuditors: Map<string,boolean> = new Map();
    static classStore: Map<string,any> = new Map();
    
    static get newUid() {
        SharedMeta.guid++;
        return SharedMeta.guid;
    }

    static updateMetaRequest(meta: IMetaRequest, target: IMeta, prop: string) {
        Object.entries(meta).forEach(([key,value]) => {
            if (defaultValidations.hasOwnProperty(key)) { 
                SharedMeta.setMeta('hasValidations',true,target,prop);
                if(['number','boolean'].includes(typeof meta[key])){
                    value = getDefaultValidation(key,value); 
                }
            }
            SharedMeta.setMeta(key,value,target,prop);
            
            if (SharedMeta.getMeta('hasValidations',target,prop)){
                target.__metaErrorsAndWarnings$ = new BehaviorSubject<{ metaId: number, prop: string, list: Array<ValidationResult> }>(undefined);
            }
            
            if (SharedMeta.getMeta('isGlobalAuditor',target,prop)){
                const alias = target.getClassMeta('alias',prop) as string;
                SharedMeta.globalAuditors.set(alias,false);
            }
       });
    }

    static updateMetaResponse(propValue: number | string, target: IMeta, prop: string) {
        const isGlobalAuditor = SharedMeta.getMeta('isGlobalAuditor', target, prop);
        const historyLength = SharedMeta.getMeta('historyLength', target, prop);
        const navigation = SharedMeta.getMeta('navigation', target, prop);
        const updates = SharedMeta.getMeta('updates', target, prop);
        const hasValidations = SharedMeta.getMeta('hasValidations', target, prop);

        if (isGlobalAuditor) {
            const alias = target.getClassMeta('alias',prop) as string;
            SharedMeta.globalAuditors.set(alias,!!target[prop]);
        }

        if (historyLength) {
            const history = SharedMeta.getMeta(`history_${target.__metaId}`, target, prop) as Array<unknown> ?? []; 
            if (history.length === historyLength) {
                history.splice(1,1);
            }
            history.push(propValue);
            SharedMeta.setMeta(`history_${target.__metaId}`, history, target, prop);
        }

        if (navigation) {
            if (Array.isArray(target[prop])){
                const collection:Array<IMeta> = target[prop];
                const nonDeletedCollection = collection?.filter( item => item && !item.markedForDeletion);
                nonDeletedCollection?.forEach( (item, index) => {
                    item.__metaParent = nonDeletedCollection;
                    item.__metaParentProp = prop;
                    item.__metaGrandParent = target;
                    item.__metaPrevious = index > 0 ? nonDeletedCollection[index - 1] : undefined;
                    item.__metaNext = index < nonDeletedCollection.length - 1 ? nonDeletedCollection[index + 1] : undefined;
                    item.__metaIndex = index;
                    const fullAlias = target.getClassMeta('alias',prop) as string;
                    if (fullAlias){
                        item.setClassMeta('fullAlias',fullAlias.replace('##',`#${index + 1}`));
                    }
                    const auditRoute = target.getClassMeta('auditRoute',prop) as string;
                    if (auditRoute){
                        item.setClassMeta('auditRoute', auditRoute);
                    }
                })
            }
            else if (target[prop]) {
                target[prop].__metaParent = target;
                target[prop].__metaParentProp = prop;
                target[prop].__metaGrandParent = target.__metaParent;
            }
        }

        if (updates) {
            updates(target, propValue);
        }

        if (hasValidations) {
            target.validate(prop);
        }
    }

    static runAllValidations(target: IMeta) {
        for (let key in target){
            if (key.startsWith('__meta')){
                //Do nothing
            }
            else {
                const prop = key.replace('__','');
                SharedMeta.runValidations(target[prop],target,prop);
            }
        }
    }

    static runValidations(propValue: unknown, target: IMeta, prop: string) {
        const validationPrototypes = [...SharedMeta.getValidationPrototypes(target,prop)];
        const errorsAndWarnings: Array<ValidationResult> = [];
        validationPrototypes.forEach( v => {
            if (!target.markedForDeletion){
                const message = v.value.validateRule(v.value, propValue, target, prop);
                if (message){
                    errorsAndWarnings.push({type: v.value.type, message: message});
                }
            }
        });
        target?.__metaErrorsAndWarnings$?.next({ metaId: target.__metaId, prop: prop, list: errorsAndWarnings });
    }

    static getValidationSummary(target: Meta, summary: ValidationSummary, debug?: boolean){
        for (let key in target){
            if (key.startsWith('__')){
                //do nothing
            }
            else if (Array.isArray(target[key]) && target[key][0] instanceof Meta){
                (target[key] as Array<Meta>)?.forEach((item, index) => {
                    const groupEntity = (item['__group']) ? item['classType'] : summary.groupEntity;
                    const groupName = (item['__group']) ? item[item['__group']] : summary.groupName;
                    const validationSummary = {
                        groupEntity: groupEntity,
                        groupName: groupName,
                        entity: item['classType'],
                        fields: [],
                        state: item.state,
                        children: []
                    }
                    SharedMeta.getValidationSummary(item,validationSummary,debug);
                    if (validationSummary.fields?.length > 0 || validationSummary.children?.length > 0 || debug){
                        summary.children = [...(summary.children ?? []),validationSummary];
                    }    
                });
            }
            else if (target[key] instanceof Meta){
                if (!target.getClassMeta('isInAudible',key)){
                    const groupEntity = (target[key]['__group']) ? target[key]['classType'] : summary.groupEntity;
                    const groupName = (target[key]['__group']) ? target[key][target[key]["__group"]] : summary.groupName;
                    const validationSummary = {
                        groupEntity: groupEntity,
                        groupName: groupName,
                        entity: target[key]['classType'],
                        fields: [],
                        state: target[key].state,
                        children: []
                    }
                    SharedMeta.getValidationSummary(target[key],validationSummary,debug);
                    if (validationSummary.fields?.length > 0 || validationSummary.children?.length > 0 || debug){
                        summary.children = [...(summary.children ?? []),validationSummary];
                    }
                }
            }
            else {

                //Check Global Validations first - Return if invalid
                if (target.state !== ModelState.Ready && !SharedMeta.getMeta('isInaudible', target, key) && (target as Meta).hasModified(key)){
                    SharedMeta.globalAuditors.forEach((value, key) => {
                        if (!value){
                            summary.fields.push({ alias: key, errors: [DefaultValidationMessages.hardNotEmpty]})
                        }
                    })
                    if (summary.fields.length){
                        target.state = ModelState.HasError;
                    }
                }

                //Check Individual Validations
                if (SharedMeta.getMeta('hasValidations', target, key)){
                    const grandParent = target.__metaGrandParent? target.__metaGrandParent as IMeta : undefined;
                    const parentFullAlias = grandParent ? SharedMeta.aliasReplacer('fullAlias',grandParent) : undefined;  
                    const fullAlias = parentFullAlias ? parentFullAlias : SharedMeta.aliasReplacer('fullAlias',target);
                    const alias = SharedMeta.getMeta('alias',target,key).replace('##',`#${(target.index + 1)}`);
                    const errors = SharedMeta.getErrors(target[key], target, key);
                    if (errors?.length > 0){
                        summary.fields.push({alias: (fullAlias) ? `${fullAlias} ${alias}` : alias, errors:errors});
                        summary.state = ModelState.HasError;
                    }
                }
            }
        }
        return summary;
    }

    static aliasReplacer(meta, target, key?): string{
        const alias = key ? SharedMeta.getMeta(meta,target,key) : SharedMeta.getMeta(meta,target);
        if (alias?.endsWith('##')){
            return `${alias.replace('##',`#${(target.index + 1)}`)} - ${key}`;
        }
        const matches = alias?.match(/\[([^)]+)\]/);
        if (matches){
            const fieldValue = target[matches[1]];
            if (fieldValue instanceof Date && !isNaN(fieldValue.valueOf())){
                return alias.replace(/\[(.+?)\]/g, `${fieldValue.getMonth() + 1}/${fieldValue.getFullYear()}`);
            }
            return alias.replace(/\[(.+?)\]/g, fieldValue);
        }
        return alias;
    }

    static getValidationPrototypes(target: unknown, prop: string): Array<{key: string, value: ValidationStructure}> {
        const metaPairs = SharedMeta.getAllMeta(Object.getPrototypeOf(target), prop);
        const validationPrototypes:Array<{key, value}> = [];
        metaPairs.forEach( metaPair => {
            if (defaultValidations.hasOwnProperty(metaPair.key)) {
                const validationPrototype = this.getMeta(metaPair.key,target,prop);
                if (validationPrototype){
                    validationPrototypes.push({key: metaPair.key, value: validationPrototype});
                }
            }
        });
        return validationPrototypes;
    }

    static getValidationInstances(target: unknown, prop: string): Array<ValidationStructure> {
        const metaPairs = SharedMeta.getAllMeta(target, prop);
        const validationInstances:Array<ValidationStructure> = [];
        metaPairs.forEach( metaPair => {
            if (defaultValidations.hasOwnProperty(metaPair.key)) {
                const validationInstance = this.getMeta(metaPair.key,target,prop);
                if (validationInstance){
                    validationInstances.push(validationInstance);
                }
            }
        });
        return validationInstances.filter( v => v.message).sort((a,b) => a.type < b.type ? 1 : -1);
    }

    static getErrors(propValue: unknown, target: unknown, prop: string): Array<string> {
        const validationPrototypes = [...SharedMeta.getValidationPrototypes(target,prop)];
        const errors: Array<string> = [];
        validationPrototypes.forEach( v => {
            if (!target['markedForDeletion']){
                const message = v.value.validateRule(v.value, propValue, target, prop);
                if (message && v.value.type === ValidationStyleEnum.Hard){
                    errors.push(message);
                }
            }
        });
        return errors;
    }

    static setMeta(meta: string, metaValue: unknown, target: IMeta, prop: string | symbol = null) {
        Reflect.defineMetadata(meta,metaValue,target,prop);
    }

    static getMeta(meta: string, target: IMeta, prop: string | symbol = null) {
        const metaObj = Reflect.getMetadata(meta,target,prop);
        if (!metaObj){
            SharedMeta.setMeta(meta,undefined,target,prop);
        }
        return metaObj ?? Reflect.getMetadata(meta,target,prop);
    }

    static getAllMeta(target: unknown, prop: string) {
        if (target){
            const keys = Reflect.getOwnMetadataKeys(target,prop);
            return keys.map( key => { 
                return {
                    key: key,
                    meta: Reflect.getMetadata(key,target,prop)
                }
            })
        }
    }

    static searchValueInPath(target: IMeta, props: Array<string>, aliasDelimiter: string = '#'){
        let value;
        if (props?.length === 1){
            if (props[0].includes(aliasDelimiter)){
                const propSplit = props[0].split(aliasDelimiter);
                const prop = propSplit[0];
                const meta = propSplit[1];
                value = target.getClassMeta(meta);
            }
            else {
                value = target[props[0]];
            }
            return value;
        }
        
        const prop = props.shift();
        return SharedMeta.searchValueInPath(target[prop],props);
    }
}

export function inMetaFactory(constructor: ClassConstructor<unknown>){
    const classType = new constructor()['classType'];
    SharedMeta.classStore.set(classType,constructor);
}



