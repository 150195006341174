import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './core/guards/authentication.guard';

const routes: Routes = [

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main', 
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule), 
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'ask-ai',
    loadChildren: () => import('./pages/ask-ai/ask-ai.module').then(m => m.AskAiModule), 
    canActivate: [AuthenticationGuard]  
  },
  {
    path: 'managementcompany/:entityId',
    loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'investmentvehicle/:entityId',
    loadChildren: () => import('./pages/fund/fund.module').then(m => m.FundModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: '**', redirectTo: '/main'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
