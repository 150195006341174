<div class="o2-search-container" [ngClass]="position">
    <div class="flex-start flex-grow-2">
        <input [(ngModel)]="searchValue" [ngClass]="{ 'focused': focused }"
            tabindex="0" 
            (focusin)="focusin()"
            (focusout)="focusout()" [placeholder]="focused ? 'hit enter' : placeholder"
            (keyup)="updateIcon()"
            (keyup.enter)="search()"
        />
        <div *ngIf="!hideBorder" class="border"></div>
    </div>
    <fa-icon *ngIf="!hideIcon" [icon]="faActiveIcon" class="flex-end width-15 font-size-15 color1" 
        [class.Loading]="isLoading" [class.pointer]="faActiveIcon === faTimesCircle" [class.rotating]="faActiveIcon === faSpinner"
        (click)="clearSearch()"></fa-icon>
    <div *ngIf="results?.length > 0" class="o2-search-list">
        <ul *ngFor="let item of results">
            <li (click)="select($event, item)">
                <fa-icon *ngIf="item.icon" [icon]="item.icon ? item.icon : ''" class="margin-right-15 font-size-15 color1"></fa-icon>
                {{labelProperty !== null ? item[labelProperty] : item}}
            </li>
        </ul>
    </div>
</div>
