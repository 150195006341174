import { AfterViewInit, Directive, ElementRef, EventEmitter, inject, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';

@Directive({
  selector: '[UiSticky]',
  exportAs: 'UiSticky',
  inputs: ['stickyClass']
})
export class StickyDirective implements OnDestroy, AfterViewInit {
  public topMarkerRef!: HTMLElement;
  public bottomMarkerRef!: HTMLElement;
  public isStuck: boolean = false;
  public externalIsStuck: boolean = true;
  public stickyClass!: string | undefined;
  private isBottomMarkerVisible: boolean = false;
  private isTopMarkerVisible: boolean = false;
  private observer: IntersectionObserver | null = null;

  @Input() externalMarker: Element;
  @Output() stickyChanged: EventEmitter<boolean> = new EventEmitter(false);
  @Output() externalMarkerHit: EventEmitter<boolean> = new EventEmitter(false);
  
  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}

  ngAfterViewInit(): void {
    this.topMarkerRef = this.elementRef.nativeElement.querySelector('.top-marker');
    this.bottomMarkerRef = this.elementRef.nativeElement.querySelector('.bottom-marker');
    if (this.stickyClass){
      this.initObserver();
    }
  }

  private initObserver() {
    this.observer = new IntersectionObserver(this.handleIntersection);
    this.observer.observe(this.bottomMarkerRef);
    this.observer.observe(this.topMarkerRef);
    if (this.externalMarker) {
      this.observer.observe(this.externalMarker);
    }
  }

  private handleIntersection = ( entries: IntersectionObserverEntry[] ) : void => {
		var previousIsStuck = this.isStuck;
		var nextIsStuck = this.isStuck;
    for ( var entry of entries ) {
 			if ( entry.target === this.bottomMarkerRef ) {
        this.isBottomMarkerVisible = entry.isIntersecting;
 			}
 			if ( entry.target === this.topMarkerRef ) {
        this.isTopMarkerVisible = entry.isIntersecting;
 			}
      if ( entry.target === this.externalMarker ){
        this.externalIsStuck = !this.externalIsStuck;
        this.externalMarkerHit.emit(this.externalIsStuck);
      }
 		}
 		nextIsStuck = ( this.isBottomMarkerVisible && ! this.isTopMarkerVisible );
 
		if ( nextIsStuck === previousIsStuck ) {
    	return;
		}
 
    this.toggleStickyClass(nextIsStuck);
  }

  private toggleStickyClass(nextIsStuck:boolean){
    ( this.isStuck = nextIsStuck )
					? this.elementRef.nativeElement.classList.add( this.stickyClass )
					: this.elementRef.nativeElement.classList.remove( this.stickyClass );
          this.stickyChanged.emit(this.isStuck);
  }

  public ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }

}
