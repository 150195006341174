import { copyToClipboard } from '@aksia-monorepo/shared-ui';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { faCopy, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { ModelState } from '../../../enums/enums';

@Component({
  selector: 'label-v2',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LabelComponent implements OnInit {

  //#region Icons

  faInfoCircle = faInfoCircle;
  faCopy = faCopy;
  
  //#endregion Icons;

  modelState = ModelState;

  @Input() label: string;
  @Input() tooltip: string;
  @Input() state: ModelState; 
  copying: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  ngOnInit(): void {
  }

  copy() {
    copyToClipboard(this.label);
    this.copying.next(true);
    setTimeout(() => { this.copying.next(false); },2000);
    return false;
  }
}
