import {
  DefaultValidationMessages,
  meta,
  Meta,
  ModelState,
  ValidationStyleEnum,
} from '@aksia-monorepo/shared-ui';
import { SimpleAnswerEnum } from '../../enums/enums';
import { Transform } from 'class-transformer';

export class ClosedEndTargetReturnProfile extends Meta {
  closedEndDataFieldsId: number = 0;

  //#region Target Return Profile

  @meta({
    alias: 'Target Returns Gross',
    hardNotLessThan: {
      type: ValidationStyleEnum.Hard,
      defaultMessage:
        'Target Gross IRR cannot be lesser than the Target Net IRR',
      validateRule(self, value, target, prop) {
        if (!value) return (self.message = null);
        if (target instanceof ClosedEndTargetReturnProfile) {
          return (self.message =
            value < target.gpTargetReturnsNet
              ? `${self.defaultMessage}`
              : null);
        }
      },
    },
    auditRoute: 'GPTargetReturnsGross',
  })
  gpTargetReturnsGross?: number;

  @meta({
    alias: 'Target Returns Net',
    hardNotMoreThan: {
      type: ValidationStyleEnum.Hard,
      defaultMessage:
        'Target Net IRR cannot be greater than the Target Gross IRR',
      validateRule(self, value, target, prop) {
        if (!value) return (self.message = null);
        if (target instanceof ClosedEndTargetReturnProfile) {
          return (self.message =
            value > target.gpTargetReturnsGross
              ? `${self.defaultMessage}`
              : null);
        }
      },
    },
    auditRoute: 'GPTargetReturnsNet',
  })
  gpTargetReturnsNet?: number;

  @meta({ alias: 'Expected Annual Distribution' })
  targetAnnualDivident?: number;

  @meta({
    alias: 'Target Returns Gross TVPI',
    hardNotLessThan: {
      type: ValidationStyleEnum.Hard,
      defaultMessage:
        'Target Gross TVPI cannot be lesser than the Target Net TVPI',
      validateRule(self, value, target, prop) {
        if (!value) return (self.message = null);
        if (target instanceof ClosedEndTargetReturnProfile) {
          return (self.message =
            value < target.gpTargetNetMultiple
              ? `${self.defaultMessage}`
              : null);
        }
      },
    },
    auditRoute: 'GPTargetGrossMultiple',
  })
  gpTargetGrossMultiple?: number;

  @meta({
    alias: 'Target Returns Net TVPI',
    hardNotMoreThan: {
      type: ValidationStyleEnum.Hard,
      defaultMessage:
        'Target Net TVPI cannot be greater than the Target Gross TVPI',
      validateRule(self, value, target, prop) {
        if (!value) return (self.message = null);
        if (target instanceof ClosedEndTargetReturnProfile) {
          return (self.message =
            value > target.gpTargetGrossMultiple
              ? `${self.defaultMessage}`
              : null);
        }
      },
    },
    auditRoute: 'GPTargetNetMultiple',
  })
  gpTargetNetMultiple?: number;

  //#endregion

  isDeleted?: boolean;
  modifiedBy?: string;
  modifiedOn?: Date;

  auditURL =
    'basicdata/fund/audit/{0}/closedend/{1}/closedEndReturnProfile/{2}';
  auditURLParams = ['grandParent.fundId@model', 'parent.id@model', '@prop'];

  constructor() {
    super();
    this.state = ModelState.Ready;
    this.excludeFromNotePath = true;
  }
}
