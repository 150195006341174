import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getterByTrigger'
})
export class GetterByTriggerPipe implements PipeTransform {

  transform(getter: unknown, trigger: number): unknown {
    return getter ?? null;
  }

}
