import { forwardRef, Inject, Injectable } from '@angular/core';
import { LogCategory } from '../enums/enums';

@Injectable({
  providedIn: 'root', 
  useClass: forwardRef(() => DefaultLoggingService)
})
export abstract class BaseLoggingService {
  public abstract addInfo(category: LogCategory, message: string, data?: unknown): void;
  public abstract logInfo(message: string): void;
  public abstract logException(error: unknown): void;
}

@Injectable({
  providedIn: 'root'
})
export class DefaultLoggingService implements BaseLoggingService {
  addInfo(category: LogCategory, message: string, data?: unknown){
    console.log(category, message, data);
  }

  logInfo(message: string){
    console.log(message);
  }

  logException(error: unknown){
    console.error(error);
  }
}
