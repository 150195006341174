import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTitle'
})
export class DateTitlePipe implements PipeTransform {

  transform(value: Date, view: string, page: number, pageSize: number, pageChanged: number = 0): string {
    switch (view){
      case 'Day': {
        return `${value.toLocaleDateString('en-US', { month: 'long' })} ${value.getFullYear()}`
      }
      case 'Month': {
        return `${value.getFullYear()}`
      }
      case 'Year': {
        let startYear = Math.floor((value.getFullYear() / 10)) * 10 + page * 10; 
        let endYear = startYear + pageSize;
        return `${startYear} - ${endYear}`;
      }
    }
  }
}
