import { ElementRef } from '@angular/core';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faTimesCircle, faSearch, faSpinner, IconDefinition, faUser, faChartLine, faBezierCurve, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TrackChanges } from '../../decorators';
import { ChangesStrategy } from '../../enums/enums';

@Component({
  selector: 'o2-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  @Input() control: FormControl;
  @Input() label: string = '';
  @Input() results: any[];
  @Input() placeholder: string = '';
  @Input() labelProperty: string = '';
  @Input() isLoading: boolean = false;
  @Input() position: string = 'center';
  @Input() hideIcon: boolean = false;
  @Input() hideBorder: boolean = false;
  @Output() searchText = new EventEmitter<string>();
  @Output() selected = new EventEmitter<any>();

  searchValue: string = '';
  menuHidden: boolean = true;
  focused: boolean;
  required: boolean;
  faActiveIcon: IconDefinition = faSearch;
  faSearch = faSearch;
  faSpinner = faSpinner;
  faTimesCircle = faTimesCircle;
  faUser = faUser;
  faDollarSign = faDollarSign;
  faBezierCurve = faBezierCurve;
  faChartLine = faChartLine;
  private popUpClosed$: Subject<boolean> = new Subject<boolean>();

  constructor(private _elementRef: ElementRef) { }

  @TrackChanges<unknown>('results','updateResults',ChangesStrategy.NonFirst)
  ngOnChanges(): void {}

  updateResults() {
    this.isLoading = false;
    this.updateIcon();
    
    if (this.results?.length > 0){
      return;
    }

    this.results.push({
      entityId: "-1",
      entityTypeId: -1,
      icon: null,
      name: "There are no results founds"
    });
    
  }

  focusin() { 
      this.focused = true 
  }

  focusout() { 
      this.focused = false 
  }

  search(){
    this.menuHidden = false;
    this.isLoading = true;
    this.searchText.emit(this.searchValue);
    this.updateIcon();
    this.addPopupCloseListener();
  }

  select(event: PointerEvent, value: any){
    this.menuHidden = true;
    this.searchValue = '';
    this.results = [];
    if (value.entityTypeId !== -1){
      this.selected.emit({ value: value, ctrlKey: event.ctrlKey });
    }
    this.updateIcon();
  }

  hideMenu(){
    this.menuHidden = true;
  }

  updateIcon(){
    if (this.isLoading){
      this.faActiveIcon = this.faSpinner;
    }
    else if (this.searchValue){
      this.faActiveIcon = this.faTimesCircle
    }
    else {
      this.faActiveIcon = this.faSearch;
    }
  }

  clearSearch(){
    this.searchValue = '';
    this.updateIcon();
  }

  addPopupCloseListener() {
    fromEvent(document, 'click')
      .pipe( 
        takeUntil(this.popUpClosed$)
      )
      .subscribe((event: MouseEvent) => {
        this.searchValue = ''; 
        this.results = [];
        this.updateIcon();
        this.popUpClosed$.next(true);
      })
  }
}
