import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, ElementRef, EventEmitter, forwardRef, Inject, Injector, Input, Output, ViewChild, ViewChildren } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faFilter, faChevronDown, faPlusCircle, faPen, faCheck, IconDefinition, faSearch, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { fromEvent, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { TrackChanges } from '../../../decorators';
import { ChangesStrategy } from '../../../enums/enums';
import { BaseLoggingService } from '../../../services/base-logging.service';
import { BaseComponent, UIControlSettings } from '../base/base.component';

enum DropdownModeEnum {
  idle, selecting, adding, renaming
}

type DropdownSettings = {
  key?: string;
  value?: string;
  unique?: boolean;
  uniqueExcept?: unknown;
  justifyList?: 'Left' | 'Right';
  alignList?: 'Top' | 'Bottom';
  canAdd?: boolean;
  canRename?: boolean;
  canFilter?: boolean;
  canSelectMultiple?: boolean;
  isMenu?: boolean;
  sortAtoZ?: boolean;
  isAsync?: boolean;
}

const defaultDropdownSettings: DropdownSettings = {
  key: 'key',
  value: 'value',
  unique: false,
  justifyList: 'Left',
  alignList: 'Bottom',
  canAdd: false,
  canRename: false,
  canFilter: false,
  canSelectMultiple: false ,
  isMenu: false,
  sortAtoZ: false,
  isAsync: false
}

@Component({
  selector: 'dropdown-v2',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent extends BaseComponent {
  
  protected _source: Array<unknown>;
  filteredSource: any
  //#region Icons

  faActiveIcon: IconDefinition = faSearch;
  faFilter = faFilter;
  faChevronDown = faChevronDown;
  faPlusCircle = faPlusCircle;
  faPen = faPen;
  faCheck = faCheck;
  faSearch = faSearch;
  faSpinner = faSpinner;
  faTimesCircle = faTimesCircle;

  //#endregion Icons;

  mode: DropdownModeEnum = DropdownModeEnum.idle;
  modes = DropdownModeEnum;
  uniqueViolation: boolean = false;
  currentIndex: number;
  private popUpClosed$: Subject<boolean> = new Subject<boolean>();
  
  constructor(injector: Injector, cmpFactoryResolver: ComponentFactoryResolver, elRef: ElementRef, @Inject(BaseLoggingService) logService: BaseLoggingService) {
    super(injector,cmpFactoryResolver,elRef,logService);
    this._settings = {...this._settings, ...defaultDropdownSettings};
  }

  @TrackChanges<unknown>('isLoading','updateIcon',ChangesStrategy.NonFirst)
  ngOnChanges(): void {}
  //#region ElementRefs
  
  @ViewChild('input') input: ElementRef;
  
  //#endregion
  
  //#region Input/Output
  @Input() isLoading: boolean = false;

  @Input() 
  set settings(value: UIControlSettings & DropdownSettings){
    for (const property in value) {
      this._settings[property] = value[property] ?? this._settings[property];
    }
    if (this._settings.e2eId){
      this.attach_e2e_attributes(this._settings.e2eId);
    }
  }

  get settings() {
    return this._settings;
  }

  @Input() 
  set source(source: Array<unknown>){
    this._source = this.settings.sortAtoZ 
                    ? source.sort((a,b) => a[this.settings.value]?.toLowerCase() > b[this.settings.value]?.toLowerCase() ? 1: -1) 
                    : source;
    this.filteredSource = this._source;
  }

  get source() {
    return this._source ?? this._settings.source;
  }

  @Output() valueRenamed: EventEmitter<any> = new EventEmitter();
  @Output() valueAdded: EventEmitter<any> = new EventEmitter();
  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  //#endregion

  //#region Component Specific

  startRenaming(input: HTMLFormElement){
    this.mode = DropdownModeEnum.renaming;
    input.focus();
    input.select();
  }

  startAdding(input: HTMLFormElement){
    this.mode = DropdownModeEnum.adding;
    input.value = "";
    input.focus();
  }

  applyRenaming(value: string){
    this.mode = DropdownModeEnum.idle;
    this.valueRenamed.emit(value);
  }

  applyAdding(value: string){
    this.mode = DropdownModeEnum.idle;
    this.valueAdded.emit(value);
  }

  select(item: unknown) {
    if (this.settings.unique 
      && (this.model.parent as Array<unknown>).some( colItem => colItem[this.prop] === item[this.settings.key]) 
      && this.model[this.prop] !== item[this.settings.key]
      && this.settings.uniqueExcept !== item[this.settings.key]){
      this.uniqueViolation = true;
    }
    else {
      this.uniqueViolation = false;
      if (this.settings.canSelectMultiple){
        this.selectMultiple(item);
      }
      else {
        this.selectSingle(item);
      }
    }
  }

  searchValue: string = '';
  trackBy = (index, item) => item[this.settings.key]
  filter(){
    if(this.settings.isAsync){
      this.onFilter.emit(this.searchValue);
    }else{
      this.filteredSource = this.source.filter( 
        src => (src[this.settings.value] as string).replace(/\s/g, '').toLowerCase().includes(this.searchValue.replace(/\s/g, '').toLowerCase())
      );
    }
  }

  resetOrCancel(input: HTMLFormElement) {
    if (this.mode === DropdownModeEnum.adding || this.mode === DropdownModeEnum.renaming) {
      this.mode = DropdownModeEnum.idle;
      super.focusout(this.value$.value);
      input.value = this.source.find( item => item[this.settings.key] === this.value$.value)[this.settings.value];
    }
    else {
      super.reset();
    }
  }

  onKeyUpEnter(value: string){
    if(this.mode === DropdownModeEnum.adding)
      this.applyAdding(value);
    else if(this.mode === DropdownModeEnum.renaming)
      this.applyRenaming(value);
    else
      super.focusout()
  }

  private selectSingle(item: unknown){
    this.currentIndex = this.source.indexOf(item);
    super.focusout(item[this.settings.key]);
    if (this.settings.isMenu){
      this.value$.next(null);
    }
  }

  private selectMultiple(item: unknown){
    let values = Array.isArray(this.value$.value) ? this.value$.value : [this.value$.value];
    if (values.includes(item[this.settings.key])){
      values = values.filter(v => v !== item[this.settings.key]);
    }
    else values = [...values, item[this.settings.key]];
    this.value$.next(values);
  }

  private closeList() {
    this.mode = DropdownModeEnum.idle;
    this.focused = false;
    this.filteredSource = this.settings.isAsync ? [] : this.source;
    this.searchValue = '';
    this.value$.next(this.value);
    this.popUpClosed$.next(true);
    this.updateIcon();
  }

  updateIcon(){
    if (this.isLoading){
      this.faActiveIcon = this.faSpinner;
    }
    else if (this.searchValue){
      this.faActiveIcon = this.faTimesCircle
    }
    else {
      this.faActiveIcon = this.faSearch;
    }
  }

  clearSearch(){
    this.searchValue = '';
    this.updateIcon();
  }

  //#endregion

  //#region Overrides

  focusin(force?: boolean) {
    this.uniqueViolation = false;
    if (force){
      this.input.nativeElement.focus();
    }
    super.focusin();
    if (this.mode === DropdownModeEnum.idle){
      this.mode = DropdownModeEnum.selecting;
      fromEvent(document, 'click')
      .pipe( 
        takeUntil(this.popUpClosed$),
        skip(1) 
      )
      .subscribe((event: MouseEvent) => {
        const inputFilter = document.querySelector('.filter');
        if(event.target !== inputFilter)
          this.closeList();
      })
                        
    }
  }

  //#endregion
}
