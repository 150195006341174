import { Pipe, PipeTransform } from '@angular/core';
import * as enums from '../enums/enums';

@Pipe({
  name: 'enumLabel'
})
export class EnumLabelPipe implements PipeTransform {
 enums = enums;
 transform(value: string | number, enumType: string): unknown {
   if (isNaN(+value)){
     return ((value as string) ?? '-').replace(/([a-z])([A-Z])/g, '$1 $2');
   }
   else{
    return this.enums[enumType].toKeyValue().find( keyValue => keyValue.key == value)?.value ?? '-';
   }
  }
}
