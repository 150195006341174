import { Injectable } from '@angular/core';
import { SearchResponse, SearchRequest } from '../interfaces/system.interface';
import { BehaviorSubject } from 'rxjs';
import { ApiMicroService, AuthenticationService } from '@aksia-monorepo/shared-ui';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private readonly searchEntities = new BehaviorSubject<SearchResponse>(null);
  readonly searchEntities$ = this.searchEntities.asObservable();

  constructor(
    private api: ApiMicroService,
    private auth: AuthenticationService
  ) { }
  
  public get _searchEntities(): SearchResponse {
    return this.searchEntities.getValue();
  }

  public set _searchEntities(nextSearchEntities: SearchResponse) {
    this.searchEntities.next(nextSearchEntities);
  }

  public getEntitiesApi(term: string, loaderIds: string[]): void {
    if (term === null || term.trim() === '') {
      this._searchEntities = null;
    }

    /* const searchBody: SearchRequest = {
      Query: term
    }; */

    //this.api.post('basicdata/search/main', searchBody)
    this.getEntitiesObservable(term, loaderIds).subscribe(
      {
        next: (response: SearchResponse) => {
          this._searchEntities = response;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error);
          if (error?.status === 401){
            this.auth.clearSecurity();
          }
        }
      }
      
    );
  }

  public getEntitiesObservable(term: string, loaderIds: string[])  {
    const searchBody: SearchRequest = {
      Query: term
    };
    return this.api.post('basicdata/search/main', searchBody);
  }
}
