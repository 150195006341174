import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public isMainLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() { }

  public loading(set: boolean) {
    this.isMainLoading$.next(set);
  }
}
