import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, User } from '@aksia-monorepo/shared-ui';
import { Observable, throwError} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';
import { O2User } from '../classes/authentication/credentials.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private router: Router
    ) { }
    canActivate(next: ActivatedRouteSnapshot, { url }: RouterStateSnapshot): Observable<boolean> {
        return this.authenticationService.ping().pipe(
            map((res: User) => {
              if (res) {
                this.authorizationService.o2User = new O2User(res);
                return true;
              } else {
                this.router.navigateByUrl('login', { state: { redirect: url } });
                return false;
              }
            }),
            catchError((err) => {
              this.router.navigateByUrl('login', { state: { redirect: url } });
              return throwError(false);
            })
          );
    }
}