import { ApiMicroService } from '@aksia-monorepo/shared-ui';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, concat, Observable, of } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';
import { Sector, Tag, Taxonomy } from '../classes/taxonomy/taxonomy.meta';
import { TagCategoryEnum } from '../enums/enums';

@Injectable({
  providedIn: 'root',
})
export class TaxonomyService {
  //#region Properties

  isReady: BehaviorSubject<boolean> = new BehaviorSubject(undefined);

  private readonly _taxonomy = new BehaviorSubject<Taxonomy>(undefined);
  readonly taxonomy$ = this._taxonomy.asObservable();

  public get taxonomy(): Taxonomy {
    return this._taxonomy.getValue();
  }

  public set taxonomy(value: Taxonomy) {
    this._taxonomy.next(value);
  }

  private readonly _tags = new BehaviorSubject<Array<Tag>>(undefined);
  readonly tags$ = this._tags.asObservable();

  public get tags(): Array<Tag> {
    return this._tags.getValue();
  }

  public set tags(value: Array<Tag>) {
    this._tags.next(value);
  }

  //#endregion

  constructor(private api: ApiMicroService) {
    this.taxonomy = Taxonomy;
    this.api.cache = true;
  }

  public loadTaxonomy(): Observable<boolean> {
    return concat(
      this.loadMainHierarchy(),
      this.loadPrimaryRegions(),
      this.loadTags(),
      this.loadOtherSubstrategies()
    ).pipe(
      toArray(),
      concatMap((results: Array<unknown>) => {
        const taxonomy = results[0] as Array<Sector>;
        this.initMainHierarchy(taxonomy);

        const regions = results[1] as Array<Tag>;
        this.initPrimaryRegions(regions);

        const tags = results[2] as Array<Tag>;
        this.initTags(tags);

        const otherSubStrategyTags = results[3] as Array<Tag>;
        this.initOtherSubStrategies(otherSubStrategyTags);

        return of(true);
      })
    );
  }

  public loadMainHierarchy(
    init: boolean = false
  ): Observable<Array<Sector>> | null {
    const ob$ = this.api.get('taxonomy/sector/all');
    if (init) {
      ob$.subscribe((response: Array<Sector>) =>
        this.initMainHierarchy(response)
      );
      return null;
    } else {
      return ob$;
    }
  }

  public loadPrimaryRegions(init: boolean = false): Observable<boolean> {
    const ob$ = this.api.get('taxonomy/primaryRegion/all');
    if (init) {
      ob$.subscribe((response: Array<Tag>) => this.initPrimaryRegions(response));
      return null;
    } else {
      return ob$;
    }
  }

  public loadTags(init: boolean = false): Observable<boolean> {
    const ob$ = this.api.get('taxonomy/crossstrategytag/all');
    if (init) {
      ob$.subscribe((response: Array<Tag>) => this.initTags(response));
      return null;
    } else {
      return ob$;
    }
  }

  public loadOtherSubstrategies(init: boolean = false): Observable<any> | null {
    const ob$ = this.api.get('taxonomy/assetclasssubstrategy/all');
    if (init) {
      ob$.subscribe((response: any) => this.initOtherSubStrategies(response));
      return null;
    } else {
      return ob$;
    }
  }

  public initMainHierarchy(mainHierarchy: Array<Sector>) {
    Taxonomy.mainHierarchy = plainToClass(Sector, mainHierarchy);
  }

  public initPrimaryRegions(primaryRegions: Array<Tag>) {
    Taxonomy.primaryRegions = plainToClass(Tag, primaryRegions);
  }

  public initOtherSubStrategies(tagsDTO: Array<Tag>) {
    const tags = plainToClass(Tag, tagsDTO);
    Taxonomy.hfSubstrategy = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.HFSubstrategy
    );
    Taxonomy.hfSubstrategy.forEach(
      (tag) => (tag.buckets = Tag.linksToBucket(tag.sectorStrategyLinks))
    );

    Taxonomy.pcSubstrategy = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.PCSubstrategy
    );
    Taxonomy.pcSubstrategy.forEach(
      (tag) => (tag.buckets = Tag.linksToBucket(tag.sectorStrategyLinks))
    );

    Taxonomy.peSubstrategy = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.PESubstrategy
    );
    Taxonomy.peSubstrategy.forEach(
      (tag) => (tag.buckets = Tag.linksToBucket(tag.sectorStrategyLinks))
    );

    Taxonomy.raSubstrategy = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.RASubstrategy
    );
    Taxonomy.raSubstrategy.forEach(
      (tag) => (tag.buckets = Tag.linksToBucket(tag.sectorStrategyLinks))
    );

    Taxonomy.reSubstrategy = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.RESubstrategy
    );
    Taxonomy.reSubstrategy.forEach(
      (tag) => (tag.buckets = Tag.linksToBucket(tag.sectorStrategyLinks))
    );

    Taxonomy.loSubstrategy = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.LongOnlySubstrategy
    );
    Taxonomy.loSubstrategy.forEach(
      (tag) => (tag.buckets = Tag.linksToBucket(tag.sectorStrategyLinks))
    );
  }

  public initTags(tagsDTO: Array<Tag>) {
    const tags = plainToClass(Tag, tagsDTO);

    Taxonomy.internalPriorityLevel = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.InternalPriorityLevel
    );

    Taxonomy.geography = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.Geography
    );
    Taxonomy.regionFocus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.RegionFocus
    );
    Taxonomy.countryFocus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.CountryFocus
    );

    Taxonomy.industry = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.Industry
    );
    Taxonomy.industrySector = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.IndustrySector
    );
    Taxonomy.industryGroup = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.IndustryGroup
    );

    Taxonomy.esg = tags.filter((tag) => tag.categoryId === TagCategoryEnum.ESG);
    Taxonomy.wmdOwnership = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.WomanMinoriyDisabledOwnership
    );
    Taxonomy.esgRegulated = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.ESGRegulated
    );
    Taxonomy.diversityCertifications = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.DiversityCertifications
    );

    Taxonomy.marketCapHF = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.MarketCapHF
    );
    Taxonomy.portfolioUtility = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.PortfolioUtility
    );
    Taxonomy.primaryRiskDriver = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.PrimaryRiskDriver
    );
    Taxonomy.secondaryRiskDriver = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.SecondaryRiskDriver
    );
    Taxonomy.stressEquityBeta = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.StressEquityBeta
    );
    Taxonomy.hfPortfolioFocus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.HFPortfolioFocus
    );

    Taxonomy.origination = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.Origination
    );
    Taxonomy.seniority = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.Seniority
    );
    Taxonomy.incomeSource = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.IncomeSource
    );
    Taxonomy.underlyingAssetClass = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.UnderlyingAssetClass
    );
    Taxonomy.marketCapPC = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.MarketCapPC
    );
    Taxonomy.borrowerStatus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BorrowerStatus
    );
    Taxonomy.leverage = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.Leverage
    );
    Taxonomy.pcPortfolioFocus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.PCPortfolioFocus
    );

    Taxonomy.marketCapPE = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.MarketCapPE
    );
    Taxonomy.dealStrategy = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.DealStrategy
    );
    Taxonomy.pePortfolioFocus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.PEPortfolioFocus
    );
    Taxonomy.ownership = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.Ownership
    );
    Taxonomy.buyoutFundStyle = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutFundStyle
    );
    Taxonomy.coInvestmentActivity = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.CoinvestmentActivity
    );
    Taxonomy.priorityLevel = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.PriorityLevel
    );
    Taxonomy.gpLedTransactionTypes = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.GPLedTransactionTypes
    );
    Taxonomy.secondaryInvestmentStage = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.SecondaryInvestmentStage
    );
    Taxonomy.ventureCapitalAndGrowthEquityIndustryExposure = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.VentureCapitalAndGrowthEquityIndustryExposure
    );
    Taxonomy.growthEquityTargetedRevenueGrowth = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.GrowthEquityTargetedRevenueGrowth
    );
    Taxonomy.growthEquityTargetedRevenue = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.GrowthEquityTargetedRevenue
    );
    Taxonomy.growthEquityTargetedProfitability = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.GrowthEquityTargetedProfitability
    );
    Taxonomy.gpStakesTargetedAssetsUnderManagement = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.GPStakesTargetedAssetsUnderManagement
    );
    Taxonomy.northAmericaExposure = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.NorthAmericaExposure
    );
    Taxonomy.europeExposure = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.EuropeExposure
    );
    Taxonomy.asiaAndPacificExposure = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.AsiaAndPacificExposure
    );
    Taxonomy.southOrLatinAmericaExposure = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.SouthOrLatinAmericaExposure
    );
    Taxonomy.africaExposure = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.AfricaExposure
    );
    Taxonomy.buyoutExposureAerospaceAndDefense = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureAerospaceAndDefense
    );
    Taxonomy.buyoutExposureBusinessServices = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureBusinessServices
    );
    Taxonomy.buyoutExposureConsumer = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureConsumer
    );
    Taxonomy.buyoutExposureCommunicationsAndMedia = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureCommunicationsAndMedia
    );
    Taxonomy.buyoutExposureEducation = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureEducation
    );
    Taxonomy.buyoutExposureEnergy = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureEnergy
    );
    Taxonomy.buyoutExposureFinancials = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureFinancials
    );
    Taxonomy.buyoutExposureGovernmentServices = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureGovernmentServices
    );
    Taxonomy.buyoutExposureHealthCare = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureHealthCare
    );
    Taxonomy.buyoutExposureIndustrials = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureIndustrials
    );
    Taxonomy.buyoutExposureMaterials = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureMaterials
    );
    Taxonomy.buyoutExposureSports = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureSports
    );
    Taxonomy.buyoutExposureTechnologyHardware = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureTechnologyHardware
    );
    Taxonomy.buyoutExposureTechnologySoftware = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureTechnologySoftware
    );
    Taxonomy.buyoutExposureTransportationAndLogistics = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureTransportationAndLogistics
    );
    Taxonomy.buyoutExposureRealEstate = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureRealEstate
    );
    Taxonomy.buyoutExposureUtilities = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.BuyoutExposureUtilities
    );
    Taxonomy.gpLedTransactionType = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.GPLedTransactionType
    );
    Taxonomy.lpLedTransactionType = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.LPLedTransactionType
    );
    Taxonomy.strategicPrimariesTransactionType = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.StrategicPrimariesTransactionType
    );
    Taxonomy.secondaryTransactionType0To100Mn = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.SecondaryTransactionType0To100Mn
    );
    Taxonomy.secondaryTransactionType100To250Mn = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.SecondaryTransactionType100To250Mn
    );
    Taxonomy.secondaryTransactionType250To500Mn = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.SecondaryTransactionType250To500Mn
    );
    Taxonomy.secondaryTransactionType500To1000Mn = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.SecondaryTransactionType500To1000Mn
    );
    Taxonomy.secondaryTransactionType1000PlusMn = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.SecondaryTransactionType1000PlusMn
    );
    

    Taxonomy.infraRiskProfile = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.InfraRiskProfile
    );
    Taxonomy.raPortfolioFocus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.RAPortfolioFocus
    );

    Taxonomy.primaryPropertyType = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.PrimaryPropertyType
    );
    Taxonomy.managerProfile = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.ManagerProfile
    );
    Taxonomy.usRegionalFocus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.USRegionalFocus
    );
    Taxonomy.investmentStrategyDetails = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.InvestmentStrategyDetails
    );
    Taxonomy.rePortfolioFocus = tags.filter(
      (tag) => tag.categoryId === TagCategoryEnum.REPortfolioFocus
    );
  }
}
