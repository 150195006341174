import { Meta, IMetaState, meta, toLocalDate, ModelState } from "@aksia-monorepo/shared-ui";
import { Transform, Expose, Type, ClassTransformer } from "class-transformer";
import { ResetOrchestrator, resetBy } from "../../decorators/reset.decorator";
import { CurrencyEnum, SimpleAnswerEnum, CalendarUnitEnum, MgmtFeeFrequencyEnum, IncFeeRateTypeEnum, ValidationType, HighWaterMarkEnum, DataSourceEnum } from "../../enums/enums";
import { Lockup, Gate, MgmtFeePeriod, Discount, IncFeeRate } from "../entities/entities.model";

export class HybridShareClass extends Meta implements IMetaState {
    classType = 'HybridShareClass';
    classId: number = null;
    fundId: number = null;
    className: string;
    isDefault = false;
    isDeleted = false;

    @meta({ alias: 'Source' })
    source?: DataSourceEnum;

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'As of' })
    asOfDate?: Date;

    //#region <<< Section - Minimum Investment >>>

    minimumLPCommitment?: number;
    minimumLPCommitementCurrency: CurrencyEnum = CurrencyEnum.USD;
    
    //#endregion

    //#region <<< Section - Redemptions >>>

    private _hasLockup?: SimpleAnswerEnum = null;
    
    @Expose()
    get hasLockup() {
        if (this.lockups?.length > 0){
            this._hasLockup = SimpleAnswerEnum.Yes;
        }
        return this._hasLockup;
    }

    set hasLockup(value) {
        this._hasLockup = value;
        if (value != SimpleAnswerEnum.Yes) {
            ResetOrchestrator.reset(this, 'hasLockup');
        }
        else {
            this.addLockup();
        }
    }

    @resetBy('hasLockup')
    lockupEndAllow?: boolean = false;

    @resetBy('hasLockup')
    @Type(() => Lockup)
    lockups: Lockup[] = null;

    addLockup(shareClass = this){
        /* let lockup = new Lockup({});
        if (shareClass.lockups?.length > 0){
            lockup.lockupDurationMinRange = shareClass.lockups[shareClass.lockups.length - 1].lockupDurationMaxRange + 1;
            lockup.lockupDurationMaxRange = lockup.lockupDurationMinRange + 1;
            shareClass.lockups.push(lockup);
        }
        else {
            shareClass.lockups = [lockup];
        } */
    }

    _redemptionTermsFrequencyAmount?: number = null;

    @Expose()
    get redemptionTermsFrequencyAmount() {
        return this._redemptionTermsFrequencyAmount;
    }

    set redemptionTermsFrequencyAmount(value) {
        this._redemptionTermsFrequencyAmount = value;
        if (!value) {
            ResetOrchestrator.reset(this, 'hasFrequency');
        }
    }

    @resetBy('hasFrequency')
    redemptionTermsFrequency?: CalendarUnitEnum = CalendarUnitEnum.Quarters;

    @resetBy('hasFrequency')
    redemptionTermsFrequencyDay?: string = null;

    @resetBy('hasFrequency')
    redemptionTermsNotice?: number = null;

    @resetBy('hasFrequency')
    redemptionTermsNoticePeriod?: number = null;

    @resetBy('hasFrequency')
    redemptionFee?: number = null;

    private _hasGate?: SimpleAnswerEnum = null;

    @Expose()
    get hasGate() {
        if (this.gates?.length > 0){
            this._hasGate = SimpleAnswerEnum.Yes;
        }
        return this._hasGate;
    }

    set hasGate(value) {
        this._hasGate = value;
        if (value != SimpleAnswerEnum.Yes) {
            ResetOrchestrator.reset(this, 'hasGate');
        }
        else {
            //this.gates = [new Gate({})];
        }
    }
    
    @resetBy('hasGate')
    @Type(() => Gate)
    gates: Gate[] = null;

    @resetBy('hasGate')
    holdback?: number = null;

    //#endregion

    //#region <<< Section - Fees >>>

    private _mgmtFee: SimpleAnswerEnum = null;

    @Expose()
    get mgmtFee() {
        return this._mgmtFee;
    }

    set mgmtFee(value) {
        this._mgmtFee = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasMgmtFee');
        }
        else {
            //this.mgmtFeePeriods = [new MgmtFeePeriod(this.classId, EntityTypeEnum.Shareclass)];
        }
    }

    @resetBy('hasMgmtFee')
    @Transform((model) => { model.value?.forEach(element => { element.entityId = model.obj.classId; }); return model.value }, { toClassOnly: true})
    mgmtFeePeriods: Array<MgmtFeePeriod> = null;

    @resetBy('hasMgmtFee')
    paymentFrequency: MgmtFeeFrequencyEnum = null;

    @resetBy('hasMgmtFee')
    mgmtFeeOffsetRate: number = null;

    private _hasMgmtFeeDiscounts = null;

    @Expose()
    @resetBy('hasMgmtFee')
    get hasMgmtFeeDiscounts() {
        /* if (this.mgmtFeeDiscounts?.filter(d => !d.markedForDeletion)?.length > 0) {
            this._hasMgmtFeeDiscounts = SimpleAnswerEnum.Yes;
        } */
        return this._hasMgmtFeeDiscounts;
    }

    set hasMgmtFeeDiscounts(value) {
        this._hasMgmtFeeDiscounts = value;
        if (value != SimpleAnswerEnum.Yes) {
            this.mgmtFeeDiscounts = null;
        }
        else {
            //this.mgmtFeeDiscounts = [new Discount({})];
        }
    }

    @Type(() => Discount)
    mgmtFeeDiscounts: Discount[];

    private _hasIncentiveFee: SimpleAnswerEnum = null;

    @Expose()
    @resetBy('hasIncentiveFeeRateType')
    get hasIncentiveFee() {
        return this._hasIncentiveFee;
    }

    set hasIncentiveFee(value){
        this._hasIncentiveFee = value;
        if (value != SimpleAnswerEnum.Yes) {
            ResetOrchestrator.reset(this, 'hasIncentiveFee');
        }
    }
    
    private _incFeeRateType: IncFeeRateTypeEnum = null;

    @Expose()
    get incFeeRateType() {
        return this._incFeeRateType;
    }

    set incFeeRateType(value) {
        if (!value){
            ResetOrchestrator.reset(this, 'hasIncentiveFeeRateType');
        }
        this._incFeeRateType = value;
        const hasHurdle = (value == IncFeeRateTypeEnum.SingleRate) ? null : SimpleAnswerEnum.Yes;
        //this.incFeeRates = [new IncFeeRate({})];
    }

    @resetBy('hasIncentiveFee')
    incFeeRates: Array<IncFeeRate> = null;

    addIncFeeRate(incFeeRates: Array<IncFeeRate>) {
        //incFeeRates.push(new IncFeeRate({}));
    }

    @resetBy('hasIncentiveFee')
    crystalization?: number = null;

    crystalizationPeriod?: CalendarUnitEnum = CalendarUnitEnum.Years;

    @resetBy('hasIncentiveFee')
    highwaterMark?: HighWaterMarkEnum = HighWaterMarkEnum.StandardHWM;

    private _hasIncentiveFeeDiscounts: SimpleAnswerEnum = null;

    @Expose()
    @resetBy('hasIncentiveFee')
    get hasIncentiveFeeDiscounts() {
        /* if (this.incentiveFeeDiscounts?.filter(d => !d.markedForDeletion)?.length > 0) {
            this._hasIncentiveFeeDiscounts = SimpleAnswerEnum.Yes;
        } */
        return this._hasIncentiveFeeDiscounts;
    }
    
    set hasIncentiveFeeDiscounts(value) {
        this._hasIncentiveFeeDiscounts = value;
        if (value != SimpleAnswerEnum.Yes) {
            this.incentiveFeeDiscounts = null;
        }
        else {
            //this.incentiveFeeDiscounts = [new Discount({})];
        }
    }
    
    @Type(() => Discount)
    incentiveFeeDiscounts: Discount[] = null;
 
    //#endregion
 
    addMgmtFeeTime(mgmtFeeTimes: Array<MgmtFeePeriod>){
        //mgmtFeeTimes.push(new MgmtFeePeriod());
    }

    addDiscount(discounts: Array<Discount>){
        //discounts.push(new Discount({}));
    }

    addToCollection(collection: Array<unknown>, classType: ClassTransformer){
        collection.push
    }

    removeFromCollection(collection: Array<any>, index: number){
        if (collection[index].id > 0){
            collection[index].markedForDeletion = true;
        }
        else {
            collection.splice(index, 1);
        }
    }

    @Expose({ groups: ['meta'] })
    shareClassAuditURL = (field: string | Array<string>, collection: string = ''): string => {
        let url = '';
        if (Array.isArray(field)){
            url = 'shareclass/audit/hybrid/?propertyPaths=' + field.reduce((acc, val) => acc.concat(`ShareClass_${this.classId}%40${collection}%40${val}`),[]).join('%2C');
        }
        else {
            url = `shareclass/audit/hybrid/?propertyPaths=ShareClass_${this.classId}${collection ? '%40' + collection : ''}%40${field}`
        }
        return url;
    }

    constructor(fundId: number, className?: string, isDefault = false){
        super();
        this.fundId = fundId;
        this.state = ModelState.Ready;
        this.className = className ?? 'Default Class';
        this.isDefault = isDefault;
    }
}