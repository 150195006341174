import { meta, Meta, ModelState } from "@aksia-monorepo/shared-ui";
import { Type } from "class-transformer";
import { LeverageTypeEnum } from "../../enums/enums";
import { Leverage } from "../entities/entities.model";
import { Fund } from "../fund/fund.model";

export class LeverageMeta extends Meta {

    @Type(() => Leverage)
    @meta({
        alias: 'Fund Leverage ##',
        navigation: true,
        adds: (self, prop) => {
            if (self instanceof LeverageMeta){
                const leverage = new Leverage();
                leverage.leverageType = LeverageTypeEnum.Fund;
                self[prop] = [...(self[prop] ?? []), leverage];
    
                const fund = (self.parent as Fund);
                self.syncLeverageEntity(fund);
            }
            
        },
        removes: (self, prop, index) => {
            if (self instanceof LeverageMeta){
                self[prop] = self[prop].filter( (item, i) => i !== index );

                const fund = (self.parent as Fund);
                self.syncLeverageEntity(fund);
            }
        }
         
    })
    fundLeverage?: Array<Leverage>;

    @Type(() => Leverage)
    @meta({
        alias: 'Asset Leverage ##',
        navigation: true,
        adds: (self, prop) => {
            if (self instanceof LeverageMeta){
                const leverage = new Leverage();
                leverage.leverageType = LeverageTypeEnum.Asset;
                self[prop] = [...(self[prop] ?? []), leverage];
    
                const fund = (self.parent as Fund);
                self.syncLeverageEntity(fund);
            }
            
        },
        removes: (self, prop, index) => {
            if (self instanceof LeverageMeta){
                self[prop] = self[prop].filter( (item, i) => i !== index );

                const fund = (self.parent as Fund);
                self.syncLeverageEntity(fund);
            }
        }
         
    })
    assetLeverage?: Array<Leverage>;

    public syncLeverageMeta(fund: Fund) {
        this.fundLeverage = fund.leverage.filter( lev => lev.leverageType === LeverageTypeEnum.Fund);
        this.assetLeverage = fund.leverage.filter( lev => lev.leverageType === LeverageTypeEnum.Asset);
    }

    public syncLeverageEntity(fund: Fund) {
        fund.leverage = [...(this.fundLeverage ?? []), ...(this.assetLeverage ?? [])];
        fund.state = ModelState.IsDirty;
    }

    constructor() {
        super();
        this.excludeFromNotePath = true;
    }
}