import { Inject, Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CacheItem, LocalStorageService, StorageTypeEnum, ENVIRONMENT, Environment } from '@aksia-monorepo/shared-ui';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
    constructor(
        @Inject(ENVIRONMENT) private env: Environment,
        private localStorageService: LocalStorageService
    ) { }
    
    public static cacheStatusHeader: string = 'X-Aksia-ResponseCache-Unchanged';
    public static cacheInfoHeader: string = 'X-Aksia-ResponseCache-Info';
    private cacheKey: string;
    private cacheItem: CacheItem;

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.env.storageType !== StorageTypeEnum.Database) {
            this.cacheKey = req.params.get('Route');
            this.cacheItem = this.localStorageService.get(this.cacheKey);
            if (this.cacheItem?.cacheInfo){
                req = req.clone({
                    headers: req.headers.set(CacheInterceptor.cacheInfoHeader,this.cacheItem.cacheInfo)
                });
            }
        }
        return next.handle(req).pipe(
            map(evt => {
                if (evt instanceof HttpResponse) {
                    const cacheUnchanged = evt.headers.get(CacheInterceptor.cacheStatusHeader);
                    if (cacheUnchanged === 'False') {
                        this.setCache(evt);
                        /* this.cacheItem = { 
                            cacheInfo: evt.headers.get(CacheInterceptor.cacheInfoHeader),
                            data: evt.body
                        };
                        this.localStorageService.set(this.cacheKey, this.cacheItem); */
                    }
                    else if (cacheUnchanged === 'True') {
                        this.cacheItem = this.localStorageService.get(this.cacheKey) ?? this.setCache(evt);
                        evt = evt.clone({body: this.cacheItem.data});
                    }
                    return evt;
                }
            })
        );
    }

    setCache(evt: HttpResponse<any>) {
        this.cacheItem = { 
            cacheInfo: evt.headers.get(CacheInterceptor.cacheInfoHeader),
            data: evt.body
        };
        this.localStorageService.set(this.cacheKey, this.cacheItem);
        return this.cacheItem;
    }
}