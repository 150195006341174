import { EntityTypeEnum } from "../../enums/enums";

export class Note {
    entityId: number;
    entityTypeId: EntityTypeEnum;
    fieldName: string;
    modifiedBy: string;
    modifiedOn: Date;
    note: string;
    system: string = 'O2';
    
    _path?: string = null;
    _label?: string = null;
    _group?: string;
    _isDirty?: boolean = false;
    constructor(entityId, entityTypeId) {
        this.entityId = entityId;
        this.entityTypeId = entityTypeId;
    }
}