import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CsrfHeaderInterceptor implements HttpInterceptor {
  public static headerName: string = 'X-XSRF-TOKEN';
  public static cookieName: string = 'XSRF-TOKEN';
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var requestToken = this.getCookieValue(this.getNameWithEnvironment(CsrfHeaderInterceptor.cookieName));
    return next.handle(req.clone({
      headers: req.headers.set(this.getNameWithEnvironment(CsrfHeaderInterceptor.headerName), requestToken)
    }));
  }
  
  private getCookieValue(cookie: string) {
    const allCookies = decodeURIComponent(document.cookie)?.split('; ');
    for (let i = 0; i < allCookies.length; i++) {
      const currentCookie = allCookies[i];
      if (currentCookie.toLowerCase().startsWith(cookie.toLowerCase() + '=')) {
        return currentCookie.substring(cookie.length + 1);
      }
    }
    return '';
  }
  
  private getNameWithEnvironment(name){
    try 
    {
      return `${name}-${environment.environmentVariable.toUpperCase()}`;
    }catch{
      return name;
    }
  }
}
