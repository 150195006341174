import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FromJSONPipe, SharedUiModule } from '@aksia-monorepo/shared-ui';

import { AuditPipe } from '../core/pipes/audit.pipe';
import { EnumLabelPipe } from '../core/pipes/enum-label.pipe';
import { GroupArrayPipe } from '../core/pipes/group-array.pipe';
import { ArrayFilterPipe } from '../core/pipes/array-filter.pipe';
import { StatePipe } from '../core/pipes/state.pipe';

import { HistoryComponent } from './history/history.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageSectionComponent } from './page-section/page-section.component';
import { PageSectionGroupComponent } from './page-section-group/page-section-group.component';
import { PageSectionRowComponent } from './page-section-row/page-section-row.component';
import { NoteComponent } from './note/note.component';
import { IsLaterPipe } from '../core/pipes/is-later.pipe';
import { BadgeComponent } from './badge/badge.component';
import { PageNavigationComponent } from './page-navigation/page-navigation.component';

@NgModule({
    providers: [
        AuditPipe, 
        FromJSONPipe, 
        EnumLabelPipe, 
        GroupArrayPipe,
        ArrayFilterPipe,
        StatePipe,
        IsLaterPipe
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        SharedUiModule
    ],
    declarations: [
        AuditPipe,
        EnumLabelPipe,
        GroupArrayPipe,
        ArrayFilterPipe,
        StatePipe,
        IsLaterPipe,
        HistoryComponent,
        PageHeaderComponent,
        PageSectionComponent,
        PageSectionGroupComponent,
        PageSectionRowComponent,
        NoteComponent,
        BadgeComponent,
        PageNavigationComponent
    ],
    exports: [
        SharedUiModule,
        HistoryComponent,
        PageHeaderComponent,
        PageSectionComponent,
        PageSectionGroupComponent,
        PageSectionRowComponent,
        PageNavigationComponent,
        NoteComponent,
        BadgeComponent,
        EnumLabelPipe,
        ArrayFilterPipe,
        StatePipe,
        IsLaterPipe,
    ] 
})
export class SharedO2Module {}