import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { plainToClass } from 'class-transformer';
import { AumService, BulkAUM } from './aum.service';
import {
  AUMSourceEnum,
  CurrencyEnum,
  DataSourceEnum,
  EntityTypeEnum,
} from '../enums/enums';
import { AUM } from '../classes/aum/aum.model';
import { ManagementCompany, Program } from '../classes/company/company.model';
import { LoadingService } from './loading.service';
import { NoteService } from './note.service';
import { EntityService } from './entity.service';
import {
  AuthenticationService,
  ApiMicroService,
  ModelState,
} from '@aksia-monorepo/shared-ui';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { IErrorResult, NoteDTO } from '../interfaces/system.interface';
import { AUMeta } from '../classes/aum/aum.meta';
import { HttpErrorResponse } from '@angular/common/http';
import { Worksheet, Comment, CellFormulaValue } from 'exceljs';
import { Title } from '@angular/platform-browser';
import { TaxonomyService } from './taxonomy.service';
import { Address } from '../classes/entities/entities.model';
import { CompanyUtils } from '../classes/company/company.utils';
import { Stream } from '../classes/stream/stream.model';
import { StreamMeta } from '../classes/stream/stream.meta';
import { PERIODIC_STREAMS } from './periodic-streams.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends EntityService {
  private datePipe: DatePipe = new DatePipe('en-US');
  private declinedNotes: Array<{ key: string; value: string; desc: string }>;
  public importError = { type: null, message: null };

  constructor(
    private auth: AuthenticationService,
    private api: ApiMicroService,
    private aumService: AumService,
    private noteService: NoteService,
    protected taxService: TaxonomyService,
    loadingService: LoadingService,
    title: Title
  ) {
    super(loadingService, title, taxService);
  }

  //#region Public Methods

  public getCompany(entityId: number): ManagementCompany {
    //Reset Params
    this.importError = { type: null, message: null };
    this.noteService._o2Notes.clear();

    this.endPointsRequesting = 3;
    this.api.get(`basicdata/managementcompany/${entityId}`).subscribe(
      (response: any) => {
        this.endPointsRequesting += response.investmentPrograms.length;
        response.investmentPrograms = response.investmentPrograms.sort((a, b) =>
          a.name < b.name ? -1 : 1
        );
        this.localCompany = plainToClass(ManagementCompany, response);
        this.localCompany.addresses?.forEach(
          (addr, i) =>
            (addr.countryState = response.addresses?.find((addrDTO) =>
              CompanyUtils.compareAddress(addrDTO, addr)
            )?.state)
        );
        this.localCompany.funds = (response.investmentPrograms as any)?.flatMap(
          (ip) =>
            (ip as Program).funds?.map((fund) => {
              return { key: fund.fundId, value: fund.name };
            })
        );

        //AUM
        this.aumService
          .getHistoricalStream(
            entityId,
            PERIODIC_STREAMS.AUM,
            EntityTypeEnum.ManagementCompany
          )
          .subscribe(
            (aumPlain: BulkAUM) => {
              let datapoints = aumPlain.aum
                .filter(
                  (point) =>
                    point.entityTypeId == EntityTypeEnum.ManagementCompany
                )
                .map((point) => {
                  let { amount, asOfDate, ...rest } = point;
                  return {
                    value: amount,
                    asOf: asOfDate,
                    isAUM: true,
                    isNew: !amount,
                    ...rest,
                  };
                });

              const stream: Array<Stream> = plainToClass(
                Stream,
                datapoints
              ).sort((a, b) => a.asOf.getTime() - b.asOf.getTime());

              let aumMeta = new StreamMeta(
                this.localCompany.managementCompanyId,
                EntityTypeEnum.ManagementCompany,
                this.localCompany.getInceptionDate(),
                this.localCompany.name,
                PERIODIC_STREAMS.AUM,
                this.aumService
              );
              this.localCompany.aumMeta = plainToClass(StreamMeta, aumMeta);
              this.localCompany.aumMeta.initStream(
                stream,
                this.localCompany.getInceptionDate()
              );

              this.localCompany.aumMeta.updateSpreadsheet();

              this.localCompany.aumMeta.streamSpreadSheet.hasFullTrackRecord =
                true;
              this.localCompany.aumMeta.streamSpreadSheet.isVisible = true;
              this.localCompany.aumMeta.streamSpreadSheet.showHistoryToggle =
                true;
              this.localCompany.aumMeta.streamSpreadSheet.showCloseToggle =
                false;
              this.localCompany.aumMeta.selectedCurrency =
                this.localCompany.aumMeta.stream?.find(
                  (point) => point.currency
                )?.currency;

              this.localCompany.investmentPrograms.forEach((ip) => {
                let datapoints = aumPlain.aum
                  .filter(
                    (point) =>
                      point.entityTypeId == EntityTypeEnum.Program &&
                      point.entityId == ip.investmentProgramId
                  )
                  .map((point) => {
                    let { amount, asOfDate, ...rest } = point;
                    return {
                      value: amount,
                      asOf: asOfDate,
                      isAUM: true,
                      isNew: !amount,
                      ...rest,
                    };
                  });

                const stream: Array<Stream> = plainToClass(
                  Stream,
                  datapoints
                ).sort((a, b) => a.asOf.getTime() - b.asOf.getTime());

                let aumMeta = new StreamMeta(
                  ip.investmentProgramId,
                  EntityTypeEnum.Program,
                  ip.inceptionDate,
                  ip.name,
                  PERIODIC_STREAMS.AUM,
                  this.aumService
                );
                ip.aumMeta = plainToClass(StreamMeta, aumMeta);

                ip.aumMeta.classType = 'ProgramAUMeta';

                ip.aumMeta.initStream(stream, ip.inceptionDate);
                ip.aumMeta.updateSpreadsheet();

                console.warn('COMPLETED Program AUM');
                this.endPointResponsed();
              });

              console.warn('COMPLETED AUM');
              this.endPointResponsed();
            },
            (error) => {
              console.error(error);
              this.endPointResponsed();
            }
          );

        //Get Notes
        this.api
          .get(`metadata/${entityId}/${EntityTypeEnum.ManagementCompany}`)
          .subscribe((response) => {
            response.forEach((noteDTO) => {
              if (noteDTO.note) {
                let noteKV = this.noteService.toMapModel(
                  noteDTO,
                  this.localCompany
                );
                if (noteKV) {
                  const metaId = this.localCompany.metaId;
                  this.noteService._o2Notes.set(
                    `${noteKV.key}_${metaId}`,
                    noteKV.noteValue
                  );
                }
              }
            });
            console.warn('COMPLETED Notes');
            this.endPointResponsed();
          });

        console.warn('COMPLETED Company');
        this.endPointResponsed();
      },
      (error) => {
        console.error(error);
        this.endPointResponsed();
      }
    );
    return this.company;
  }

  public getInvestmentProgram(entityId: number): Observable<Program> {
    return this.api.get(`basicdata/investmentprogram/${entityId}`);
  }

  public savePage() {
    this.validateAndPrepare(EntityTypeEnum.ManagementCompany);

    if (
      [
        this.company.state,
        ...this.company.investmentPrograms.map((ip) => ip.aumMeta.state),
        this.company.aumMeta.state,
        this.company.programAUMState,
      ].includes(ModelState.HasError)
    ) {
      this.hasError = true;
      //return;
    }

    const isCompanyDirty = this.company.state === ModelState.IsDirty ? 1 : 0;
    const dirtyCompanyAUM =
      this.company.aumMeta.stream?.filter(
        (streamPoint) => streamPoint.state === ModelState.IsDirty
      ) ?? [];
    const dirtyProgramsByField = this.company.investmentPrograms?.filter(
      (program) => program.state === ModelState.IsDirty
    );
    const dirtyProgramsByAUM = this.company.investmentPrograms?.filter(
      (program) => program.aumMeta.state === ModelState.IsDirty
    );
    const areNotesDirty = this.noteService.state === ModelState.IsDirty ? 1 : 0;
    this.endPointsRequesting +=
      isCompanyDirty +
      (dirtyCompanyAUM?.length > 0 ? 1 : 0) +
      dirtyProgramsByField?.length +
      dirtyProgramsByAUM?.length +
      areNotesDirty;

    if (isCompanyDirty) {
      this.saveCompany();
    }

    if (
      dirtyProgramsByField?.length > 0 &&
      this.company.programState === ModelState.IsDirty
    ) {
      this.savePrograms(dirtyProgramsByField);
    }

    if (
      dirtyProgramsByAUM?.length > 0 &&
      this.company.programAUMState === ModelState.IsDirty
    ) {
      this.saveProgramAUM(dirtyProgramsByAUM);
    }

    if (
      dirtyCompanyAUM?.length > 0 &&
      this.company.aumMeta.state === ModelState.IsDirty
    ) {
      this.saveCompanyAUM(dirtyCompanyAUM);
    }

    if (areNotesDirty) {
      this.noteService.updateNotes(
        this.company,
        this.endPointResponsed.bind(this)
      );
    }
  }

  public saveCompany() {
    this.company.state = ModelState.Saving;
    this.updateCompanyBasicData(this.localCompany)
      .pipe(first())
      .subscribe({
        error: (error) => {
          console.error(error);
          this.serverError += JSON.stringify(error);
          this.hasError = true;
          this.localCompany.state = ModelState.NotSaved;
          this.endPointResponsed();
        },
        complete: () => {
          this.localCompany.state = ModelState.Saved;
          console.warn('Company Basic Data Saved!');
          this.endPointResponsed();
        },
      });
  }

  public savePrograms(dirtyProgramsByField: Array<Program>) {
    this.company.programState = ModelState.Saving;

    if (dirtyProgramsByField?.length > 0) {
      dirtyProgramsByField.forEach((program) => {
        program.state = ModelState.Saving;
        this.updateInvestmentProgramData(program)
          .pipe(first())
          .subscribe({
            error: (error) => {
              console.error(error);
              this.serverError += JSON.stringify(error);
              this.hasError = true;
              program.state = ModelState.NotSaved;
              this.localCompany.programState = ModelState.NotSaved;
              this.endPointResponsed();
            },
            complete: () => {
              console.warn('Program Saved!');
              program.state = ModelState.Saved;
              if (this.localCompany.programState !== ModelState.NotSaved) {
                this.localCompany.programState = ModelState.Saved;
              }
              this.endPointResponsed();
            },
          });
      });
    }
  }

  public saveProgramAUM(dirtyProgramsByAUM: Array<Program>) {
    this.company.programAUMState = ModelState.Saving;

    if (dirtyProgramsByAUM?.length > 0) {
      dirtyProgramsByAUM.forEach((program) => {
        program.aumMeta.state = ModelState.Saving;
        const dirtyAUM = program.aumMeta.stream.filter(
          (aum) => aum.state === ModelState.IsDirty
        );
        if (dirtyAUM?.length > 0) {
          this.aumService
            .updateAUM(dirtyAUM)
            .pipe(first())
            .subscribe({
              error: (error: HttpErrorResponse) => {
                console.error(error);
                this.serverError += JSON.stringify(error);
                this.hasError = true;
                program.aumMeta.state = ModelState.NotSaved;
                program.aumMeta.updateSpreadsheet();
                this.localCompany.programAUMState = ModelState.NotSaved;
                this.endPointResponsed();
              },
              complete: () => {
                if (program.aumMeta.state !== ModelState.NotSaved) {
                  program.aumMeta.state = ModelState.Saved;
                  if (
                    this.localCompany.programAUMState !== ModelState.NotSaved
                  ) {
                    this.localCompany.programAUMState = ModelState.Saved;
                    let localProgram =
                      this.localCompany.investmentPrograms?.find(
                        (ip) =>
                          ip.investmentProgramId === program.investmentProgramId
                      );

                    localProgram.aumMeta.stream?.forEach((streamPoint) => {
                      if (streamPoint.markedForDeletion) {
                        streamPoint.source = undefined;
                        streamPoint.markedForDeletion = undefined;
                        streamPoint.isNew = true;
                      } else {
                        streamPoint.isNew = false;
                      }
                      streamPoint.state = ModelState.Ready;
                    });
                    localProgram.aumMeta.updateSpreadsheet();
                  }
                  console.warn('Program AUM Saved!');
                  this.endPointResponsed();
                }
              },
            });
        } else {
          this.endPointResponsed();
        }
      });
    }
  }

  public saveCompanyAUM(dirtyAUM: Array<Stream>) {
    this.company.aumMeta.state = ModelState.Saving;

    this.aumService
      .updateAUM(dirtyAUM)
      .pipe(first())
      .subscribe({
        error: (error: HttpErrorResponse) => {
          console.error(error);
          this.serverError += JSON.stringify(error);
          this.hasError = true;
          this.localCompany.aumMeta.state = ModelState.NotSaved;
          this.localCompany.aumMeta.updateSpreadsheet();
          this.endPointResponsed();
        },
        complete: () => {
          if (this.localCompany.aumMeta.state !== ModelState.NotSaved) {
            this.localCompany.aumMeta.state = ModelState.Saved;
            this.localCompany.aumMeta.stream?.forEach((streamPoint) => {
              if (streamPoint.markedForDeletion) {
                streamPoint.source = undefined;
                streamPoint.markedForDeletion = undefined;
                streamPoint.isNew = true;
              } else {
                streamPoint.isNew = false;
              }
              streamPoint.state = ModelState.Ready;
            });
            this.localCompany.aumMeta.updateSpreadsheet();
          }
          console.warn('Company AUM Saved!');
          this.endPointResponsed();
        },
      });
  }

  public updateInvestmentProgramData(
    program: Program
  ): Observable<Program | IErrorResult> {
    let programDTO = program.toPlain();
    delete programDTO.auditURL;
    delete programDTO.auditURLParams;
    delete programDTO.classType;
    delete programDTO.group;
    delete programDTO._programLiquidity;
    delete programDTO.taxonomyId;
    return this.api.put(`basicdata/investmentprogram`, programDTO);
  }

  //#endregion

  //#region Private Methods

  private updateCompanyBasicData(
    o2Company: ManagementCompany
  ): Observable<any> {
    let o2CompanyDTO = o2Company.toPlain();
    delete o2CompanyDTO.auditURL;
    delete o2CompanyDTO.auditURLParams;
    delete o2CompanyDTO.classType;
    delete o2CompanyDTO.group;
    delete o2CompanyDTO.aumMeta;
    delete o2CompanyDTO.funds;
    delete o2CompanyDTO.investmentPrograms;
    delete o2CompanyDTO.programAUMState;
    delete o2CompanyDTO.programState;
    delete o2CompanyDTO._notes;
    return this.api.put(`basicdata/managementcompany`, o2CompanyDTO);
  }

  //#endregion
}
