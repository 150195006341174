import {
  DefaultValidationMessages,
  getAbbr,
  inMetaFactory,
  meta,
  Meta,
  toDateFormat,
  toLocalDate,
  ValidationStyleEnum,
} from '@aksia-monorepo/shared-ui';
import { Transform, Type } from 'class-transformer';
import {
  RecyclingTimeLimitEnum,
  RecyclingProceedsLimitEnum,
  LpClawbackCalculatedOnEnum,
  LpClawbackTimeLimitFromEnum,
  MgmtFeeFromEnum,
  MgmtFeeToEnum,
  MgmtFeeCalcOnEnum,
  SimpleAnswerEnum,
  ExtensionTypeEnum,
  DiscountTypeEnum,
  FundLevelsEnum,
  HurdleIndexEnum,
  LockUpEnum,
  RateIndexEnum,
  CarriedInterestTieredBaseEnum,
  IncFeeRateTypeEnum,
  MgmtFeeRateTypeEnum,
  CloseDateTypeEnum,
  LeverageBasisEnum,
  LeverageTypeEnum,
  CurrencyEnum,
  EntityTypeEnum,
  LeverageUseEnum,
} from '../../enums/enums';
import { ClosedShareClass } from '../shareclass/shareclass.closed';
import { OpenShareClass } from '../shareclass/shareclass.open';
import { ClosedEndDetails } from '../fund/fund.closedEndDetails';
import { Fund } from '../fund/fund.model';

//#region Common

@inMetaFactory
export class Address extends Meta {
  classType = 'Address';
  addressId: number = 0;
  entityId: number;
  entityTypeId: number = EntityTypeEnum.ManagementCompany;

  @meta({ alias: 'Main Office' })
  address1: string = '';
  city: string;

  @meta({ dtoName: 'state' })
  countryState: string;
  zip: string;
  country: string;

  @meta({ alias: 'Office Details' })
  address2: string;

  @meta({ alias: 'Fax' })
  fax: string;

  @meta({ alias: 'Phone' })
  phone: string;

  isPrimary: boolean;

  latitude: number;
  longitude: number;

  /* @Expose({ groups: ['meta']}) */

  auditURL = 'basicdata/managementcompany/audit/{0}/address/{1}/{2}';
  auditURLParams = [
    'grandParent.managementCompanyId@model',
    'addressId@model',
    '@prop',
  ];
}

@inMetaFactory
export class MasterFund extends Meta {
  classType = 'MasterFund';
  id: number = 0;

  fundId: number;

  @meta({ alias: 'Master Fund ##', softNotEmpty: true })
  name: string;

  auditURL = 'basicdata/fund/audit/{0}/masterfund/{1}/{2}';
  auditURLParams = ['grandParent.fundId@model', 'id@model', '@prop'];
}

@inMetaFactory
export class Discount extends Meta {
  classType = 'Discount';
  id: number = 0;

  @meta({
    alias: 'Discount Type ##',
    source: DiscountTypeEnum.toKeyValue(),
    softNotEmpty: true,
  })
  discountType: DiscountTypeEnum;

  @meta({
    alias: 'Fee Reduction',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: {
      value: 100,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        if (!value) return (self.message = null);
        if (target?.parentProp === 'mgmtFeeDiscounts') {
          let maxFeeRate = Math.max(
            ...target?.grandParent.mgmtFeePeriods
              ?.filter((per) => !per.markedForDeletion)
              .flatMap((per) =>
                per.mgmtFeeAmounts
                  ?.filter((am) => !am.markedForDeletion)
                  .map((am) => am.mgmtFeeRate)
              )
          );
          let max = Math.min(
            self.value,
            maxFeeRate ?? Number.POSITIVE_INFINITY
          );
          return value <= max
            ? (self.message = null)
            : (self.message = `${self.defaultMessage} ${max}`);
        } else if (target?.parentProp === 'managementFeeDiscounts') {
          let maxFeeRate = Math.max(
            ...target?.grandParent?.mgmtFeeRates
              ?.filter((rat) => !rat.markedForDeletion)
              .map((am) => am.mgmtFeeRatePercentage)
          );
          let max = Math.min(
            self.value,
            maxFeeRate ?? Number.POSITIVE_INFINITY
          );
          return value <= max
            ? (self.message = null)
            : (self.message = `${self.defaultMessage} ${max}`);
        } else if (target?.parentProp === 'carriedInterestDiscounts') {
          let maxRate = target?.grandParent?.carriedInterestRate
            ? target?.grandParent?.carriedInterestRate
            : Math.max(
                ...target?.grandParent.carriedInterestTieredRates
                  ?.filter((rat) => !rat.markedForDeletion)
                  .map((rate) => rate.feeRate)
              );
          let max = Math.min(self.value, maxRate ?? Number.POSITIVE_INFINITY);
          return value <= max
            ? (self.message = null)
            : (self.message = `${self.defaultMessage} ${max}`);
        } else if (target?.parentProp === 'incentiveFeeDiscounts') {
          let minRate =
            target?.grandParent?.incFeeRateType ===
            IncFeeRateTypeEnum.SlidingScaleRate
              ? Math.min(
                  ...target?.grandParent?.incFeeRates
                    ?.filter((rat) => !rat.markedForDeletion)
                    .map((rate) => rate.incFeeMaxRatePercentage)
                )
              : Math.max(
                  ...target?.grandParent?.incFeeRates
                    ?.filter((rat) => !rat.markedForDeletion)
                    .map((rate) => rate.incFeeRatePercentage)
                );
          let max = Math.min(self.value, minRate ?? Number.POSITIVE_INFINITY);
          return value <= max
            ? (self.message = null)
            : (self.message = `${self.defaultMessage} ${max}`);
        }
      },
    },
    softNotEmpty: true,
  })
  discountRate: number;

  @meta({ alias: 'Eligibility', softNotEmpty: true })
  discountDesc: string;

  auditURL = 'shareclass/audit/{0}/?propertyPaths=ShareClass_{1}@{2}_{3}@{4}';
  auditURLParams = [
    'grandParent.auditRoute@model',
    'grandParent.classId@model',
    'parent#auditRoute@model',
    'id@model',
    '@prop',
  ];
}

//#endregion

//#region Open

@inMetaFactory
export class Lockup extends Meta {
  classType = 'Lockup';
  id: number = 0;

  @meta({
    alias: 'Lockup (From) ##',
    hardNotLessThan: {
      value: 1,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        prop = 'lockupDurationMaxRange';
        let min = target?.previous
          ? Math.max(
              self.value,
              target?.previous[prop] ?? Number.NEGATIVE_INFINITY
            )
          : self.value;
        return !value || value >= min
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${min}`);
      },
    },
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        prop = 'lockupDurationMaxRange';
        let max = target[prop]
          ? Math.min(self.value, target[prop] ?? Number.POSITIVE_INFINITY)
          : self.value;
        return !value || value <= max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${max}`);
      },
    },
    softNotEmpty: true,
    softNotMoreThan: 36,
    updates: (self, value) => {
      if (self instanceof Lockup) {
        self.validate('lockupDurationMaxRange');
        if (self.previous && self.previous instanceof Lockup) {
          self.previous.validate('lockupDurationMaxRange');
        }
      }
    },
  })
  lockupDurationMinRange = 1;

  @meta({
    alias: 'Lockup (To) ##',
    hardNotLessThan: {
      value: 1,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        prop = 'lockupDurationMinRange';
        let min = target[prop]
          ? Math.max(self.value, target[prop] ?? Number.NEGATIVE_INFINITY)
          : self.value;
        return !value || value >= min
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${min}`);
      },
    },
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        prop = 'lockupDurationMinRange';
        let max = target?.next
          ? Math.min(self.value, target?.next[prop] ?? Number.POSITIVE_INFINITY)
          : self.value;
        return !value || value <= max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${max}`);
      },
    },
    softNotEmpty: true,
    softNotMoreThan: 36,
    updates: (self, value) => {
      if (self instanceof Lockup) {
        self.validate('lockupDurationMinRange');
        if (self.next && self.next instanceof Lockup) {
          self.next.validate('lockupDurationMinRange');
        }
      }
    },
  })
  lockupDurationMaxRange = 12;

  @meta({
    alias: 'Lockup (Type) ##',
    source: LockUpEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof Lockup) {
        if (value !== LockUpEnum.Soft) {
          self.lockupFee = undefined;
        }
      }
    },
  })
  lockupType: LockUpEnum;

  @meta({ alias: 'Rolling' })
  lockupRolling: boolean;

  @meta({
    alias: 'Lockup (Exit Fee) ##',
    hardNotLessThan: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        prop = 'lockupFee';
        let min = target?.next
          ? Math.max(self.value, target?.next[prop] ?? Number.NEGATIVE_INFINITY)
          : self.value;
        return !value || value >= min
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${min}`);
      },
    },
    hardNotMoreThan: {
      value: 100,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        prop = 'lockupFee';
        let max = target?.previous
          ? Math.min(
              self.value,
              target?.previous[prop] ?? Number.POSITIVE_INFINITY
            )
          : self.value;
        return !value || value <= max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${max}`);
      },
    },
    softNotEmpty: true,
    softNotLessThan: 1,
    softNotMoreThan: 10,
    updates: (self, value) => {
      if (self instanceof Lockup) {
        if (self.previous && self.previous instanceof Lockup) {
          self.previous.validate('lockupFee');
        }
        if (self.next && self.next instanceof Lockup) {
          self.next.validate('lockupFee');
        }
      }
    },
  })
  lockupFee?: number;

  auditURL =
    'shareclass/audit/openend/?propertyPaths=ShareClass_{0}@Lockups_{1}@{2}';
  auditURLParams = ['grandParent.classId@model', 'id@model', '@prop'];
}

@inMetaFactory
export class Gate extends Meta {
  classType = 'Gate';
  id: number = 0;

  @meta({
    alias: 'Gate Level ##',
    source: FundLevelsEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof Gate) {
        switch (value) {
          case FundLevelsEnum.MasterFund:
          case FundLevelsEnum.Fund:
          case FundLevelsEnum.Investor:
          case FundLevelsEnum.Class:
            self.gateMasterFundPercentage =
              self.gateFundPercentage =
              self.gateInvestorPercentage =
                undefined;
            break;
          case FundLevelsEnum.Investor_Fund:
            self.gateMasterFundPercentage = self.gatePercentage = undefined;
            break;
          case FundLevelsEnum.Investor_MasterFund:
            self.gateFundPercentage = self.gatePercentage = undefined;
            break;
          default:
            self.gateMasterFundPercentage =
              self.gateFundPercentage =
              self.gateInvestorPercentage =
              self.gatePercentage =
                undefined;
        }
      }
    },
  })
  gateLevel: FundLevelsEnum;

  @meta({
    alias: 'Master Fund Percentage',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 10,
    softNotMoreThan: 35,
  })
  gateMasterFundPercentage?: number;

  @meta({
    alias: 'Fund Percentage',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 10,
    softNotMoreThan: 35,
  })
  gateFundPercentage?: number;

  @meta({
    alias: 'Investor Percentage',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 10,
    softNotMoreThan: 35,
  })
  gateInvestorPercentage?: number;

  @meta({
    alias: 'Gate Percentage',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 10,
    softNotMoreThan: 35,
  })
  gatePercentage?: number;

  auditURL =
    'shareclass/audit/openend/?propertyPaths=ShareClass_{0}@Gates_{1}@{2}';
  auditURLParams = ['grandParent.classId@model', 'id@model', '@prop'];
}

@inMetaFactory
export class SidePocket extends Meta {
  classType = 'SidePocket';
  id: number = 0;

  @meta({
    alias: 'Side Pocket Level ##',
    source: FundLevelsEnum.toKeyValue().filter(
      (fl) =>
        ![
          FundLevelsEnum.Investor_Fund,
          FundLevelsEnum.Investor_MasterFund,
        ].includes(fl.key)
    ),
    softNotEmpty: true,
  })
  sidePocketLevel: FundLevelsEnum;

  @meta({
    alias: 'Side Pocket Limit ##',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 5,
    softNotMoreThan: 30,
  })
  sidePocketLimit?: number;

  auditURL =
    'shareclass/audit/openend/?propertyPaths=ShareClass_{0}@SidePockets_{1}@{2}';
  auditURLParams = ['grandParent.classId@model', 'id@model', '@prop'];

  constructor() {
    super(SidePocket);
  }
}

@inMetaFactory
export class MgmtFeeRate extends Meta {
  classType = 'MgmtFeeRate';
  id: number = 0;
  @meta({
    alias: 'Fee Rate ##',
    hardNotLessOrEqualTo: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessOrEqualTo,
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFeeRatePercentage';
        let min = target?.next
          ? Math.max(self.value, target?.next[prop] ?? Number.NEGATIVE_INFINITY)
          : self.value;
        return !value || value > min
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${min}`);
      },
    },
    hardNotMoreOrEqualTo: {
      value: 100,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFeeRatePercentage';
        let max = target?.previous
          ? Math.min(
              self.value,
              target?.previous[prop] ?? Number.POSITIVE_INFINITY
            )
          : self.value;
        return !value || value < max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${max}`);
      },
    },
    softNotEmpty: true,
    softNotMoreThan: 3,
    updates: (self, value) => {
      if (self instanceof MgmtFeeRate) {
        if (self.previous && self.previous instanceof MgmtFeeRate) {
          self.previous.validate('mgmtFeeRatePercentage');
        }
        if (self.next && self.next instanceof MgmtFeeRate) {
          self.next.validate('mgmtFeeRatePercentage');
        }
        if (
          self.grandParent &&
          self.grandParent instanceof OpenShareClass &&
          self.grandParent.managementFeeDiscounts?.length > 0
        ) {
          self.grandParent.managementFeeDiscounts.forEach((disc) =>
            disc.validate('discountRate')
          );
        }
      }
    },
  })
  mgmtFeeRatePercentage: number;

  @meta({
    alias: 'From',
    hardNotLessOrEqualTo: 0,
    hardNotLessThan: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        let against = target.previous?.mgmtFeeRateAmountTo ?? self.value;
        return !value || value >= against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${getAbbr(against, true)}`);
      },
    },
    hardNotMoreOrEqualTo: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule: (self, value, target, prop) => {
        return !value ||
          !target?.mgmtFeeRateAmountTo ||
          value < target?.mgmtFeeRateAmountTo
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${getAbbr(
              target?.mgmtFeeRateAmountTo,
              true
            )}`);
      },
    },
    updates: (self, value) => {
      if (self instanceof MgmtFeeRate) {
        self.validate('mgmtFeeRateAmountTo');
      }
    },
  })
  mgmtFeeRateAmountFrom: number;

  @meta({
    alias: 'To',
    hardNotLessOrEqualTo: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        let against = target.mgmtFeeRateAmountFrom ?? self.value;
        return !value || value > against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${getAbbr(against, true)}`);
      },
    },
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return !value ||
          !target.next?.mgmtFeeRateAmountFrom ||
          value <= target.next?.mgmtFeeRateAmountFrom
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${getAbbr(
              target.next?.mgmtFeeRateAmountFrom,
              true
            )}`);
      },
    },
    updates: (self, value) => {
      if (self instanceof MgmtFeeRate) {
        self.validate('mgmtFeeRateAmountFrom');
      }
    },
  })
  mgmtFeeRateAmountTo: number;

  auditURL =
    'shareclass/audit/openend/?propertyPaths=ShareClass_{0}@MgmtFeeRates_{1}@{2}';
  auditURLParams = ['grandParent.classId@model', 'id@model', '@prop'];
}

@inMetaFactory
export class IncFeeRate extends Meta {
  classType = 'IncFeeRate';
  id: number = 0;

  @meta({
    alias: 'Fee Rate ##',
    hardNotLessOrEqualTo: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessOrEqualTo,
      validateRule: (self, value, target, prop) => {
        let against = target.previous?.incFeeRatePercentage ?? self.value;
        return !value || value > against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${against}`);
      },
    },
    hardNotMoreThan: {
      value: 100,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        prop = 'incFeeRatePercentage';
        let against = target.next?.incFeeRatePercentage ?? self.value;
        return !value || value <= against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${against}`);
      },
    },
    softNotEmpty: true,
    softNotLessThan: 5,
    softNotMoreThan: 30,
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        if (self.previous && self.previous instanceof IncFeeRate) {
          self.previous.validate('incFeeRatePercentage');
        }
        if (self.next && self.next instanceof IncFeeRate) {
          self.next.validate('incFeeRatePercentage');
        }
        if (
          self.grandParent &&
          self.grandParent instanceof OpenShareClass &&
          self.grandParent.incentiveFeeDiscounts?.length > 0
        ) {
          self.grandParent.incentiveFeeDiscounts.forEach((disc) =>
            disc.validate('discountRate')
          );
        }
      }
    },
  })
  incFeeRatePercentage: number;

  @meta({
    alias: 'Min Fee Rate',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    hardNotMoreOrEqualTo: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule: (self, value, target, prop) => {
        let against = target.incFeeMaxRatePercentage ?? self.value;
        return !value || value < against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${against}`);
      },
    },
    softNotEmpty: true,
    softNotLessThan: 5,
    softNotMoreThan: 30,
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        self.validate('incFeeMaxRatePercentage');
      }
    },
  })
  incFeeMinRatePercentage: number;

  @meta({
    alias: 'Max Fee Rate',
    hardNotLessOrEqualTo: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessOrEqualTo,
      validateRule: (self, value, target, prop) => {
        let against = target.incFeeMinRatePercentage ?? self.value;
        return !value || value > against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${against}`);
      },
    },
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 5,
    softNotMoreThan: 30,
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        self.validate('incFeeMinRatePercentage');

        if (
          self.grandParent &&
          self.grandParent instanceof OpenShareClass &&
          self.grandParent.incentiveFeeDiscounts?.length > 0
        ) {
          self.grandParent.incentiveFeeDiscounts.forEach((disc) =>
            disc.validate('discountRate')
          );
        }
      }
    },
  })
  incFeeMaxRatePercentage: number;

  @meta({
    alias: 'Hurdle',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.hurdleRateType = undefined;
        }
      }
    },
  })
  hasHurdle: SimpleAnswerEnum;

  @meta({
    alias: 'Hurdle Type ##',
    source: RateIndexEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        if (value === RateIndexEnum.Index) {
          self.hurdleRate = undefined;
        } else if (value === RateIndexEnum.FixedRate) {
          self.hurdleIndex = undefined;
        } else if (!value) {
          self.hurdleRate = undefined;
          self.hurdleIndex = undefined;
        }
      }
    },
  })
  hurdleRateType?: RateIndexEnum;

  @Transform(({ value }) => (value === 0 ? undefined : value), {
    toClassOnly: true,
  })
  @meta({
    alias: 'Index ##',
    source: HurdleIndexEnum.toKeyValue(),
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        if (value !== HurdleIndexEnum.Other) {
          self.hurdleIndexDesc = undefined;
        }
      }
    },
  })
  hurdleIndex: HurdleIndexEnum;

  @meta({ alias: 'Description ##' })
  hurdleIndexDesc: string = null;

  @meta({
    alias: 'Rate ##',
    hardNotLessOrEqualTo: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessOrEqualTo,
      validateRule: (self, value, target, prop) => {
        let against = target.previous?.hurdleRate ?? self.value;
        return !value || value > against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${against}`);
      },
    },
    hardNotMoreThan: 100,
    hardNotMoreOrEqualTo: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        let against = target.next?.hurdleRate ?? self.value;
        return !value || value < against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${against}`);
      },
    },
    softNotEmpty: true,
    softNotLessThan: 2,
    softNotMoreThan: 10,
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        if (self.previous && self.previous instanceof IncFeeRate) {
          self.previous.validate('hurdleRate');
        }
        if (self.next && self.next instanceof IncFeeRate) {
          self.next.validate('hurdleRate');
        }
      }
    },
  })
  hurdleRate?: number;

  @meta({
    alias: 'Min Hurdle Rate',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    hardNotMoreOrEqualTo: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule: (self, value, target, prop) => {
        prop = 'hurdleMaxRatePercentage';
        let against = target.hurdleMaxRatePercentage ?? self.value;
        return !value || value < against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${against}`);
      },
    },
    softNotEmpty: true,
    softNotLessThan: 2,
    softNotMoreThan: 10,
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        self.validate('hurdleMaxRatePercentage');
      }
    },
  })
  hurdleMinRatePercentage: number;

  @meta({
    alias: 'Max Hurdle Rate',
    hardNotLessOrEqualTo: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessOrEqualTo,
      validateRule: (self, value, target, prop) => {
        let against = target.hurdleMinRatePercentage ?? self.value;
        return !value || Number.isNaN(against) || value >= against
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${against}`);
      },
    },
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 2,
    softNotMoreThan: 20,
    updates: (self, value) => {
      if (self instanceof IncFeeRate) {
        self.validate('hurdleMinRatePercentage');
      }
    },
  })
  hurdleMaxRatePercentage: number;

  auditURL =
    'shareclass/audit/openend/?propertyPaths=ShareClass_{0}@IncFeeRates_{1}@{2}';
  auditURLParams = ['grandParent.classId@model', 'id@model', '@prop'];
}

//#endregion

//#region Closed

@inMetaFactory
export class RecyclingTimeLimit extends Meta {
  classType = 'RecyclingTimeLimit';
  id: number = 0;

  @meta({
    alias: 'Description',
    source: RecyclingTimeLimitEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof RecyclingTimeLimit) {
        if (
          value !==
          RecyclingTimeLimitEnum.Only_for_investments_realized_within_a_specific_timeframe
        ) {
          self.monthsFromAcquisition = undefined;
        }
      }
    },
  })
  timeLimitType?: RecyclingTimeLimitEnum;

  @meta({
    alias: 'Months from Acquisition',
    hardNotLessOrEqualTo: 0,
    softNotEmpty: true,
    softNotLessThan: 3,
    softNotMoreThan: 36,
  })
  monthsFromAcquisition?: number;

  auditURL =
    'basicdata/fund/audit/{0}/closedend/{1}/recyclingTimeLimits/{2}/{3}';
  auditURLParams = [
    'grandParent.parent.fundId@model',
    'grandParent.id@model',
    'id@model',
    '@prop',
  ];
}

@inMetaFactory
export class RecyclingProceedsLimit extends Meta {
  classType = 'RecyclingProceedsLimit';
  id: number = 0;

  @meta({
    alias: 'Type of Proceeds',
    source: RecyclingProceedsLimitEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof RecyclingProceedsLimit) {
        if (value !== RecyclingProceedsLimitEnum.Other) {
          self.proceedsDesc = undefined;
        }
      }
    },
  })
  proceedsType: RecyclingProceedsLimitEnum;

  @meta({ alias: 'Description' })
  proceedsDesc?: string;

  auditURL =
    'basicdata/fund/audit/{0}/closedend/{1}/recyclingProceedsLimits/{2}/{3}';
  auditURLParams = [
    'grandParent.parent.fundId@model',
    'grandParent.id@model',
    'id@model',
    '@prop',
  ];
}

@inMetaFactory
export class LpClawbackPerLimit extends Meta {
  classType = 'LpClawbackPerLimit';
  id: number = 0;

  @meta({
    alias: 'Rate',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 10,
    softNotMoreThan: 50,
  })
  lpClawbackPerLimitRate?: number;

  @meta({
    alias: 'Calculated On',
    source: LpClawbackCalculatedOnEnum.toKeyValue(),
    softNotEmpty: true,
  })
  lpClawbackCalculatedOnType?: LpClawbackCalculatedOnEnum;

  auditURL =
    'basicdata/fund/audit/{0}/closedend/{1}/lpClawbackPerLimits/{2}/{3}';
  auditURLParams = [
    'grandParent.parent.fundId@model',
    'grandParent.id@model',
    'id@model',
    '@prop',
  ];
}

@inMetaFactory
export class LpClawbackTimeLimit extends Meta {
  classType = 'LpClawbackTimeLimit';
  id: number = 0;

  @meta({
    alias: 'Years',
    hardNotLessOrEqualTo: 0,
    softNotEmpty: true,
    softNotLessThan: 1,
    softNotMoreThan: 5,
  })
  lpClawbackTimeLimitYears?: number;

  @meta({
    alias: 'Calculated On',
    source: LpClawbackTimeLimitFromEnum.toKeyValue(),
    softNotEmpty: true,
  })
  lpClawbackTimeLimitFrom?: LpClawbackTimeLimitFromEnum;

  auditURL =
    'basicdata/fund/audit/{0}/closedend/{1}/lpClawbackTimeLimits/{2}/{3}';
  auditURLParams = [
    'grandParent.parent.fundId@model',
    'grandParent.id@model',
    'id@model',
    '@prop',
  ];
}

@inMetaFactory
export class MgmtFeePeriod extends Meta {
  classType = 'MgmtFeePeriod';
  id: number = 0;

  @meta({
    alias: 'From',
    source: MgmtFeeFromEnum.toKeyValue(),
    softNotEmpty: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotEmpty,
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFee';
        return target.grandParent &&
          target.grandParent[prop] === SimpleAnswerEnum.Yes &&
          !value
          ? (self.message = `${self.defaultMessage}`)
          : (self.message = null);
      },
    },
    softEqual: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: "Is usually the same with the previous period's To",
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFee';
        return target.grandParent &&
          target.grandParent[prop] === SimpleAnswerEnum.Yes &&
          target.previous &&
          MgmtFeeToEnum.toKeyValue().find(
            (x) => x.key === target.previous?.mgmtFeeTimeTo
          )?.value !==
            MgmtFeeFromEnum.toKeyValue().find(
              (x) => x.key === target.mgmtFeeTimeFrom
            )?.value
          ? (self.message = `${self.defaultMessage}`)
          : (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof MgmtFeePeriod) {
        if (value !== MgmtFeeFromEnum.Other) {
          self.mgmtFeeTimeFromDesc = undefined;
        }
      }
    },
  })
  mgmtFeeTimeFrom: MgmtFeeFromEnum;

  @meta({
    alias: 'Management Fee Period (From Description) ##',
    softNotEmpty: true,
  })
  mgmtFeeTimeFromDesc: string;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Management Fee Period (From Date) ##',
    softNotLessOrEqualTo: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: 'Is usually later than the previous date',
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFee';
        return target.grandParent &&
          target.grandParent[prop] === SimpleAnswerEnum.Yes &&
          target.previous &&
          value &&
          value <= target.previous.mgmtFeeTimeToDate
          ? (self.message = `${self.defaultMessage} (${toDateFormat(
              target.previous.mgmtFeeTimeToDate as Date
            )})`)
          : (self.message = null);
      },
    },
    softNotMoreOrEqualTo: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: 'Is usually earlier than the next date',
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFee';
        return target.grandParent &&
          target.grandParent[prop] === SimpleAnswerEnum.Yes &&
          value &&
          value >= target.mgmtFeeTimeToDate
          ? (self.message = `${self.defaultMessage} (${toDateFormat(
              target.mgmtFeeTimeToDate as Date
            )})`)
          : (self.message = null);
      },
    },
  })
  mgmtFeeTimeFromDate: Date;

  @meta({
    alias: 'To',
    source: MgmtFeeToEnum.toKeyValue(),
    softNotEmpty: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotEmpty,
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFee';
        return target.grandParent &&
          target.grandParent[prop] === SimpleAnswerEnum.Yes &&
          !value
          ? (self.message = `${self.defaultMessage}`)
          : (self.message = null);
      },
    },
    softEqual: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: "Is usually the same with the next period's From",
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFee';
        return target.grandParent &&
          target.grandParent[prop] === SimpleAnswerEnum.Yes &&
          target.next &&
          MgmtFeeFromEnum.toKeyValue().find(
            (x) => x.key === target.next?.mgmtFeeTimeFrom
          )?.value !==
            MgmtFeeToEnum.toKeyValue().find(
              (x) => x.key === target.mgmtFeeTimeTo
            )?.value
          ? (self.message = `${self.defaultMessage}`)
          : (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof MgmtFeePeriod) {
        if (value !== MgmtFeeToEnum.Other) {
          self.mgmtFeeTimeToDesc = undefined;
        }
      }
    },
  })
  mgmtFeeTimeTo: MgmtFeeToEnum;

  @meta({
    alias: 'Management Fee Period (To Description) ##',
    softNotEmpty: true,
  })
  mgmtFeeTimeToDesc: string;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Management Fee Period (To Date) ##',
    softNotLessOrEqualTo: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: 'Is usually later than the previous date',
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFee';
        return target.grandParent &&
          target.grandParent[prop] === SimpleAnswerEnum.Yes &&
          value &&
          value <= target.mgmtFeeTimeFromDate
          ? (self.message = `${self.defaultMessage} (${toDateFormat(
              target.mgmtFeeTimeFromDate as Date
            )})`)
          : (self.message = null);
      },
    },
    softNotMoreOrEqualTo: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: 'Is usually earlier than the next date',
      validateRule: (self, value, target, prop) => {
        prop = 'mgmtFee';
        return target.grandParent &&
          target.grandParent[prop] === SimpleAnswerEnum.Yes &&
          target.next &&
          value &&
          value >= target.next.mgmtFeeTimeToDate
          ? (self.message = `${self.defaultMessage} (${toDateFormat(
              target.next.mgmtFeeTimeToDate as Date
            )})`)
          : (self.message = null);
      },
    },
  })
  mgmtFeeTimeToDate: Date;

  @Type(() => MgmtFeeAmount)
  @meta({
    alias: 'Management Fee Amount ##',
    navigation: true,
    adds: 'MgmtFeeAmount',
    removes: 'soft',
  })
  mgmtFeeAmounts: Array<MgmtFeeAmount>;

  auditURL =
    'shareclass/audit/closedend/?propertyPaths=ShareClass_{0}@MgmtFeePeriods_{1}@{2}';
  auditURLParams = ['grandParent.classId@model', 'id@model', '@prop'];
}

@inMetaFactory
export class MgmtFeeAmount extends Meta {
  classType = 'MgmtFeeAmount';
  id: number = 0;
  @meta({
    alias: 'Rate ##',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotMoreThan: {
      value: 3,
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        const previousPeriod = target?.grandParent?.previous;
        let maxFeeRate = Number.POSITIVE_INFINITY;
        if (previousPeriod instanceof MgmtFeePeriod) {
          maxFeeRate = Math.max(
            ...previousPeriod.mgmtFeeAmounts
              ?.filter(
                (amount) => !amount.markedForDeletion && amount.mgmtFeeRate
              )
              .map((amount) => amount.mgmtFeeRate)
          );
        }
        let max = Math.min(self.value, maxFeeRate);
        return value <= max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${max}`);
      },
    },
    updates: (self, value) => {
      if (self instanceof MgmtFeeAmount) {
        if ((self?.grandParent as Meta)?.next instanceof MgmtFeePeriod) {
          (
            (self?.grandParent as Meta)?.next as MgmtFeePeriod
          )?.mgmtFeeAmounts?.forEach((amount) =>
            amount.validate('mgmtFeeRate')
          );
        }
        if (
          (self?.grandParent as Meta)?.grandParent &&
          (self?.grandParent as Meta)?.grandParent instanceof
            ClosedShareClass &&
          (self?.grandParent as Meta)?.grandParent['mgmtFeeDiscounts']?.length >
            0
        ) {
          (self?.grandParent as Meta)?.grandParent['mgmtFeeDiscounts']?.forEach(
            (disc) => disc.validate('discountRate')
          );
        }
      }
    },
  })
  mgmtFeeRate: number;

  @meta({
    alias: 'calculated on',
    source: MgmtFeeCalcOnEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof MgmtFeeAmount) {
        if (value !== MgmtFeeCalcOnEnum.other_amount) {
          self.mgmtFeeCalcOnDesc = undefined;
        }
        if (
          ![
            MgmtFeeCalcOnEnum.actively_invested_capital,
            MgmtFeeCalcOnEnum.actively_invested_capital_plus_unfunded_commitments,
            MgmtFeeCalcOnEnum.total_invested_capital,
            MgmtFeeCalcOnEnum.total_invested_capital_plus_unfunded_commitments,
            MgmtFeeCalcOnEnum.lesser_of_NAV_and_total_invested_capital,
            MgmtFeeCalcOnEnum.lesser_of_NAV_and_actively_invested_capital,
            MgmtFeeCalcOnEnum.lesser_of_actively_invested_capital_and_committed_capital,
            MgmtFeeCalcOnEnum.lesser_of_total_invested_capital_and_committed_capital,
          ].includes(value as MgmtFeeCalcOnEnum)
        ) {
          self.useOfLeverage = undefined;
        }
      }
    },
  })
  mgmtFeeCalcOnType: MgmtFeeCalcOnEnum;

  @meta({
    alias: 'Description',
    softNotEmpty: true,
  })
  mgmtFeeCalcOnDesc: string;

  @meta({
    alias: 'Use of leverage',
    source: LeverageUseEnum.toKeyValue(),
  })
  useOfLeverage?: LeverageUseEnum;

  auditURL =
    'shareclass/audit/closedend/?propertyPaths=ShareClass_{0}@MgmtFeePeriods_{1}@MgmtFeeAmounts_{2}@{3}';
  auditURLParams = [
    'grandParent.grandParent.classId@model',
    'grandParent.id@model',
    'id@model',
    '@prop',
  ];
}

@inMetaFactory
export class CarriedInterestTieredRate extends Meta {
  classType = 'CarriedInterestTieredRate';
  id: number = 0;

  @meta({
    alias: 'Carried Interest Rate ##',
    hardNotLessOrEqualTo: 0,
    hardNotLessThan: {
      value: Number.NEGATIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        prop = 'feeRate';
        let min =
          target?.previous && target?.previous[prop]
            ? Math.max(self.value, target?.previous[prop])
            : self.value;
        return !value || value >= min
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${min}`);
      },
    },
    hardNotMoreThan: {
      value: 100,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        prop = 'feeRate';
        let max =
          target?.next && target?.next[prop]
            ? Math.min(self.value, target?.next[prop])
            : self.value;
        return !value || value <= max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${max}`);
      },
    },
    softNotEmpty: true,
    softNotLessThan: 5,
    softNotMoreThan: 30,
    updates: (self, value) => {
      if (self instanceof CarriedInterestTieredRate) {
        if (
          self.previous &&
          self.previous instanceof CarriedInterestTieredRate
        ) {
          self.previous.validate('feeRate');
        }
        if (self.next && self.next instanceof CarriedInterestTieredRate) {
          self.next.validate('feeRate');
        }
      }
    },
  })
  feeRate: number;

  @meta({
    alias: 'Hurdle/IRR ##',
    hardNotLessOrEqualTo: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        const propType = 'carriedInterestTieredBaseType';
        prop = 'prefReturn';
        if (
          !target.grandParent ||
          (target.grandParent &&
            ![
              CarriedInterestTieredBaseEnum.PreferredReturn,
              CarriedInterestTieredBaseEnum.PreferredReturn_and_Multiple,
            ].includes(target.grandParent[propType]))
        ) {
          return (self.message = null);
        } else {
          const minOffset = target?.next ? 0 : 0.01;
          let min =
            target?.previous && target?.previous[prop]
              ? Math.max(self.value, target?.previous[prop] - minOffset)
              : self.value;
          return !value || value > min
            ? (self.message = null)
            : (self.message = `${self.defaultMessage} ${min}`);
        }
      },
    },
    hardNotMoreThan: 100,
    hardNotMoreOrEqualTo: {
      value: 100.0001,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        const propType = 'carriedInterestTieredBaseType';
        prop = 'prefReturn';
        if (
          !target.grandParent ||
          (target.grandParent &&
            ![
              CarriedInterestTieredBaseEnum.PreferredReturn,
              CarriedInterestTieredBaseEnum.PreferredReturn_and_Multiple,
            ].includes(target.grandParent[propType]))
        ) {
          return (self.message = null);
        } else {
          const maxOffset = target?.next?.next ? 0 : 0.01;
          let max =
            target?.next && target?.next[prop]
              ? Math.min(self.value, target?.next[prop] + maxOffset)
              : self.value;
          return !value || value < max
            ? (self.message = null)
            : (self.message = `${self.defaultMessage} ${max}`);
        }
      },
    },
    softNotEmpty: true,
    softNotLessThan: 5,
    softNotMoreThan: 25,
    updates: (self, value) => {
      if (self instanceof CarriedInterestTieredRate) {
        if (
          self.previous &&
          self.previous instanceof CarriedInterestTieredRate
        ) {
          self.previous.validate('prefReturn');
        }
        if (self.next && self.next instanceof CarriedInterestTieredRate) {
          self.next.validate('prefReturn');
        }
      }
    },
  })
  prefReturn: number;

  @meta({
    alias: 'Multiple ##',
    hardNotLessOrEqualTo: 0,
    hardNotLessThan: {
      value: Number.NEGATIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        const propType = 'carriedInterestTieredBaseType';
        prop = 'multiple';
        if (
          !target.grandParent ||
          (target.grandParent &&
            ![
              CarriedInterestTieredBaseEnum.Multiple,
              CarriedInterestTieredBaseEnum.PreferredReturn_and_Multiple,
            ].includes(target.grandParent[propType]))
        ) {
          return (self.message = null);
        } else {
          const minOffset = target?.next ? 0.01 : 0;
          let min =
            target?.previous && target?.previous[prop]
              ? Math.max(self.value, target?.previous[prop] + minOffset)
              : self.value;
          return !value || value >= min
            ? (self.message = null)
            : (self.message = `${self.defaultMessage} ${min}`);
        }
      },
    },
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        const propType = 'carriedInterestTieredBaseType';
        prop = 'multiple';
        if (
          !target.grandParent ||
          (target.grandParent &&
            ![
              CarriedInterestTieredBaseEnum.Multiple,
              CarriedInterestTieredBaseEnum.PreferredReturn_and_Multiple,
            ].includes(target.grandParent[propType]))
        ) {
          return (self.message = null);
        } else {
          const maxOffset = target?.next?.next ? 0.01 : 0;
          let max =
            target?.next && target?.next[prop]
              ? Math.min(self.value, target?.next[prop] - maxOffset)
              : self.value;
          return !value || value <= max
            ? (self.message = null)
            : (self.message = `${self.defaultMessage} ${max}`);
        }
      },
    },
    softNotEmpty: true,
    softNotLessThan: 1.5,
    softNotMoreThan: 4,
    updates: (self, value) => {
      if (self instanceof CarriedInterestTieredRate) {
        if (
          self.previous &&
          self.previous instanceof CarriedInterestTieredRate
        ) {
          self.previous.validate('multiple');
        }
        if (self.next && self.next instanceof CarriedInterestTieredRate) {
          self.next.validate('multiple');
        }
      }
    },
  })
  multiple: number;

  @meta({
    alias: 'Catch-up ##',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    updates: (self, value) => {
      if (self instanceof CarriedInterestTieredRate) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.catchupRate = undefined;
        }
      }
    },
  })
  hasCatchup: SimpleAnswerEnum;

  @meta({
    alias: 'Catch-Up % ##',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotLessThan: 50,
  })
  catchupRate?: number;

  auditURL =
    'shareclass/audit/closedend/?propertyPaths=ShareClass_{0}@{1}_{2}@{3}';
  auditURLParams = [
    'grandParent.classId@model',
    'parent#auditRoute@model',
    'id@model',
    '@prop',
  ];
}

@inMetaFactory
export class ContractualExtension extends Meta {
  classType = 'ContractualExtension';
  id: number = 0;
  collectionId: number;

  @meta({
    alias: 'Extension ##',
    hardNotLessOrEqualTo: 0,
    softNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        self.message = null;
        switch (target.parentProp) {
          case 'contractualFinalCloseExtensions':
            if (
              value >
              (target?.grandParent?.contractualFinalCloseMonths ?? self.value)
            ) {
              self.message = `${self.defaultMessage} ${target.grandParent?.contractualFinalCloseMonths}`;
            }
            break;
          case 'contractualInvestmentPeriodExtensions':
            if (
              value >
              (target?.grandParent?.closedEndKeyTerm
                ?.contractualInvestmentPeriodExpirationYears ?? self.value)
            ) {
              self.message = `${self.defaultMessage} ${target.grandParent?.closedEndKeyTerm?.contractualInvestmentPeriodExpirationYears}`;
            }
            break;
          case 'contractualTermExtensions':
            if (
              value >
              (target?.grandParent?.closedEndKeyTerm
                ?.contractualTermExpirationYears ?? self.value)
            ) {
              self.message = `${self.defaultMessage} ${target.grandParent?.closedEndKeyTerm?.contractualTermExpirationYears}`;
            }
            break;
        }
        return self.message;
      },
    },
    softNotLessThan: 3,
  })
  duration: number;

  @meta({ alias: 'in/with ##', source: ExtensionTypeEnum.toKeyValue() })
  type: ExtensionTypeEnum = undefined;

  auditURL = 'basicdata/fund/audit/{0}/closedend/{1}/{2}/{3}/{4}';
  auditURLParams = [
    'grandParent.parent.fundId@model',
    'grandParent.id@model',
    'parent#auditRoute@model',
    'id@model',
    '@prop',
  ];
}

@inMetaFactory
export class ClosedEndInterimDate extends Meta {
  classType = 'ClosedEndInterimDate';
  id: number = 0;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Interim Closed Date (mm/dd/yyyy) ##',
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.date > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
    hardNotLessOrEqualTo: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessOrEqualTo,
      validateRule(self, value, target, prop) {
        if (
          target instanceof ClosedEndInterimDate &&
          target?.grandParent instanceof ClosedEndDetails &&
          target?.grandParent?.parent instanceof Fund
        ) {
          return (self.message =
            target?.grandParent?.parent?.firstCloseDate?.getTime() >=
            value?.getTime()
              ? 'Interim close should occur after First Close'
              : null);
        }
      },
    },
    hardNotMoreOrEqualTo: {
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule(self, value, target, prop) {
        if (
          target instanceof ClosedEndInterimDate &&
          target?.grandParent instanceof ClosedEndDetails &&
          target?.grandParent?.parent instanceof Fund
        ) {
          return (self.message =
            target?.grandParent?.parent?.finalCloseDate?.getTime() <=
            value?.getTime()
              ? 'Interim close should occur before Final Close'
              : null);
        }
      },
    },
  })
  date?: Date;

  @meta({
    alias: 'Interim Closed Date ##',
    source: CloseDateTypeEnum.toKeyValue(),
  })
  type?: CloseDateTypeEnum;

  @meta({ alias: 'Interim Closed Date (Description) ##' })
  description?: string;

  auditURL = 'basicdata/fund/audit/{0}/closedend/{1}/{2}/{3}/{4}';
  auditURLParams = [
    'grandParent.parent.fundId@model',
    'grandParent.id@model',
    'parent#auditRoute@model',
    'id@model',
    '@prop',
  ];
}

@inMetaFactory
export class Leverage extends Meta {
  classType = 'Leverage';
  id: number = 0;
  fundId: number;

  @meta({
    alias: 'Leverage Limit (Calculated on) ##',
    source: LeverageBasisEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value: LeverageBasisEnum) => {
      if (self instanceof Leverage) {
        if (
          ![
            LeverageBasisEnum.leverage_to_value__LTV____,
            LeverageBasisEnum.leverage_to_cost__LTC____,
          ].includes(value)
        ) {
          self.leveragePercentageMin = self.leveragePercentageTarget =
            undefined;
        }
        if (![LeverageBasisEnum.calculated_on_other_amount].includes(value)) {
          self.leverageDesc = undefined;
        }
        if (
          [
            LeverageBasisEnum.debt_to_equity__D___E____,
            LeverageBasisEnum.net_debt___EBITDA,
          ].includes(value)
        ) {
          self.leveragePercentageMax = undefined;
        } else {
          self.leverageMultipleMin =
            self.leverageMultipleMax =
            self.leverageMultipleTarget =
              undefined;
        }
      }
    },
  })
  leverageBasis?: LeverageBasisEnum;
  leverageType?: LeverageTypeEnum;

  @meta({ alias: 'Leverage (%) Min ##', hardNotLessOrEqualTo: 0 })
  leveragePercentageMin?: number;

  @meta({ alias: 'Leverage (%) Max ##', hardNotLessOrEqualTo: 0 })
  leveragePercentageMax?: number;

  @meta({ alias: 'Leverage (%) Target ##', hardNotLessOrEqualTo: 0 })
  leveragePercentageTarget?: number;

  @meta({ alias: 'Leverage (x) Min ##', hardNotLessOrEqualTo: 0 })
  leverageMultipleMin?: number;

  @meta({ alias: 'Leverage (x) Max ##', hardNotLessOrEqualTo: 0 })
  leverageMultipleMax?: number;

  @meta({ alias: 'Leverage (x) Target ##', hardNotLessOrEqualTo: 0 })
  leverageMultipleTarget?: number;

  @meta({ alias: 'Leverage Description ##' })
  leverageDesc?: string;

  auditURL = 'basicdata/fund/audit/{0}/leverage/{1}/{2}';
  auditURLParams = ['fundId@model', 'id@model', '@prop'];
}

//#endregion

//#region Secondary

@inMetaFactory
export class SecondaryAsset extends Meta {
  classType = 'SecondaryAsset';

  id: number;
  secondaryDataFieldsId: number;
  assetId?: number;

  @meta({ alias: 'Asset ##' })
  assetName?: string;

  @meta({ alias: 'Vintage ##' })
  vintage?: number;

  @meta({ alias: 'Record Date ##' })
  recordDate?: Date;

  @meta({ alias: 'Currency ##', source: CurrencyEnum.toKeyValue() })
  currency?: CurrencyEnum;

  @meta({ alias: 'NAV at Record Date ##', auditRoute: 'NAVRecordDate' })
  navRecordDate?: number;

  @meta({ alias: 'Uncalled Capital ##' })
  uncalledCapital?: number;

  @meta({ alias: 'Cash Flows Since Reference Date ##' })
  cashFlowsSinceRefDate?: number;

  @meta({ alias: 'Unadjusted Investment Amount ##' })
  unadjustedInvestmentAmount?: number;

  @meta({ alias: 'Adjusted Investment Amount ##' })
  adjustedInvestmentAmount?: number;

  @meta({ alias: 'Show in Report ##' })
  showInReport?: boolean;

  auditURL = 'basicdata/fund/audit/{0}/secondaryDetails/{1}/asset/{2}/{3}';
  auditURLParams = [
    'grandParent.parent.fundId@model',
    'grandParent.id@model',
    'id@model',
    '@prop',
  ];
}

//#endregion
