import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupArray'
})
export class GroupArrayPipe implements PipeTransform {

  transform(value: unknown[], prop: string, dateUnit: string = null): any[] {
    let groupped = value?.reduce((acc, cur) => {
      (acc[new Date(cur[prop]).getFullYear()] = acc[new Date(cur[prop]).getFullYear()] || []).push(cur);
      return acc;
    },[]);

    let grouppedAndNormalized = [];

    if (Array.isArray(groupped))
    Object.keys(groupped).forEach( g => {
      grouppedAndNormalized.push({
        key: g,
        value: groupped[g]
       }
      )
    })

    return grouppedAndNormalized;
  }

}
