import { AuthenticationService } from '@aksia-monorepo/shared-ui';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { O2User } from '../classes/authentication/credentials.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private _o2User: BehaviorSubject<O2User> = new BehaviorSubject(null);
  public o2User$ = this._o2User.asObservable();

  public set o2User(value) {
     this._o2User.next(value);
  }

  public get o2User() {
    if (!this._o2User.value) {
      this._o2User.next(new O2User(this.auth.user));
    }
    return this._o2User.value;
  }

  constructor(private auth: AuthenticationService) {}
}
