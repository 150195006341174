import { meta, Meta, toLocalDate } from "@aksia-monorepo/shared-ui";
import { Transform, Type } from "class-transformer";
import { AksiaRoleEnum, DealDynamicEnum, DealTypeSecondaryEnum, SimpleAnswerEnum, SourceDealEnum } from "../../enums/enums";
import { SecondaryAsset } from "../entities/entities.model";
export class SecondaryDetails extends Meta {

    id?: number = 0;
    fundId?: number;

    //#region Historical Info

    @meta({ alias: 'Fund Name / Asset' })
    fundNameAsset?: string;    

    //#endregion

    //#region Decision Dates

    @meta({ 
        alias: 'Bids Outstanding', 
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified)
    })
    bidsOutstanding?: SimpleAnswerEnum;

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'Target IC Date' })
    targetICDate?: Date;

    //#endregion

    //#region Categorization Factors

    @meta({ alias: 'Deal Type', source: DealTypeSecondaryEnum.toKeyValue() })
    dealType?: DealTypeSecondaryEnum;

    @meta({ alias: 'Source Type', source: SourceDealEnum.toKeyValue() })
    sourceDeal?: SourceDealEnum;

    @meta({ alias: 'Source Contact' })
    sourceContact?: string;

    @meta({ alias: 'Aksia Role', source: AksiaRoleEnum.toKeyValue() })
    aksiaRole?: AksiaRoleEnum;

    @meta({ alias: 'Deal Dynamic', source: DealDynamicEnum.toKeyValue() })
    dealDynamic?: DealDynamicEnum

    //#endregion

    //#region Key Terms

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'First Round Bid Date' })
    firstRoundBidDate?: Date;

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'Final Bid Date' })
    finalBidDate?: Date;

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'Expected Deal Close' })
    expectedDealClose?: Date;

    @meta({ alias: 'Final Transaction Price' })
    finalTransactionPrice?: number;

    @meta({ alias: 'Aksia Proposed Price Range' })
    aksiaProposedPriceRange?: string;

    @meta({ alias: 'Management Company Name' })
    managementCompanyName?: string;

    @meta({ alias: 'Fund(s)/Company' })
    fundsCompany?: string;

    @meta({ alias: 'Discount Guidance' })
    discountGuidance?: string;

    @meta({ alias: 'Action Items' })
    actionItems?: string;


    //TODO: Delete?
    @meta({ alias: 'NAV at Record Date', auditRoute: 'NAVRecordDate' })
    navRecordDate?: number;

    @meta({ alias: 'Uncalled Capital' })
    uncalledCapital?: number;

    @meta({ alias: 'Cash Flows Since Reference Date' })
    cashFlowsSinceRefDate?: number;

    @meta({ alias: 'Adjusted Investment Amount' })
    adjustedInvestmentAmount?: number;

    

    //#endregion

    //#region Assets

    @Transform( (dto) => { 
        if (dto?.obj?.assets?.length > 0){
            dto.obj.hasAssets = SimpleAnswerEnum.Yes;
        }
        return dto.value; 
    }, { toClassOnly: true })
    @Type(() => SecondaryAsset)
    @meta({ 
        navigation: true,
        adds: 'SecondaryAsset',
        removes: 'hard'
     })
    assets?: Array<SecondaryAsset>;

    @meta({ 
        alias: 'Assets?',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified)
    })
    hasAssets?: SimpleAnswerEnum;

    //#endregion

    //#region GP Led Details

    @meta({ 
        alias: 'Subsequent Funds Investing', 
        source: SimpleAnswerEnum.toKeyValue() 
    })
    subsequentFundsInvesting?: SimpleAnswerEnum;

    //#endregion


    auditURL = 'basicdata/fund/audit/{0}/secondaryDetails/{1}/{2}';
    auditURLParams = ['parent.fundId@model','id@model','@prop'];

    constructor(fundId: number) {
        super();
        this.fundId = fundId;
    }
}