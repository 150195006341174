import { Pipe, PipeTransform } from '@angular/core';
import { Meta, ModelState } from '@aksia-monorepo/shared-ui';

@Pipe({
  name: 'state'
})
export class StatePipe implements PipeTransform {
  viewState: Map<ModelState,string> = new Map();
  
  constructor() { 
    this.viewState.set(ModelState.HasError,'Has Error');
    this.viewState.set(ModelState.IsDirty,'Has Changes');
    this.viewState.set(ModelState.NotSaved,'Not Saved');
    this.viewState.set(ModelState.Ready,'Unchanged');
    this.viewState.set(ModelState.Saved,'Saved');
    this.viewState.set(ModelState.Saving,'Saving');
  }

  transform(state: ModelState | Array<Meta>): unknown {
    if (Array.isArray(state)){
      return [...new Set(state.map( item => this.viewState.get(item.state ?? ModelState.Ready)))]?.join(',')?.trim(); 
    }
    else {
      return state ? this.viewState.get(state) : this.viewState.get(ModelState.Ready);
    }
    
  }

}
