import { Injectable } from '@angular/core';
import { Type } from 'class-transformer';
import { Observable } from 'rxjs';
import { EntityTypeEnum } from '../enums/enums';
import { AUM } from '../classes/aum/aum.model';
import { Stream } from '../classes/stream/stream.model';
import { IErrorResult } from '../interfaces/system.interface';
import { ApiMicroService } from '@aksia-monorepo/shared-ui';
import {
  PERIODIC_STREAMS,
  PeriodicStreamType,
} from './periodic-streams.service';

export class BulkAUM {
  entityId: number;
  entityTypeId: number;
  @Type(() => AUM)
  aum: AUM[];
}

@Injectable({
  providedIn: 'root',
})
export class AumService {
  constructor(private api: ApiMicroService) {}

  //#region Base CRUD

  public getLatestAUM(
    entityId,
    entityTypeId: EntityTypeEnum = EntityTypeEnum.ManagementCompany
  ): Observable<Array<AUM> | AUM> {
    if (entityTypeId == EntityTypeEnum.ManagementCompany) {
      return this.api.get(
        `aum/latestForEntityTree/${entityId}/${
          EntityTypeEnum.ManagementCompany
        }/${true}`
      );
    } else {
      return this.api.get(`aum/latest/${entityId}/${entityTypeId}`);
    }
  }

  public getHistoricalStream(
    entityId: number,
    streamType: PeriodicStreamType = PERIODIC_STREAMS.AUM,
    entityTypeId: EntityTypeEnum = EntityTypeEnum.Fund
  ): Observable<unknown | null> {
    return this.api.get(`aum/mergeStream/${entityId}/${entityTypeId}`);
  }

  public updateAUM(
    o2Stream: Array<Stream>
  ): Observable<Array<AUM | IErrorResult>> {
    const aumPlain = this.cleanUpAUM(o2Stream);
    return this.api.post('aum/bulk', aumPlain);
  }

  private cleanUpAUM(o2Stream: Array<Stream>): Array<unknown> {
    const now = new Date();
    o2Stream.forEach(
      (stream) => (stream.isDeleted = !stream.value ? true : stream.isDeleted)
    );
    const aumPlain = o2Stream
      .filter(
        (aum) =>
          !(
            aum.asOf.getFullYear() >= now.getFullYear() &&
            aum.asOf.getMonth() >= now.getMonth()
          )
      )
      .map((streamPoint) => {
        let plainPoint = streamPoint.toPlain();
        let {
          classType,
          feeType,
          classification,
          estimationAsOf,
          isNew,
          isPublic,
          isAUM,
          modifiedOn,
          value,
          asOf,
          ...aumPoint
        } = plainPoint;
        return { amount: value, asOfDate: asOf, ...aumPoint };
      });

    return aumPlain;
  }

  //#endregion
}
