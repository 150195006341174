import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT } from '../environment/environment.token';
import { Environment } from '../environment/environment.interface';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { pipe } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AiService {
  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    private http: HttpClient
  ) {}

  /**
   * @deprecated The method was used with C# API
   */
  public chat(
    payload: {
      question: string;
      customInstructions: string;
      history?: Array<{ user: string; bot: string }>;
    },
    version?: string
  ) {
    switch (version) {
      case 'v-1':
        return this.http.post(
          `${this.env.aiUrl}/SimpleAssistant/chat`,
          payload
        );
      case 'v-2':
        return this.http.post(
          `${this.env.aiUrl}/ChattingAssistant/chat`,
          payload
        );
      default:
        return this.http.post(
          `${this.env.aiUrl}/${version ? version + '/' : ''}Assistant/chat`,
          payload
        );
    }
  }

  public chatV2(payload: { UserInput: string; History?: string }) {
    return this.http.post(`${this.env.aiUrl}/chat`, payload);
  }

  public getApiVersions() {
    return this.http.get(`${this.env.aiUrl}/AssistantTools/versions`);
  }

  public getHistory(pageSize: number, pageNumber: number) {
    return this.http.get(
      `${this.env.aiUrl}/AssistantLogs?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  }

  //#region Voting

  public vote(payload: {
    conversation_id: number;
    vote?: number;
    comment?: string;
  }) {
    return this.http
      .post(`${this.env.aiUrl}/votes`, payload)
      .pipe(take(1))
      .subscribe();
  }

  //#endregion
}
