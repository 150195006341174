import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, forwardRef, Inject, Injector, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCheck, faCheckSquare, faChevronDown, faFilter, faMinus, faSquare } from '@fortawesome/free-solid-svg-icons';
import { classToClass } from 'class-transformer';
import { fromEvent, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { groupBy } from '../../../functions/helpers';
import { BaseLoggingService } from '../../../services/base-logging.service';
import { BaseComponent, UIControlSettings } from '../base/base.component';

type MultiSelectSettings = {
  key?: string;
  value?: string;
  groupBy?: string;
  unique?: boolean;
  uniqueExcept?: unknown;
  justifyList?: 'Left' | 'Right';
  alignList?: 'Top' | 'Bottom';
  sortAtoZ?: boolean;
}

const defaultMultiSelectSettings: MultiSelectSettings = {
  key: 'key',
  value: 'value',
  groupBy: undefined,
  unique: false,
  justifyList: 'Left',
  alignList: 'Bottom',
  sortAtoZ: false
}

@Component({
  selector: 'multi-select',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true
    }
  ],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent extends BaseComponent {

  //#region Icons

  faFilter = faFilter;
  faChevronDown = faChevronDown;
  faCheck = faCheck;
  faMinus = faMinus;
  faSquare = faSquare;
  faCheckSquare = faCheckSquare;
  
  //#endregion Icons;

  protected _source: Array<unknown>;
  filteredSource: Array<unknown>;
  filterValue: string;
  uniqueViolation: boolean = false;
  currentIndex: number;
  private popUpClosed$: Subject<boolean> = new Subject<boolean>();
  #preventPopUp: boolean = false;
  popUp: boolean;
  Array = Array;

  @ViewChild('input') input: ElementRef;

  @Input() 
  set settings(value: UIControlSettings & MultiSelectSettings){
    for (const property in value) {
      this._settings[property] = value[property] ?? this._settings[property];
    }
    if (this._settings.e2eId){
      this.attach_e2e_attributes(this._settings.e2eId);
    }
  }

  get settings() {
    return this._settings;
  }

  @Input() 
  set source(source: Array<unknown>){
    this._source = this.settings.sortAtoZ 
                    ? source.sort((a,b) => a[this.settings.value]?.toLowerCase() > b[this.settings.value]?.toLowerCase() ? 1: -1) 
                    : source;
    this._source = this.settings.groupBy ? groupBy(this._source, this.settings.groupBy, ', ', true) as Array<unknown> : [[undefined, this._source]]; 
    this.filteredSource = this._source;
  }

  get source() {
    return this._source ?? this._settings.source;
  }

  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  constructor(injector: Injector, cmpFactoryResolver: ComponentFactoryResolver, elRef: ElementRef, @Inject(BaseLoggingService) logService: BaseLoggingService) {
    super(injector,cmpFactoryResolver,elRef,logService);
    this._settings = {...this._settings, ...defaultMultiSelectSettings};
  }

  ngOnInit(): void {
  }

  trackBy = (index, item) => item[this.settings.key];

  select(item: unknown, preventPopup: boolean = false) {
    let values = this.value$.value as Array<unknown>;
    if (values?.find( v => v[this.settings.key] === item[this.settings.key])){
      values = values.filter( v => v[this.settings.key] !== item[this.settings.key]);
    }
    else {
      values = [...(values ?? []), item];
    }
    this.value$.next(values);
    this.#preventPopUp = preventPopup;
    super.focusout(values);
  }

  filter(){
    const source = classToClass(this._source);
    this.filteredSource = source.filter( 
      groupItem => {
        groupItem[1] = (groupItem[1] as Array<unknown>).filter( item => (item[this.settings.value] as string).replace(/\s/g, '').toLowerCase().includes(this.filterValue.replace(/\s/g, '').toLowerCase()));
        if (groupItem[1]?.length > 0){
          return true; 
        }
        return false;
      } 
    );
  }

  resetOrCancel(input: HTMLFormElement) {
    super.reset();
  }
  
  private closeList() {
    this.focused = false;
    this.popUp = false;
    this.clearSearch();
    this.value$.next(this.value);
    this.popUpClosed$.next(true);
  }

  clearSearch(){
    this.filteredSource = this.source;
    this.filterValue = null;
    event.preventDefault();
  }

  //#region Overrides

  focusin(force?: boolean) {
    if (!this.#preventPopUp && !this.disabled){
      this.uniqueViolation = false;
      this.popUp = true;
      super.focusin();
        fromEvent(document, 'click')
        .pipe( 
          takeUntil(this.popUpClosed$),
          skip(1) 
        )
        .subscribe((event: MouseEvent) => {
          const target = event.target as HTMLElement;
          if (!(target?.className?.includes && target?.className?.includes('keepOpen'))){
            this.closeList();
          }
        })
    }
    this.#preventPopUp = false;
  }

  //#endregion

}
