import { DefaultValidationMessages, getAbbr, meta, Meta, ModelState, ValidationStyleEnum } from "@aksia-monorepo/shared-ui";
import { SimpleAnswerEnum, MinGPCommitmentEnum, EligibilityEnum, CoInvestPolicyEnum, LPACEnum, ValidationType } from "../../enums/enums";
import { ClosedEndDetails } from "./fund.closedEndDetails";
import { ClosedEndKeyTerm } from "./fund.closedEndKeyTerm";

export class ClosedEndStructure extends Meta {
    closedEndDataFieldsId: number = 0;

    //#region Structure

    @meta({
        alias: 'Minimum GP Commitment',
        source: MinGPCommitmentEnum.toKeyValue(),
        updates: (self, value) => {
            if (self instanceof ClosedEndStructure){
                if (!value){
                    self.minGPCommitmentCashless = undefined;
                    self.minGPCommitment = undefined;
                    self.minGPCommitmentAmount = undefined;
                }
                if (value === MinGPCommitmentEnum.Amount) {
                    self.minGPCommitment = undefined;
                }
                else if (value === MinGPCommitmentEnum.Percentage){
                    self.minGPCommitmentAmount = undefined;
                }
            }
        }
    })
    minGPCommitmentType?: MinGPCommitmentEnum;

    @meta({ alias: 'Minimum GP Commitment (Rate)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotLessThan: 1, softNotMoreThan: 5 }) 
    minGPCommitment?: number;

    @meta({ 
        alias: 'Minimum GP Commitment (Amount)', 
        hardNotLessOrEqualTo: 0, 
        hardNotMoreOrEqualTo: {
            value: Number.POSITIVE_INFINITY,
            type: ValidationStyleEnum.Hard,
            defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
            validateRule(self, value, target, prop) {
                if (!value) return self.message = null;
                if (target instanceof ClosedEndStructure && target.parent instanceof ClosedEndDetails && target.parent?.closedEndKeyTerm instanceof ClosedEndKeyTerm){
                    if (target.parent.closedEndKeyTerm.commitmentCap){
                        return self.message = value >= target.parent.closedEndKeyTerm.commitmentCap ? `${self.defaultMessage} ${getAbbr(target.parent.closedEndKeyTerm.commitmentCap)}` : null;
                    }
                    else if (target.parent.closedEndKeyTerm.commitmentTarget){
                        return self.message = value >= target.parent.closedEndKeyTerm.commitmentTarget ? `${self.defaultMessage} ${getAbbr(target.parent.closedEndKeyTerm.commitmentTarget)}` : null;
                    }
                    else {
                        return self.message = null;
                    }
                }
            },
        },
        softNotLessThan: {
            value: Number.NEGATIVE_INFINITY,
            type: ValidationStyleEnum.Soft,
            defaultMessage: DefaultValidationMessages.softNotLessThan,
            validateRule(self, value, target, prop) {
                if (!value) return self.message = null;
                if (target instanceof ClosedEndStructure && target.parent instanceof ClosedEndDetails && target.parent?.closedEndKeyTerm instanceof ClosedEndKeyTerm){
                    if (target.parent.closedEndKeyTerm.commitmentCap){
                        return self.message = value <= 0.01 * target.parent.closedEndKeyTerm.commitmentCap ? `${self.defaultMessage} ${getAbbr(0.01 * target.parent.closedEndKeyTerm.commitmentCap)}` : null;
                    }
                    else if (target.parent.closedEndKeyTerm.commitmentTarget){
                        return self.message = value <= 0.01 * target.parent.closedEndKeyTerm.commitmentTarget ? `${self.defaultMessage} ${getAbbr(0.01 * target.parent.closedEndKeyTerm.commitmentTarget)}` : null;
                    }
                    else {
                        return self.message = null;
                    }
                }
            },
        },
        softNotMoreThan: {
            value: Number.POSITIVE_INFINITY,
            type: ValidationStyleEnum.Soft,
            defaultMessage: DefaultValidationMessages.softNotMoreThan,
            validateRule(self, value, target, prop) {
                if (!value) return self.message = null;
                if (target instanceof ClosedEndStructure && target.parent instanceof ClosedEndDetails && target.parent?.closedEndKeyTerm instanceof ClosedEndKeyTerm){
                    if (target.parent.closedEndKeyTerm.commitmentCap){
                        return self.message = value >= 0.05 * target.parent.closedEndKeyTerm.commitmentCap ? `${self.defaultMessage} ${getAbbr(0.05 * target.parent.closedEndKeyTerm.commitmentCap)}` : null;
                    }
                    else if (target.parent.closedEndKeyTerm.commitmentTarget){
                        return self.message = value >= 0.05 * target.parent.closedEndKeyTerm.commitmentTarget ? `${self.defaultMessage} ${getAbbr(0.05 * target.parent.closedEndKeyTerm.commitmentTarget)}` : null;
                    }
                    else {
                        return self.message = null;
                    }
                }
            },
        }
    }) 
    minGPCommitmentAmount?: number;

    @meta({ alias: 'Cashless' }) 
    minGPCommitmentCashless?: boolean;

    @meta({ alias: 'Co-invest Policy', source: CoInvestPolicyEnum.toKeyValue() }) 
    coInvestPolicy?: CoInvestPolicyEnum;

    //#endregion

    modifiedBy?: string;

    auditURL = 'basicdata/fund/audit/{0}/closedend/{1}/structure/{2}';
    auditURLParams = ['grandParent.fundId@model','parent.id@model','@prop'];

    constructor() {
        super();
        this.state = ModelState.Ready;
        this.excludeFromNotePath = true;
    }

}

 