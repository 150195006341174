 /* eslint-disable */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModelState } from '../enums/enums';
import { NumberFormatPipe } from '../pipes/number-format.pipe';
import { StateItem, validationError, ValidationItem } from '../types/types';

@Injectable({
  providedIn: 'root'
})

export class ValidationService {

  static sections = {
    company: {
        basic: ['Historical Info','Description','Basic Info'],
        aum: ['AUM'],
        programs: ['Program AUM'],
        notes: ['Notes'],
        all: () => [
            ...ValidationService.sections.company.basic,
            ...ValidationService.sections.company.aum,
            ...ValidationService.sections.company.notes,
            ...ValidationService.sections.company.programs
        ]
    },
    fund: {
        basic: ['Historical Info','Description','Basic Info','Date Info'],
        aum: ['AUM'],
        details: {
            ClosedEnd: ['Details Date Info','Fund Timing','Capital Call Procedure','Organizational Expense Cap','Recycling','LP Clawback','Removal/Termination Rights','Leverage','Successor Fund Provision'],
            Hybrid: ['Details Date Info','Capital Call Procedure','Organizational Expense Cap','Recycling','LP Clawback','Removal/Termination Rights','Investment Restrictions and Leverage']
        },
        class: {
            OpenEnd: ['ShareClasses','Side Pockets','Subscriptions','Redemptions','Fees'],
            ClosedEnd: ['ShareClasses','Minimum Investment','Management Fee','Carried Interest'],
            Hybrid: ['ShareClasses','Minimum Investment','Redemptions','Fees']
        },
        notes: ['Notes'],
        all: () => [
            ...ValidationService.sections.fund.basic,
            ...ValidationService.sections.fund.aum,
            ...ValidationService.sections.fund.notes,
            ...ValidationService.sections.fund.details.ClosedEnd,
            ...ValidationService.sections.fund.details.Hybrid,
            ...ValidationService.sections.fund.class.OpenEnd,
            ...ValidationService.sections.fund.class.ClosedEnd,
            ...ValidationService.sections.fund.class.Hybrid,
        ]
    }
  }

  private groups: Map<string, ModelState> = new Map();
  items: Map<string, ValidationItem> = new Map();
  numberPipe: NumberFormatPipe = new NumberFormatPipe();
  modelState: BehaviorSubject<ModelState> = new BehaviorSubject(ModelState.Loading);
  errorProps: Array<string> = [];
  
  trigger: number = 0;

  constructor() { 
    
  }

  get modelState$(){
    return this.modelState.asObservable();
  }

  setItemValue(modelProp: string, itemProp: string, value: any): ValidationItem {
    if (!this.items.has(modelProp)){
      this.items.set(modelProp, { originalValue: null, previousValue: null });
    }

    let item = this.items.get(modelProp);
    item[itemProp] = value;

    return item;
  }

  getItem(modelProp: string): ValidationItem {
    return this.items.get(modelProp);
  }

  getItemValue(modelProp: string, prop: string): any {
    if (!this.items.has(modelProp)){
      return null;
    }
    return this.items.get(modelProp)[prop];
  }

  addError(field: string){
    if (!this.errorProps.some( errorProp => errorProp == field)){
      this.errorProps.push(field);
    }
  }

  removeError(field: string){
    let i = this.errorProps.indexOf(field);
    if (i > -1){
      this.errorProps.splice(i,1);
    }
  }

  //#region Validation

  hasError(label: string): boolean {
    return this.items.get(label)?.hasError ?? false;
  }

  validate(label: string, prev: string, next: string) {
    let item = this.items.get(label);
    if (!item) {
      return;
    }
    else {
      item.hasError = false;
      item.message = undefined;
      if (item.required){
        if ((next === "" || next === null || next === undefined) && (!this.hasOnlyInaudibleChanges)){
          item.message = `cannot be empty`;
          item.hasError = true;
          this.addError(label);
          item.isSoft = false;
        }
      }
      if(item.hardEmpty && next === null){
        item.message = `cannot be empty`;
        item.hasError = true;
        this.addError(label);
        item.isSoft = false;
      }
      /* if (item.requires){
        item.requires.forEach( requiredItemProp => {
          let item = this.getItem(requiredItemProp);
          item = this.setItemValue(requiredItemProp,'required',!(next === null));
          this.validate(requiredItemProp,item.previousValue ?? item.originalValue, item.currentValue);
        });
      }*/ 
      else if(item.softEmpty && next === null && !item.hasError){
        item.message = `is usually not empty`;
        item.isSoft = true;
      }
      else if (next === null){ 
        item.hasError = false;
      }
      else {
        if ((item.hardMin && (+next < item.hardMin)) || (item.lessThan && (+next < +item.lessThan))){
          item.hasError = true;
          this.addError(label);
          let lessThan = Math.max((item.hardMin || Number.NEGATIVE_INFINITY),(item.lessThan || Number.NEGATIVE_INFINITY)).toString();
          item.message = `cannot be less than ${this.numberPipe.transform(lessThan,false,"","",true)}`;
          item.isSoft = false;
        }
        else if (item.softMin && +next < item.softMin && !item.hasError){
          item.message = `is usually more than ${this.numberPipe.transform(item.softMin.toString(),false,"","",true)}`;
          item.isSoft = true;
        } 
        if ((item.hardMax && (+next > item.hardMax)) || (item.moreThan && (+next > +item.moreThan))){
          item.hasError = true;
          this.addError(label);
          let moreThan = Math.min((item.hardMax || Number.POSITIVE_INFINITY),(item.moreThan || Number.POSITIVE_INFINITY)).toString();
          item.message = `cannot be more than ${this.numberPipe.transform(moreThan,false,"","",true)}`;
          item.isSoft = false;
        }
        else if (item.softMax && (+next > item.softMax) && !item.hasError){
          item.message = `is usually less than ${this.numberPipe.transform(item.softMax.toString(),false,"","",true)}`;
          item.isSoft = true;
        }
        if (item.hardChange) { 
          let prev = item.originalValue ?? 0;
          if (prev && (+(prev as string)) && ((+next) < (+(prev as string)) - (+(prev as string)) * item.hardChange || (+next) > (+(prev as string)) + (+(prev as string)) * item.hardChange)){
            item.hasError = true;
            this.addError(label);
            item.message = `change cannot be greater than ${item.hardChange * 100}%`;
            item.isSoft = false;
          }
        }
        else if (item.softChange && !item.hasError){
          let prev = item.originalValue ?? "0";
          if (prev && (+(prev as string)) && ((+next) < (+(prev as string)) - (+(prev as string)) * item.softChange || (+next) > (+(prev as string)) + (+(prev as string)) * item.softChange)){
            item.message = `change is usually not greater than ${item.softChange * 100}%`;
            item.isSoft = true;
          }
        }
        if (item.maxChars){
          if (next.length > item.maxChars) {
            item.hasError = true;
            item.message = `max allowed characters is 2000, currently it is ${next.length}`;
            this.addError(label);
          }
        }
      }
      if (!item.hasError){
        this.removeError(label);
      }
    }
  }

  //#endregion

  //#region state

  get hasOnlyInaudibleChanges(): boolean {
    let override: boolean = true;
    this.items.forEach( item => {
      if (!item.inaudible){
        override = false 
      }
    });
    return override;
  }

  setGroupState(section: string | undefined, modelState: ModelState): void {
    if (section){
      this.groups.set(section, modelState);
    }
  }

  getGroupState(section: string): ModelState { 
    return this.groups.get(section) ?? ModelState.Ready;
  }

  isItemDirty(prop: string){
    let item = this.items.get(prop);
    return item?.isDirty;
  }

  isModelDirty(groups: Array<string>): boolean{
    let isDirty = false;
    if (!groups){
      return true;
    }
    
    groups.forEach( group => {
      this.items.forEach( (validationItem) => {
          if (validationItem.isDirty) {
            if (validationItem.group === group && !validationItem.visitsFrom){
              isDirty = true;
            }  
            else if (validationItem.visitsFrom === group) {
              isDirty = true;
            }
          }
      });
    });
    
    return isDirty;
  }

  isModelValid(groups: Array<string>): boolean {
    let isValid = true;
    let areAllInAudible = true;
    let required: Array<string> = [];
    
    this.items.forEach( (item, prop) => {
      if (item.hasError && ((item.group && groups.includes(item.group)) || (item.visitsFrom && groups.includes(item.visitsFrom)))){
        isValid = false;
      }
      
      if (!item.inaudible && item.isDirty && (item.group?.indexOf("AUM") === -1 || (item.group?.indexOf("AUM") !== -1 && item.visitsFrom))) {
        areAllInAudible = false;
      }

      if (item.required){
        required.push(prop);
      }
      
    });

    if (!areAllInAudible) {
        required.forEach( prop => {
          let item = this.items.get(prop);
          if (!item?.currentValue) {
            this.setItemValue(prop,"message","cannot be empty");
            this.setItemValue(prop,"hasError",true);
            this.addError(prop);
            isValid = false;
          }
        });
    }

    return isValid;
  }

  setModelState(sections: Array<string>, state: ModelState) {
    this.items.forEach( (validationItem) => {
      if (sections && ((validationItem.group && sections.includes(validationItem.group)) || (validationItem.visitsFrom && sections.includes(validationItem.visitsFrom)))){
        this.setGroupState(validationItem.group,state);
        if (state === ModelState.Saved){
          validationItem.isDirty = false;
          validationItem.originalValue = validationItem.currentValue;
        }
      }
    });
    this.modelState.next(state);
    this.trigger++;
  }

  update(value: any, model: any, prop: string, section: string | undefined = undefined, visitsFrom: string | undefined = undefined, clearProps: Array<string> | undefined = undefined){
    this.setItemValue(prop,'group',section);

    if (typeof prop === 'object'){
      this.updateObject(value, model, prop, section, visitsFrom);
      this.trigger++;
      this.modelState.next(ModelState.IsDirty);
      return;
    }
    this.updateProperty(value, model, prop, section, visitsFrom);
    
    clearProps?.forEach( clearProp => {
      this.items.forEach( (value,key) => {
        if (key.includes(clearProp)){
          value.group = section;
          value.hasError = false;
          value.required = false;
        }
      });
      this.errorProps = this.errorProps.filter( errorProp => !errorProp.includes(clearProp));      
    })
 
    this.trigger++;
  }

  clearAll(){
    this.items.clear();
    this.groups.clear();
    this.errorProps = [];
  }

  private updateObject(object: any, model: any, modelMap: Object, section: string | undefined = undefined, visitsFrom: string | undefined = undefined){
    Object.entries(object).forEach( ([key, value]) => {
      let prop = (key in modelMap) ? modelMap[key] : key;
      if (prop in model){
        this.updateProperty(object[key],model,prop,section, visitsFrom);
      }
    });
  }

  private updateProperty(value: any, model: any, prop: string, section: string | undefined = undefined, visitsFrom: string | undefined = undefined){
    if (!this.items.has(prop)) {
      this.items.set(prop,{});
    }

    let item = this.items.get(prop);
    if (item){
      item.previousValue = item.currentValue;
      item.currentValue = value;
      item.isDirty = item.currentValue !== item.originalValue;
      let state = ModelState.IsDirty;

      if (item.originalValue === value){
        if (section && this.isGroupDirty(section)) {
          state = ModelState.IsDirty;
        }
        else {
          state = ModelState.Ready;
        }
      }

      item.group = section;
      item.visitsFrom = visitsFrom;

      if (section){
        this.groups.set(section, state);
      }
            
      //Returns the highest possible state from Sections for the Global State
      this.modelState.next(Math.max(...this.groups.values()));

      //Sets the specific model state
      let sections: Array<string> = model?.getE2eSections ? model?.getE2eSections() : [];
      model.isDirty = section ? sections.includes(section) && state === ModelState.IsDirty : false;

      model[prop?.split('_')[0]] = value;

      if (model.hasOwnProperty('isDirty')){
        model.isDirty = true;
      }
    }
  }

  private isGroupDirty(section: string): boolean {
    let isDirty = false;
    this.items.forEach( (validationItem) => { 
      if (validationItem.group === section && validationItem.isDirty){
        isDirty = true;
      }
    });
    return isDirty;
  }

  //#endregion
}
