import { Component, ElementRef, OnInit } from '@angular/core';
import { faCompass } from '@fortawesome/free-solid-svg-icons';
import { PageNavigationService } from '../../core/services/page-navigation.service';

@Component({
  selector: 'page-navigation',
  templateUrl: './page-navigation.component.html',
  styleUrls: ['./page-navigation.component.scss']
})
export class PageNavigationComponent implements OnInit {

  //#region Icons
  faCompass = faCompass;
  //#endregion Icons

  constructor(public pageNav: PageNavigationService) { }

  ngOnInit(): void {}

  scrollToNavItem(navItem: { title: string, ref: ElementRef, order: number, level: number}) {
    if (navItem?.ref){
      navItem.ref?.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    else {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  }
}
