import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique'
})
export class UniquePipe implements PipeTransform {

  transform(collection: unknown[], prop: string, removeCollection: unknown[], removeProp: string): unknown {
    return collection.filter( item => removeCollection.some(ritem => ritem[removeProp] !== item[prop])) ?? [];
  }

}
