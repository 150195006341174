import { Transform, Type, Expose } from "class-transformer";
import { ResetOrchestrator, resetBy } from "../../decorators/reset.decorator";
import { CloseDateTypeEnum, CurrencyEnum, MinGPCommitmentEnum, SimpleAnswerEnum, RateIndexEnum, SubClInterestIndexEnum, LeverageBasisEnum } from "../../enums/enums";
import { RecyclingTimeLimit, RecyclingProceedsLimit, LpClawbackPerLimit, LpClawbackTimeLimit } from "../entities/entities.model";

export class HybridDetails {
    id = -1;
    fundId: number = null;

    //#region <<< Section - Details Date Info >>>

    firstCloseDateType?: CloseDateTypeEnum = null;

    @Type(() => Date)
    firstCloseDate?: Date = null;
    
    //#endregion

    //#region <<< Section - Capital Call Procedure >>>

    commitmentTarget?: number = null;
    commitmentCap?: number = null;
    commitmentCurrency: CurrencyEnum = CurrencyEnum.USD;
    
    minGPCommitmentType?: MinGPCommitmentEnum = null;
    minGPCommitment?: number = null;
    minGPCommitmentAmount?: number = null;
    minGPCommitmentCashless?: boolean = null;

    private _subsequentCloseInterest?: SimpleAnswerEnum = null;

    @Expose()
    //true -> SimpleAnswerEnum.Yes
    @Transform( ({value}) => SimpleAnswerEnum[SimpleAnswerEnum[(2-value)]], { toClassOnly: true })
    //SimpleAnswerEnum.Yes => true 
    @Transform( ({value}) => value == null ? null : !!(2-SimpleAnswerEnum[SimpleAnswerEnum[value]]), { toPlainOnly: true })
    get subsequentCloseInterest() {
        return this._subsequentCloseInterest;
    }
    
    set subsequentCloseInterest(value) {
        this._subsequentCloseInterest = value;
        if (value != SimpleAnswerEnum.Yes) {
            ResetOrchestrator.reset(this, 'hasSubsequentCloseInterest');
        }
    }

    @resetBy('hasSubsequentCloseInterest')
    subsequentCloseInterestType?: RateIndexEnum = null;

    @resetBy('hasSubsequentCloseInterest')
    @Transform( ({value}) => typeof(value) == "string" ? +value.trim() : value)
    subsequentCloseInterestIndex: SubClInterestIndexEnum = null;

    @resetBy('hasSubsequentCloseInterest')
    subsequentCloseInterestIndexDesc: string = null;

    @resetBy('hasSubsequentCloseInterest')
    subsequentCloseInterestRate?: number = null;

    //#endregion

    //#region <<< Section - Organizational Expense Cap >>>

    organizationalExpenseCapType?: MinGPCommitmentEnum = null;
    organizationalExpenseCapRate?: number = null;
    organizationalExpenseCapAmount?: number = null;

    //#endregion

    //#region <<< Section - Recycling >>>

    private _hasRecycling?: SimpleAnswerEnum = null;

    @Expose()
    get hasRecycling() {
        return this._hasRecycling;
    }

    set hasRecycling(value) {
        this._hasRecycling = value;
        if (value != SimpleAnswerEnum.Yes) {
            ResetOrchestrator.reset(this, 'hasRecycling');
        }
        else {
            /* this.recyclingTimeLimits = [new RecyclingTimeLimit()];
            this.recyclingProceedsLimits = [new RecyclingProceedsLimit()]; */
        }
    }

    
    private _hasTimeLimit?: SimpleAnswerEnum = null;
    
    @Expose()
    @resetBy('hasRecycling')
    get hasTimeLimit() {
        return this._hasTimeLimit;
    }

    set hasTimeLimit(value){
        this._hasTimeLimit = value;
        if (value != SimpleAnswerEnum.Yes){
            ResetOrchestrator.reset(this, 'hasTimeLimit');
        }
    }

    @resetBy('hasTimeLimit')
    @Transform((model) => { model.value?.forEach(element => { element.entityId = model.obj.fundId; }); return model.value }, { toClassOnly: true})
    recyclingTimeLimits?: Array<RecyclingTimeLimit> = null;

    private _hasRecyclingLimit?: SimpleAnswerEnum = null;

    @Expose()
    @resetBy('hasRecycling')
    get hasRecyclingLimit() {
        return this._hasRecyclingLimit;
    }

    set hasRecyclingLimit(value){
        this._hasRecyclingLimit = value;
        if (value != SimpleAnswerEnum.Yes) {
            ResetOrchestrator.reset(this, 'hasRecyclingLimit')
        }
    }

    @resetBy('hasRecycling')
    @resetBy('hasRecyclingLimit')
    recyclingCommitmentsRate?: number;

    private _hasProceedsLimit?: SimpleAnswerEnum = null;

    @Expose()
    @resetBy('hasRecycling')
    get hasProceedsLimit() {
        return this._hasProceedsLimit;
    }

    set hasProceedsLimit(value) {
        this._hasProceedsLimit = value;
        if (value != SimpleAnswerEnum.Yes) {
            ResetOrchestrator.reset(this, 'hasProceedsLimit');
        }
    }

    @resetBy('hasProceedsLimit')
    @Transform((model) => { model.value?.forEach(element => { element.entityId = model.obj.fundId; }); return model.value }, { toClassOnly: true})
    recyclingProceedsLimits?: Array<RecyclingProceedsLimit> = null;

    //#endregion

    //#region <<< Section - LP Clawback >>>

    private _lpClawbackPerLimit: SimpleAnswerEnum = null;

    @Expose()
    get lpClawbackPerLimit() {
        return this._lpClawbackPerLimit;
    }

    set lpClawbackPerLimit(value) {
        this._lpClawbackPerLimit = value;
        if (value == SimpleAnswerEnum.Yes) {
           // this.lpClawbackPerLimits = [new LpClawbackPerLimit()];
        }
        else {
            ResetOrchestrator.reset(this, 'hasLpClawbackPerLimit');
        }
    }

    @resetBy('hasLpClawbackPerLimit')
    @Transform((model) => { model.value?.forEach(element => { element.entityId = model.obj.fundId; }); return model.value }, { toClassOnly: true})
    lpClawbackPerLimits: Array<LpClawbackPerLimit> = null;

    addLpClawbackPerLimit(lpClawbackPerLimits: Array<LpClawbackPerLimit>){
        //lpClawbackPerLimits.push(new LpClawbackPerLimit());
    }

    private _lpClawbackTimeLimit: SimpleAnswerEnum = null;

    @Expose()
    get lpClawbackTimeLimit() {
        return this._lpClawbackTimeLimit;
    }

    set lpClawbackTimeLimit(value) {
        this._lpClawbackTimeLimit = value;
        if (value == SimpleAnswerEnum.Yes) {
            //this.lpClawbackTimeLimits = [new LpClawbackTimeLimit()];
        }
        else {
            ResetOrchestrator.reset(this, 'hasLpClawbackTimeLimit');
        }
    }

    @resetBy('hasLpClawbackTimeLimit')
    @Transform((model) => { model.value?.forEach(element => { element.entityId = model.obj.fundId; }); return model.value }, { toClassOnly: true})
    lpClawbackTimeLimits: Array<LpClawbackTimeLimit> = null;

    addLpClawbackTimeLimit(lpClawbackTimeLimits: Array<LpClawbackTimeLimit>){
        //lpClawbackTimeLimits.push(new LpClawbackTimeLimit());
    }

    //#endregion

    //#region <<< Section - Removal/Termination Rights >>>

    private _forFaultGpImRemoval: SimpleAnswerEnum = null;

    @Expose()
    get forFaultGpImRemoval() {
        return this._forFaultGpImRemoval;
    }

    set forFaultGpImRemoval(value) {
        this._forFaultGpImRemoval = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasForFaultGpImRemoval');
        }
    }

    @resetBy('hasForFaultGpImRemoval')
    forFaultGpImVoteThresholdRate: number = null;

    private _carriedInterestReduction: SimpleAnswerEnum = null;

    @Expose()
    get carriedInterestReduction() {
        return this._carriedInterestReduction;
    }

    set carriedInterestReduction(value) {
        this._carriedInterestReduction = value;
        if (value != SimpleAnswerEnum.Yes) {
            ResetOrchestrator.reset(this, 'hasCarriedInterestReduction');
        }
    }

    @resetBy('hasCarriedInterestReduction')
    carriedInterestReductionRate: number = null;

    private _forFaultFundTermination: SimpleAnswerEnum = null;

    @Expose()
    get forFaultFundTermination() {
        return this._forFaultFundTermination;
    }

    set forFaultFundTermination(value) {
        this._forFaultFundTermination = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasForFaultFundTermination');
        }
    }

    @resetBy('hasNoFaultFundTermination')
    forFaultFundVoteThresholdRate: number = null;

    private _noFaultGpImRemoval: SimpleAnswerEnum = null;

    @Expose()
    get noFaultGpImRemoval() {
        return this._noFaultGpImRemoval;
    }

    set noFaultGpImRemoval(value) {
        this._noFaultGpImRemoval = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasNoFaultGpImRemoval');
        }
    }

    @resetBy('hasNoFaultGpImRemoval')
    noFaultGpImVoteThresholdRate: number = null;

    @resetBy('hasNoFaultGpImRemoval')
    noFaultGpImTimeLimit: SimpleAnswerEnum = null;

    @resetBy('hasNoFaultGpImRemoval')
    noFaultGpImPenalty: SimpleAnswerEnum = null;
    
    private _noFaultFundTermination: SimpleAnswerEnum = null;

    @Expose()
    get noFaultFundTermination() {
        return this._noFaultFundTermination;
    }

    set noFaultFundTermination(value) {
        this._noFaultFundTermination = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasNoFaultFundTermination');
        }
    }

    @resetBy('hasNoFaultFundTermination')
    noFaultFundVoteThresholdRate: number = null;

    @resetBy('hasNoFaultFundTermination')
    noFaultFundTimeLimit: SimpleAnswerEnum = null;

    @resetBy('hasNoFaultFundTermination')
    noFaultFundPenalty: SimpleAnswerEnum = null;

    private _keyPersonProvision: SimpleAnswerEnum = null;

    @Expose()
    get keyPersonProvision() {
        return this._keyPersonProvision;
    }

    set keyPersonProvision(value) {
        this._keyPersonProvision = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasKeyPersonProvision');
        }
    }

    @resetBy('hasKeyPersonProvision')
    automaticSuspensionLift: SimpleAnswerEnum = null;

    @resetBy('hasKeyPersonProvision')
    keyPersonProVoteThresholdRate: number = null;

    //#endregion

    //#region <<< Section - Investment Restrictions and Leverage >>>

    private _concentrationLimit: SimpleAnswerEnum = null;

    @Expose()
    get concentrationLimit() {
        return this._concentrationLimit;
    }

    set concentrationLimit(value) {
        this._concentrationLimit = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasConcentrationLimit');
        }
    }

    @resetBy('hasConcentrationLimit')
    concentrationLimitRate: number = null;

    private _geographyLimit: SimpleAnswerEnum = null;

    @Expose()
    get geographyLimit() {
        return this._geographyLimit;
    }

    set geographyLimit(value) {
        this._geographyLimit = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasGeographyLimit');
        }
    }

    @resetBy('hasConcentrationLimit')
    @resetBy('hasGeographyLimit')
    geographyLimitDesc: string = null;

    private _fundLevelLeverageLimit: SimpleAnswerEnum = null;

    @Expose()
    get fundLevelLeverageLimit() {
        return this._fundLevelLeverageLimit;
    }

    set fundLevelLeverageLimit(value) {
        this._fundLevelLeverageLimit = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasFundLevelLeverageLimit');
        }
    }

    @resetBy('hasFundLevelLeverageLimit')
    @Transform( ({value}) => typeof(value) == "string" ? +value.trim() : value)
    fundLevelLeverageLimitType: LeverageBasisEnum = null;

    @resetBy('hasFundLevelLeverageLimit')
    fundLevelLeverageLimitRate: number = null;

    @resetBy('hasFundLevelLeverageLimit')
    fundLevelLeverageLimitDesc: string = null;

    private _assetLevelLeverageLimit: SimpleAnswerEnum = null;

    @Expose()
    get assetLevelLeverageLimit() {
        return this._assetLevelLeverageLimit;
    }

    set assetLevelLeverageLimit(value) {
        this._assetLevelLeverageLimit = value;
        if (value != SimpleAnswerEnum.Yes) { 
            ResetOrchestrator.reset(this, 'hasAssetLevelLeverageLimit');
        }
    }

    @resetBy('hasAssetLevelLeverageLimit')
    assetLevelLeverageLimitRate: number = null;

    @resetBy('hasAssetLevelLeverageLimit')
    @Transform( ({value}) => typeof(value) == "string" ? +value.trim() : value)
    assetLevelLeverageLimitType: LeverageBasisEnum = null;

    @resetBy('hasAssetLevelLeverageLimit')
    assetLevelLeverageLimitDesc: string = null;
    
    bridgeFinancingTerm: number = null;

    //#endregion

    modifiedBy: string = null;

    /* hasErrors(): boolean {
        let hasErrors = super.hasErrors(); 
        if (hasErrors){
            //this.state = ModelState.NotSaved;
        }
        return hasErrors;
    } */

    addRecyclingTimeLimit(recyclingTimeLimits: Array<RecyclingTimeLimit>){
        //recyclingTimeLimits.push(new RecyclingTimeLimit());
    }
    
    addRecyclingProceedsLimit(recyclingProceedsLimits: Array<RecyclingProceedsLimit>){
        //recyclingProceedsLimits.push(new RecyclingProceedsLimit());
    }
    
    removeFromCollection(collection: Array<any>, index: number){
        if (collection[index].id > 0){
            collection[index].markedForDeletion = true;
        }
        else {
            collection.splice(index, 1); 
        }
    }

    /* @Expose({ groups: ['meta'] })
    fundAuditURL = (field: string, collection: string = ''): string => `basicdata/fund/audit/${this.fundId}/hybrid/${this.id}/${collection ? collection : ''}${field}`; */

    constructor(fundId: number){
        this.fundId = fundId;
    }
        
    /* constructor(fundId: number) {
        super(fundId, EntityTypeEnum.Fund);
        this.fundId = fundId;
    } */

}