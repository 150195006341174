import { Pipe, PipeTransform } from '@angular/core';
import { flatDeep } from '../functions/helpers';

@Pipe({
  name: 'pickFromCollection'
})
export class PickFromCollectionPipe implements PipeTransform {

  transform(collection: Array<unknown>, field: string, condition: 'min' | 'max', ifNull: number = null, trigger:number): unknown {
    let haystack = flatDeep(collection,field);
    return Math[condition](...haystack) ?? ifNull;
  }
}
