import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AuthenticationStepEnum } from '../enums/enums';
import { Environment } from '../environment/environment.interface';
import { ENVIRONMENT } from '../environment/environment.token';
import { ApiMicroService } from './api.micro.service';
import { LocalStorageService } from './local-storage.service';
import { Credentials, ROLETITLES, User } from '../types/types';

export const AUTH = 'authenticate';
export const AUTH_2FA = 'authorizelocation';
export const REQUEST_2FA = 'requestNewLocationCode';
export const SIGN_OUT = 'signout';
export const STORAGE_KEY = 'username';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private _user: BehaviorSubject<User> = new BehaviorSubject(null);
  public user$ = this._user.asObservable();

  public set user(value) {
     this._user.next(value);
  }

  public get user() {
    return this._user.value;
  }
  
  private _authenticationStep: BehaviorSubject<AuthenticationStepEnum> = new BehaviorSubject(null);
  public authenticationStep$ = this._authenticationStep.asObservable();

  public set authenticationStep(value) {
    this._authenticationStep.next(value);
  }

  public get authenticationStep() {
    return this._authenticationStep.getValue();
  }

  private _isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public isAuthenticated$ = this._isAuthenticated.asObservable();

  public set isAuthenticated(value) {
    this._isAuthenticated.next(value);
  }

  public get isAuthenticated() {
    return this._isAuthenticated.getValue();
  }

  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    private store: LocalStorageService,
    private api: ApiMicroService,
    private router: Router,
    private title: Title
  ) { }

  ngOnDestroy() { }

  signIn(credentials: Credentials) {
    return this.api.post(AUTH, JSON.stringify(credentials));
  }

  signOut() {
    return this.api.post(SIGN_OUT, {});
  }

  verify2FA(credentials: Credentials): Observable<any> {
    return this.api.post(AUTH_2FA, credentials);
  }

  request2FA(credentials: Credentials): Observable<any> {
    return this.api.post(REQUEST_2FA, credentials);
  }

  ping(): Observable<User> {
    this.user = this.store.get(STORAGE_KEY);
    if (this.user && ((this.env.production && document.cookie.indexOf(`XSRF-TOKEN-${this.env.environmentVariable.toUpperCase()}`) >= 0) || !this.env.production)) {
      this.authenticationStep = AuthenticationStepEnum.IsAuthenticated;
      this.isAuthenticated = true;
      this.setSecurity(this.user);
      return of(this.user);
    } else {
      return of(null);
    }
  }

  setSecurity(user: User) {
    this.store.set(STORAGE_KEY, user);
    this.user = user;
  }

  clearSecurity() {
    this.store.remove(STORAGE_KEY);
    this.authenticationStep = AuthenticationStepEnum.NotAuthenticated;
    this.isAuthenticated = false;
    this.router.navigateByUrl('/login');
    this.title.setTitle(this.env.title);
  }
}
