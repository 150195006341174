import { Meta, meta, ModelState } from '@aksia-monorepo/shared-ui';
import { Type } from 'class-transformer';
import { AUMSpreadSheet, AUM, Program, Fund, ManagementCompany } from '..';
import { EntityTypeEnum, AUMSourceEnum } from '../../enums/enums';

export class AUMeta extends Meta {
  classType = 'AUMeta';
  entityId: number;
  entityTypeId: EntityTypeEnum;
  entityName: string;
  __group = 'entityName';

  @Type(() => Date)
  aumStartDate: Date;

  aumSpreadSheet: AUMSpreadSheet;

  @Type(() => AUM)
  selectedAum: AUM;

  @meta({ historyLength: 3 })
  selectedCurrency: string;

  @Type(() => Date)
  selectedAsOf: Date;

  selectedSource: AUMSourceEnum;

  sources = AUMSourceEnum.toKeyValue();

  @Type(() => AUM)
  @meta({ alias: '(As Of [asOfDate])', navigation: true })
  aum: Array<AUM>;

  //aumAuditURL = (asOf: Date, field: string):string => `aum/auditlogs/${this.fundId}/${EntityTypeEnum.Fund}/${this.aumMeta?.selectedAsOf?.getMonth() + 1}/${this.aumMeta?.selectedAsOf?.getFullYear()}/${field}`;
  auditSourceField = 'Source';
  auditURL = 'aum/auditlogs/{0}/{1}/{2}/{3}/{4}';

  set asof(value: Date) {
    AUMeta.setAsOf(this, value);
  }

  set currency(value: string) {
    this.selectedCurrency = value;
    AUMeta.setAUMCurrency(this, value);
  }

  set source(value: AUMSourceEnum) {
    this.selectedSource = value;
    AUMeta.setAUMSource(this, value);
  }

  toggleSpreadSheet(
    entity: ManagementCompany | Fund | Program
  ): boolean | null {
    return AUMeta.toggleSpreadSheet(entity);
  }

  toggleSpreadSheetHistory() {
    return AUMeta.toggleSpreadSheetHistory(this);
  }

  static toggleSpreadSheetHistory(self: AUMeta) {
    self.aumSpreadSheet.showHistory = !self.aumSpreadSheet.showHistory;
  }

  static toggleSpreadSheet(
    entity: ManagementCompany | Fund | Program
  ): boolean | null {
    /* entity.aumMeta.aumSpreadSheet.isVisible =
      !entity.aumMeta.aumSpreadSheet.isVisible;

    if (!entity.aumMeta.aumSpreadSheet.isVisible) {
      entity.aumMeta.aumSpreadSheet.showHistory = false;
    } else if (entity instanceof Program) {
      const programs = entity.parent as Array<Program>;
      programs?.forEach((program, index) => {
        if (program.investmentProgramId !== entity.investmentProgramId) {
          program.aumMeta.aumSpreadSheet.isVisible = false;
        }
      });
    }
    return entity.aumMeta.aumSpreadSheet.isVisible; */
    return null;
  }

  static setAUMSource(self: AUMeta, source: AUMSourceEnum) {
    self.selectedSource = source;
    let minYear, maxYear, minMonth, maxMonth;
    if (self.aumSpreadSheet?.isVisible) {
      minYear = maxYear =
        +self.aumSpreadSheet?.settings.rowTitles[
          self.aumSpreadSheet?.settings.selectedCell.row
        ];
      minMonth = maxMonth = self.aumSpreadSheet?.settings.selectedCell.col;

      if (self.aumSpreadSheet?.settings.markedCells) {
        for (const [key, value] of Object.entries(
          self.aumSpreadSheet.settings.markedCells
        )) {
          if (value > -1) {
            maxYear =
              +self.aumSpreadSheet?.settings.rowTitles[
                self.aumSpreadSheet?.settings.markedCells.startRow
              ];
            minYear =
              +self.aumSpreadSheet?.settings.rowTitles[
                self.aumSpreadSheet?.settings.markedCells.endRow
              ];
            minMonth = self.aumSpreadSheet?.settings.markedCells.startCol;
            maxMonth = self.aumSpreadSheet?.settings.markedCells.endCol;
            break;
          }
        }
      }
    } else {
      minYear = maxYear = self.selectedAsOf.getFullYear();
      minMonth = maxMonth = self.selectedAsOf.getMonth();
    }

    self.aum?.forEach((aum) => {
      if (
        aum.asOfDate.getFullYear() <= maxYear &&
        aum.asOfDate.getFullYear() >= minYear &&
        aum.asOfDate.getMonth() <= maxMonth &&
        aum.asOfDate.getMonth() >= minMonth &&
        aum.amount
      ) {
        aum.source = source;
        aum.state = aum.source ? ModelState.IsDirty : ModelState.HasError;
        self.state = aum.source ? ModelState.IsDirty : ModelState.HasError;
      }
    });
  }

  static setAsOf(self: AUMeta, asOf: Date) {
    let aum = self.aum?.find(
      (aum) =>
        aum?.asOfDate?.getFullYear() === asOf.getFullYear() &&
        aum?.asOfDate?.getMonth() === asOf.getMonth()
    );
    if (!aum) {
      aum = new AUM(self.entityId, self.entityTypeId, asOf);
      self.aum.push(aum);
    }
    aum.currency = self.selectedCurrency;
    self.selectedAum = aum;
    self.selectedAsOf = asOf;
    self.selectedSource = self.selectedAum?.source ?? null;
  }

  static setAUMCurrency(self: AUMeta, currency: string) {
    self.aum.forEach((aum) => {
      if (aum.amount) {
        aum.currency = currency;
        aum.state = ModelState.IsDirty;
      }
    });
  }

  initAUM(aumList: Array<AUM>, initCurrency: boolean = true) {
    if (aumList?.length > 0) {
      this.aum = aumList.filter((aum) => aum.asOfDate >= this.aumStartDate);
      if (this.aum?.length === 0) {
        const today = new Date();
        const latestDate = new Date(today.getFullYear(), today.getMonth(), 0);
        this.aum = [new AUM(this.entityId, this.entityTypeId, latestDate)];
      }

      //TODO: Fixes erroneous empty AUM saved in the past
      if (this.aum?.length > 1) {
        this.aum.forEach(
          (aum) =>
            (aum.state =
              !aum.amount && aum.currency ? ModelState.IsDirty : aum.state)
        );
      }

      this.selectedAum =
        this.aum
          .slice()
          .reverse()
          .find((aum) => aum?.amount) ?? this.aum[this.aum.length - 1];
      //this.aumStartDate = this.aumStartDate ?? this.aum[0]?.asOfDate;
      this.selectedAsOf = this.selectedAum?.asOfDate ?? new Date();

      if (initCurrency) {
        this.selectedCurrency = this.selectedAum.currency;
      }

      this.selectedSource = this.selectedAum?.source;
      //this.updateSpreadsheet();
    }
  }

  updateSpreadsheet() {
    if (!this.aumSpreadSheet) {
      this.aumSpreadSheet = new AUMSpreadSheet(
        this.entityId,
        this.entityTypeId,
        this.aumStartDate,
        [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        [new Date().getFullYear().toString()],
        Array.from(Array(12), (_, i) => new Date().getMonth() < i)
      );
    } else {
      this.aumSpreadSheet.entityStartDate = this.aumStartDate;
    }

    if (this.aumStartDate) {
      this.aumSpreadSheet.aumToSpreadSheet(this.aum);
    }
  }

  constructor(
    entityId: number,
    entityTypeId: EntityTypeEnum,
    startDate: Date,
    entityName: string
  ) {
    super();
    this.entityId = entityId;
    this.entityTypeId = entityTypeId;
    this.entityName = entityName;
    this.aumStartDate = startDate;
    this.state = ModelState.Ready;
  }
}
