import {
  DefaultValidationMessages,
  getAbbr,
  Meta,
  meta,
  ModelState,
  toLocalDate,
  ValidationStyleEnum,
} from '@aksia-monorepo/shared-ui';
import { Transform } from 'class-transformer';
import { ClosedEndDetails } from './fund.closedEndDetails';
import {
  CurrencyEnum,
  CloseDateTypeEnum,
  CommitmentsTypeEnum,
  ProgramStatusEnum,
  PeriodTypeEnum,
  SimpleAnswerEnum,
  RateIndexEnum,
  SubClInterestIndexEnum,
  MonthEnum,
  AksiaRoleEnum,
  IPSizeGroupEnum,
  SourceEnum,
  SourceDealEnum,
  CoInvFeeStructureEnum,
  RecyclingEnum,
  FeeBaseEnum,
  PlacementAgentEnum,
  InstrumentEnum,
  LiquidityTierEnum,
} from '../../enums/enums';
import { Fund } from './fund.model';

export class ClosedEndKeyTerm extends Meta {
  closedEndDataFieldsId: number = 0;

  //#region Details Date Info

  @meta({ alias: 'Effective Date', source: CloseDateTypeEnum.toKeyValue() })
  effectiveDateType?: CloseDateTypeEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Effective Date (mm/dd/yyyy)',
    softNotLessThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotLessThan,
      validateRule(self, value, target, prop) {
        if (
          target instanceof ClosedEndKeyTerm &&
          target.grandParent instanceof Fund
        ) {
          return (self.message =
            target.effectiveDate?.getTime() <
            target.grandParent?.dateOfFormation?.getTime()
              ? `Is usually occuring after Date of Formation`
              : (self.message = null));
        }
      },
    },
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.effectiveDate > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
  }) //As of Date or ProgramStatusAsOf
  effectiveDate?: Date;

  @meta({ alias: 'Effective Date (Description)' })
  effectiveDateDesc?: string;

  //#endregion

  //#region Capital Call Procedure / Capital Raise / Fund Terms

  @meta({
    alias: 'Commitments (Target)', // Target Capital Raise (Equity) (TargetFinalCloseSize)
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: 'Should not be more than',
      validateRule: (self, value, target, prop) => {
        prop = 'commitmentCap';
        let max = Math.min(
          self.value,
          target[prop] > 0 ? target[prop] : Number.POSITIVE_INFINITY
        );
        return !value || value <= max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${getAbbr(max, true)}`);
      },
    },
    softNotLessThan: 500_000_000,
    softNotMoreThan: 25_000_000_000,
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        self.validate('commitmentCap');
        if (self.grandParent instanceof Fund) {
          self.grandParent.shareClasses?.forEach((sc) =>
            sc.validate?.('minimumLPCommitment')
          );
          self.grandParent?.closedEndDetails?.closedEndStructure?.validate?.(
            'minGPCommitmentAmount'
          );
          self.grandParent?.closedEndDetails?.validate?.(
            'organizationalExpenseCapAmount'
          );
        }
      }
    },
  })
  commitmentTarget?: number;

  @meta({
    alias: 'Commitments (Cap)',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.commitmentCap = undefined;
        }
      }
    },
  })
  hasCommitmentCap?: SimpleAnswerEnum;

  @meta({
    alias: 'Commitments (Cap Amount)', // Target Capital Raise (Cap)
    hardNotLessOrEqualTo: 0,
    hardNotLessThan: {
      value: 0,
      type: ValidationStyleEnum.Hard,
      defaultMessage: 'Should not be less than',
      validateRule: (self, value, target, prop) => {
        prop = 'commitmentTarget';
        let min = Math.max(
          self.value,
          target[prop] > 0 ? target[prop] : Number.NEGATIVE_INFINITY
        );
        return !value || value >= min
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${getAbbr(min, true)}`);
      },
    },
    softNotLessThan: 500_000_000,
    softNotMoreThan: 25_000_000_000,
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        self.validate('commitmentTarget');
        if (self.grandParent instanceof Fund) {
          self.grandParent.shareClasses?.forEach((sc) =>
            sc.validate('minimumLPCommitment')
          );
          self.grandParent?.closedEndDetails?.closedEndStructure?.validate?.(
            'minGPCommitmentAmount'
          );
          self.grandParent?.closedEndDetails?.validate?.(
            'organizationalExpenseCapAmount'
          );
        }
      }
    },
  })
  commitmentCap?: number;

  @meta({ alias: 'Total Capital Raised (Equity)' }) // (Final Close Amount)
  commitmentsActual?: number;

  @meta({
    alias: 'Total Capital Raised (Equity) Status',
    source: CommitmentsTypeEnum.toKeyValue(),
  })
  commitmentsActualType?: CommitmentsTypeEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Total Capital Raised (Equity) As Of',
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.commitmentsAsOf > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
  })
  commitmentsAsOf?: Date;

  @meta({ alias: 'Placement Agent', source: PlacementAgentEnum.toKeyValue() })
  placementAgent?: string;

  @meta({
    alias: 'Subsequent Close Interest',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.subsequentCloseInterestType = undefined;
          self.subsequentCloseInterestRate = undefined;
          self.subsequentCloseInterestIndex = undefined;
          self.subsequentCloseInterestIndexDesc = undefined;
        }
      }
    },
  })
  subsequentCloseInterest?: SimpleAnswerEnum;

  @meta({
    alias: 'Subsequent Close Interest (Type)',
    source: RateIndexEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        if (value === RateIndexEnum.FixedRate) {
          self.subsequentCloseInterestIndex = undefined;
        } else if (value === RateIndexEnum.Index) {
          self.subsequentCloseInterestRate = undefined;
        }
      }
    },
  })
  subsequentCloseInterestType?: RateIndexEnum;

  @Transform(({ value }) => (typeof value == 'string' ? +value.trim() : value))
  @meta({
    alias: 'Subsequent Close Interest (Index)',
    source: SubClInterestIndexEnum.toKeyValue(),
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        if (value !== SubClInterestIndexEnum.Other) {
          self.subsequentCloseInterestIndexDesc = undefined;
        }
      }
    },
  })
  subsequentCloseInterestIndex: SubClInterestIndexEnum;

  @meta({ alias: 'Subsequent Close Interest (Description)' })
  subsequentCloseInterestIndexDesc: string;

  @meta({
    alias: 'Subsequent Close Interest (Rate)',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotLessThan: 2,
    softNotMoreThan: 10,
  })
  subsequentCloseInterestRate?: number;

  //#endregion

  //#region Fund Timing

  @meta({
    alias: 'Contractual Investment Period Expiration', // Investment Period
    hardNotLessOrEqualTo: 0,
    softNotLessThan: 2,
    softNotMoreThan: 6,
    updates: (self, value) => {
      if (
        self instanceof ClosedEndKeyTerm &&
        self.parent instanceof ClosedEndDetails
      ) {
        if (value) {
          if (!self.parent.contractualInvestmentPeriodExtensions?.length) {
            self.parent.add('contractualInvestmentPeriodExtensions', false);
          } else {
            self.parent.contractualInvestmentPeriodExtensions?.forEach((ext) =>
              ext.validate('duration')
            );
          }
        } else {
          self.contractualInvestmentPeriodExpirationType = undefined;
          self.contractualInvestmentPeriodExpirationDate = undefined;
          self.contractualInvestmentPeriodExpirationDesc = undefined;
          self.hasContractualInvestmentPeriodUnlimitedExtensions = undefined;
          self.parent.contractualInvestmentPeriodExtensions = undefined;
        }
      }
    },
  })
  contractualInvestmentPeriodExpirationYears: number;

  @meta({
    alias: 'Contractual Investment Period Expiration (Following)',
    source: PeriodTypeEnum.toKeyValue().filter(
      (per) =>
        ![
          PeriodTypeEnum.FollowingInvestmentPeriodEnd,
          PeriodTypeEnum.FollowingLiquidationOfInvestments,
        ].includes(per.key)
    ),
    updates: (self, value) => {
      if (
        self instanceof ClosedEndKeyTerm &&
        self.parent instanceof ClosedEndDetails
      ) {
        if ((value as PeriodTypeEnum) !== PeriodTypeEnum.FollowingOtherDate) {
          self.contractualInvestmentPeriodExpirationDate = undefined;
          self.contractualInvestmentPeriodExpirationDesc = undefined;
        }
      }
    },
  })
  contractualInvestmentPeriodExpirationType: PeriodTypeEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Contractual Investment Period Expiration (Other Date)' })
  contractualInvestmentPeriodExpirationDate: Date;

  @meta({ alias: 'Contractual Investment Period Expiration (Description)' })
  contractualInvestmentPeriodExpirationDesc: string;

  @meta({ alias: 'Unlimited Extensions' })
  hasContractualInvestmentPeriodUnlimitedExtensions?: boolean;

  @meta({
    alias: 'Contractual Term Expiration', // Harvest Period
    hardNotLessOrEqualTo: 0,
    softNotLessThan: 2,
    softNotMoreThan: 12,
    updates: (self, value) => {
      if (
        self instanceof ClosedEndKeyTerm &&
        self.parent instanceof ClosedEndDetails
      ) {
        if (value) {
          if (!self.parent.contractualTermExtensions?.length) {
            self.parent.add('contractualTermExtensions', false);
          } else {
            self.parent.contractualTermExtensions?.forEach((ext) =>
              ext.validate('duration')
            );
          }
        } else {
          self.contractualTermExpirationType = undefined;
          self.contractualTermExpirationDate = undefined;
          self.contractualTermExpirationDesc = undefined;
          self.hasContractualTermUnlimitedExtensions = undefined;
          self.parent.contractualTermExtensions = undefined;
        }
      }
    },
  })
  contractualTermExpirationYears: number;

  @meta({
    alias: 'Contractual Term Expiration (Following)',
    source: PeriodTypeEnum.toKeyValue(),
    updates: (self, value) => {
      if (
        self instanceof ClosedEndKeyTerm &&
        self.parent instanceof ClosedEndDetails
      ) {
        if ((value as PeriodTypeEnum) !== PeriodTypeEnum.FollowingOtherDate) {
          self.contractualTermExpirationDate = undefined;
          self.contractualTermExpirationDesc = undefined;
        }
      }
    },
  })
  contractualTermExpirationType: PeriodTypeEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Contractual Term Expiration (Other Date)' })
  contractualTermExpirationDate: Date;

  @meta({ alias: 'Contractual Term Expiration (Description)' })
  contractualTermExpirationDesc: string;

  @meta({ alias: 'Unlimited Extensions' })
  hasContractualTermUnlimitedExtensions?: boolean;

  //#endregion

  //#region Recycling

  @meta({
    alias: 'Recycling',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.hasTimeLimit = undefined;
          self.hasRecyclingLimit = undefined;
          self.hasProceedsLimit = undefined;
        }
      }
    },
  })
  hasRecycling?: SimpleAnswerEnum;

  @meta({
    alias: 'Time Limit(s)',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    updates: (self, value) => {
      if (
        self instanceof ClosedEndKeyTerm &&
        self.parent instanceof ClosedEndDetails
      ) {
        if (value === SimpleAnswerEnum.Yes) {
          if (!self.parent.recyclingTimeLimits?.length) {
            self.parent.add('recyclingTimeLimits');
          }
        } else {
          self.parent.recyclingTimeLimits = undefined;
        }
      }
    },
  })
  hasTimeLimit?: SimpleAnswerEnum;

  @meta({
    alias: '% Limit',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.recyclingCommitmentsRate = undefined;
        }
      }
    },
  })
  hasRecyclingLimit?: SimpleAnswerEnum;

  @meta({
    alias: '% Limit (Rate)',
    hardNotLessOrEqualTo: 0,
    softNotEmpty: true,
    softNotMoreThan: 200,
  })
  recyclingCommitmentsRate?: number;

  @meta({
    alias: 'Limit(s) Relating to Type of Proceeds',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    updates: (self, value) => {
      if (
        self instanceof ClosedEndKeyTerm &&
        self.parent instanceof ClosedEndDetails
      ) {
        if (value === SimpleAnswerEnum.Yes) {
          if (!self.parent.recyclingProceedsLimits?.length) {
            self.parent.add('recyclingProceedsLimits');
          }
        } else {
          self.parent.recyclingProceedsLimits = undefined;
        }
      }
    },
  })
  hasProceedsLimit?: SimpleAnswerEnum;

  //#endregion

  //#region Target Return Profile

  @Transform(
    ({ value }) => (value === SimpleAnswerEnum.NotSpecified ? null : value),
    { toClassOnly: true }
  )
  @meta({
    alias: 'Capital Call Line',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
  })
  capitalCallLine: SimpleAnswerEnum;

  //#endregion

  //#region Structure

  @meta({
    alias: 'AGM Timing',
    auditRoute: 'AGMTiming',
    source: MonthEnum.toKeyValue(),
  })
  agmTiming?: MonthEnum;

  //#endregion

  //#region Fund Terms

  @meta({
    alias: 'Oversuscribed Fund Raise',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  oversubscribedFundRaise?: SimpleAnswerEnum;

  @meta({
    alias: 'Fund Commitments - Total',
    hardNotLessThan: {
      value: Number.NEGATIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        if (!value) {
          return (self.message = null);
        }
        if (target instanceof ClosedEndKeyTerm) {
          if (target.fundCommitmentsGP && target.fundCommitmentsLP) {
            if (
              target.fundCommitmentsTotal <= target.fundCommitmentsGP ||
              target.fundCommitmentsTotal <= target.fundCommitmentsLP
            ) {
              return (self.message = `${self.defaultMessage} ${getAbbr(
                Math.max(
                  target.fundCommitmentsGP ?? 0,
                  target.fundCommitmentsLP ?? 0
                ),
                true
              )}`);
            }
          } else if (!target.fundCommitmentsGP || !target.fundCommitmentsLP) {
            if (
              target.fundCommitmentsTotal < target.fundCommitmentsGP ||
              target.fundCommitmentsTotal < target.fundCommitmentsLP
            ) {
              return (self.message = `${self.defaultMessage} ${getAbbr(
                Math.max(
                  target.fundCommitmentsGP ?? 0,
                  target.fundCommitmentsLP ?? 0
                ),
                true
              )}`);
            }
          }
        }
        return (self.message = null);
      },
    },
    softNotLessOrEqualTo: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotLessThan,
      validateRule: (self, value, target, prop) => {
        if (!value) {
          return (self.message = null);
        }
        if (target instanceof ClosedEndKeyTerm) {
          if (
            (target.fundCommitmentsTotal <= target.fundCommitmentsGP ||
              target.fundCommitmentsTotal <= target.fundCommitmentsLP) &&
            (!target.fundCommitmentsGP || !target.fundCommitmentsLP)
          ) {
            return (self.message = `${self.defaultMessage} ${getAbbr(
              Math.max(
                target.fundCommitmentsGP ?? 0,
                target.fundCommitmentsLP ?? 0
              ),
              true
            )}`);
          }
        }
        return (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        self.validate('fundCommitmentsLP');
        self.validate('fundCommitmentsGP');
      }
    },
  })
  fundCommitmentsTotal?: number;

  @meta({
    alias: 'Fund Commitments - LP',
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        if (!value) {
          return (self.message = null);
        }
        if (target instanceof ClosedEndKeyTerm) {
          if (target.fundCommitmentsGP && target.fundCommitmentsLP) {
            if (
              target.fundCommitmentsTotal <= target.fundCommitmentsGP ||
              target.fundCommitmentsTotal <= target.fundCommitmentsLP
            ) {
              return (self.message = `${self.defaultMessage} ${getAbbr(
                Math.max(
                  target.fundCommitmentsGP ?? 0,
                  target.fundCommitmentsLP ?? 0
                ),
                true
              )}`);
            }
          } else if (!target.fundCommitmentsGP || !target.fundCommitmentsLP) {
            if (
              target.fundCommitmentsTotal < target.fundCommitmentsGP ||
              target.fundCommitmentsTotal < target.fundCommitmentsLP
            ) {
              return (self.message = `${self.defaultMessage} ${getAbbr(
                Math.max(
                  target.fundCommitmentsGP ?? 0,
                  target.fundCommitmentsLP ?? 0
                ),
                true
              )}`);
            }
          }
        }
        return (self.message = null);
      },
    },
    softNotMoreOrEqualTo: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        if (!value) {
          return (self.message = null);
        }
        if (target instanceof ClosedEndKeyTerm) {
          if (
            (target.fundCommitmentsTotal <= target.fundCommitmentsGP ||
              target.fundCommitmentsTotal <= target.fundCommitmentsLP) &&
            (!target.fundCommitmentsGP || !target.fundCommitmentsLP)
          ) {
            return (self.message = `${self.defaultMessage} ${getAbbr(
              Math.max(
                target.fundCommitmentsGP ?? 0,
                target.fundCommitmentsLP ?? 0
              ),
              true
            )}`);
          }
        }
        return (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        self.validate('fundCommitmentsTotal');
        self.validate('fundCommitmentsGP');
      }
    },
  })
  fundCommitmentsLP?: number;

  @meta({
    alias: 'Fund Commitments - GP',
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        if (!value) {
          return (self.message = null);
        }
        if (target instanceof ClosedEndKeyTerm) {
          if (target.fundCommitmentsGP && target.fundCommitmentsLP) {
            if (
              target.fundCommitmentsTotal <= target.fundCommitmentsGP ||
              target.fundCommitmentsTotal <= target.fundCommitmentsLP
            ) {
              return (self.message = `${self.defaultMessage} ${getAbbr(
                Math.max(
                  target.fundCommitmentsGP ?? 0,
                  target.fundCommitmentsLP ?? 0
                ),
                true
              )}`);
            }
          } else if (!target.fundCommitmentsGP || !target.fundCommitmentsLP) {
            if (
              target.fundCommitmentsTotal < target.fundCommitmentsGP ||
              target.fundCommitmentsTotal < target.fundCommitmentsLP
            ) {
              return (self.message = `${self.defaultMessage} ${getAbbr(
                Math.max(
                  target.fundCommitmentsGP ?? 0,
                  target.fundCommitmentsLP ?? 0
                ),
                true
              )}`);
            }
          }
        }
        return (self.message = null);
      },
    },
    softNotMoreOrEqualTo: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        if (!value) {
          return (self.message = null);
        }
        if (target instanceof ClosedEndKeyTerm) {
          if (
            (target.fundCommitmentsTotal <= target.fundCommitmentsGP ||
              target.fundCommitmentsTotal <= target.fundCommitmentsLP) &&
            (!target.fundCommitmentsGP || !target.fundCommitmentsLP)
          ) {
            return (self.message = `${self.defaultMessage} ${getAbbr(
              Math.max(
                target.fundCommitmentsGP ?? 0,
                target.fundCommitmentsLP ?? 0
              ),
              true
            )}`);
          }
        }
        return (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof ClosedEndKeyTerm) {
        self.validate('fundCommitmentsTotal');
        self.validate('fundCommitmentsLP');
      }
    },
  })
  fundCommitmentsGP?: number;

  //#endregion

  //Already exist in meta?
  isDeleted?: boolean;
  modifiedBy?: string;
  modifiedOn?: Date;
  auditURL = 'basicdata/fund/audit/{0}/closedend/{1}/keyTerms/{2}';
  auditURLParams = ['grandParent.fundId@model', 'parent.id@model', '@prop'];

  constructor() {
    super();
    this.state = ModelState.Ready;
    this.excludeFromNotePath = true;
  }
}
