import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Note } from '../classes/note/note.model';
import { EntityTypeEnum } from '../enums/enums';
import { classToPlain } from 'class-transformer';
import { toCamelCase, toPascalCase } from 'libs/shared-ui/src/lib/functions/helpers';
import { Fund, ManagementCompany } from '../classes';
import { ApiMicroService, Meta, ModelState } from '@aksia-monorepo/shared-ui';
import { NoteDTO } from '../interfaces/system.interface';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  public state: ModelState;

  private readonly o2Notes = new BehaviorSubject<Map<string,Note>>(new Map());
  readonly o2Notes$ = this.o2Notes.asObservable();

  public get _o2Notes(): Map<string,Note> {
    return this.o2Notes.getValue();
  }

  public set _o2Notes(next: Map<string,Note>) {
    this.o2Notes.next(next);
  }

  constructor(
    private api: ApiMicroService
  ) { }

  public getNotes(noteRequests: Array<{ entityId: number, entityTypeId: EntityTypeEnum }>): Observable<Array<NoteDTO>> {
    this._o2Notes.clear();
    return this.api.post(`metadata/get/bulk`, noteRequests)
  }

  public updateNotes(model: Fund | ManagementCompany, callback?:() => void) {
    this.state = ModelState.Saving;
    let dirtyNotes = [...this._o2Notes.values()]?.filter( noteValue => noteValue._isDirty );
    let notesDTO = classToPlain(dirtyNotes,{excludePrefixes:["_"]});
    this.api.post(`metadata/manage/bulk`, notesDTO)
    .subscribe({
      next: (response: Array<NoteDTO>) => {
        response?.forEach( noteDTO => {
          let noteKV = this.toMapModel(noteDTO,model);
          if (noteKV){
            const id = noteKV.noteValue.entityTypeId === EntityTypeEnum.Shareclass ? (model as Fund).shareClasses.find(shareclass => shareclass.classId === noteKV.noteValue.entityId).classId : 
                             noteKV.noteValue.entityTypeId === EntityTypeEnum.Fund ? (model as Fund).fundId : (model as ManagementCompany).managementCompanyId;
            this._o2Notes.set(`${noteKV.key}_${noteKV.noteValue.entityTypeId}_${id}`,noteKV.noteValue);
          }
        });
      },
      error: (error) => {
        console.error(error);
        this.state = ModelState.NotSaved;
        callback();
      },
      complete: () => {
        console.warn('Notes Saved!')
        if (this.state !== ModelState.HasError && this.state !== ModelState.NotSaved){
          this.state = ModelState.Saved;
        }
        callback();
      }
    });
  }

  public toMapModel(noteDTO: NoteDTO, entity: Fund | ManagementCompany): { key: string, noteValue: Note } {
    const noteValue = new Note(noteDTO.entityId,noteDTO.entityTypeId);
    const fieldName = this.formatFieldName(noteDTO.fieldName,false);
    const aliasFor = fieldName?.split('.').pop();
    const key = fieldName;
    
    let aliasEntityFor;
    if (fieldName.includes('.')) {
      if (noteDTO.entityTypeId === EntityTypeEnum.ManagementCompany){
        try {
          aliasEntityFor = eval(`entity.${fieldName?.split('.').shift()}`) as Meta;
        }
        catch (error) {
          console.error(error);
        }
      }
      else if (noteDTO.entityTypeId === EntityTypeEnum.Fund){
        try {
          aliasEntityFor = eval(`entity.${fieldName?.split('.').shift()}`) as Meta;
        }
        catch (error) {
          try {
            aliasEntityFor = eval(`entity.closedEndDetails.${fieldName?.split('.').shift()}`) as Meta;
          }
          catch (error) {
            try {
              aliasEntityFor = eval(`entity.closedEndDetails.closedEndStructure.${fieldName?.split('.').shift()}`) as Meta;
            }
            catch (error) {
              try {
                aliasEntityFor = eval(`entity.leverageMeta.${fieldName?.split('.').shift()}`) as Meta;
              }
              catch (error) {
                aliasEntityFor = null;
              }
            }
          }
        }
      }
      else if (noteDTO.entityTypeId === EntityTypeEnum.Shareclass){
        const index = (entity as Fund).shareClasses.findIndex( shareclass => shareclass.classId === noteDTO.entityId);
        try {
          aliasEntityFor = eval(`entity.shareClasses[${index}].${fieldName?.split('.').slice(-3,-1).join('.')}`) as Meta;
        }
        catch (error) {
          
        }
      }
    }
    else {
      if (noteDTO.entityTypeId === EntityTypeEnum.ManagementCompany){
        aliasEntityFor = entity as Meta;
      }
      if (noteDTO.entityTypeId === EntityTypeEnum.Fund){
        aliasEntityFor = this.getAliasEntity(entity,fieldName);
      }
      else if (noteDTO.entityTypeId === EntityTypeEnum.Shareclass){
        const index = (entity as Fund).shareClasses.findIndex( shareclass => shareclass.classId === noteDTO.entityId);
        aliasEntityFor = (entity as Fund).shareClasses[index];
      }
    }

    noteValue._label = aliasEntityFor?.getClassMeta('alias',aliasFor);

    if (noteValue._label?.endsWith('##')){
      noteValue._label = noteValue._label.replace('##',aliasEntityFor?.parent?.filter( item => !item.markedForDeletion)?.length > 1 ? `#${aliasEntityFor?.index + 1}` : '');
    }

    noteValue._group = !noteValue._label ? 'Unmapped' : noteValue.entityTypeId === EntityTypeEnum.Shareclass ? (entity as Fund).shareClasses.find( sclass => sclass.classId == noteValue.entityId).className ?? '' : '';
    noteValue._path = this.formatNotePath(noteDTO.fieldName?.split('.').slice(-3,-1).join('.'),entity);
    noteValue.fieldName = noteDTO.fieldName;
    noteValue.modifiedBy = noteDTO.modifiedBy;
    noteValue.modifiedOn = new Date(noteDTO.modifiedOn);
    noteValue.note = noteDTO.note;
    return {key, noteValue};
  }

  private getAliasEntity(entity: Meta, fieldName: string) {
    if (Object.getPrototypeOf(entity).hasOwnProperty(fieldName)){
      return entity;
    }
    else if (entity['leverageMeta'] && Object.getPrototypeOf(entity['leverageMeta']).hasOwnProperty(fieldName)){
      return entity['leverageMeta'];
    }
    else if (entity['closedEndDetails'] && Object.getPrototypeOf(entity['closedEndDetails']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails'];
    }
    else if (entity['closedEndDetails'] && entity['closedEndDetails']['closedEndKeyTerm'] && Object.getPrototypeOf(entity['closedEndDetails']['closedEndKeyTerm']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails']['closedEndKeyTerm'];
    }
    else if (entity['closedEndDetails'] && entity['closedEndDetails']['closedEndStructure'] && Object.getPrototypeOf(entity['closedEndDetails']['closedEndStructure']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails']['closedEndStructure'];
    }
    else if (entity['closedEndDetails'] && entity['closedEndDetails']['closedEndGPInformation'] && Object.getPrototypeOf(entity['closedEndDetails']['closedEndGPInformation']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails']['closedEndGPInformation'];
    }
    else if (entity['closedEndDetails'] && entity['closedEndDetails']['closedEndCategorizationFactor'] && Object.getPrototypeOf(entity['closedEndDetails']['closedEndCategorizationFactor']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails']['closedEndCategorizationFactor'];
    }
    else if (entity['closedEndDetails'] && entity['closedEndDetails']['closedEndGPTargetInvestmentStat'] && Object.getPrototypeOf(entity['closedEndDetails']['closedEndGPTargetInvestmentStat']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails']['closedEndGPTargetInvestmentStat'];
    }
    else if (entity['closedEndDetails'] && entity['closedEndDetails']['closedEndTargetReturnProfile'] && Object.getPrototypeOf(entity['closedEndDetails']['closedEndTargetReturnProfile']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails']['closedEndTargetReturnProfile'];
    }
    else if (entity['closedEndDetails'] && entity['closedEndDetails']['closedEndTrackRecord'] && Object.getPrototypeOf(entity['closedEndDetails']['closedEndTrackRecord']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails']['closedEndTrackRecord'];
    }
    else if (entity['closedEndDetails'] && entity['closedEndDetails']['closedEndTeamViewModel'] && Object.getPrototypeOf(entity['closedEndDetails']['closedEndTeamViewModel']).hasOwnProperty(fieldName)){
      return entity['closedEndDetails']['closedEndTeamViewModel'];
    }
    else if (entity['coInvestmentDetails'] && Object.getPrototypeOf(entity['coInvestmentDetails']).hasOwnProperty(fieldName)){
      return entity['coInvestmentDetails'];
    }
    else if (entity['secondaryDetails'] && Object.getPrototypeOf(entity['secondaryDetails']).hasOwnProperty(fieldName)){
      return entity['secondaryDetails'];
    }
    else {
      return null;
    }
  }
  
  /**
   * Transforms object property path to path represetation for the UI 
   * (ex. "mgmtFeePeriod[0].mgmtFeeAmount[1]" to "Management Fee Period #1 - Management Fee Amount #2")
   *
   * @param value - The whole property path as a string by entity (ex. "mgmtFeePeriod[0].mgmtFeeAmount[1]")
   * @param ctx - The root instance of the entity (ex. MgmtFeePeriod)
   * @returns A string Representation of the property path (ex. "Management Fee Period #1 - Management Fee Amount #2")
   *
   */
  public formatNotePath(value:string, ctx: any){
    let path = '';

    if (!value || value?.indexOf('[') === -1)
      return '';
    
    if (value.indexOf('.') !== -1){
      if (Array.isArray(ctx?.grandParent?.parent)){
        path += `${ctx.grandParent.parentProp} #${ctx.grandParent.index} - ${ctx.parentProp} #${ctx.index}`;
      }
      else if (Array.isArray(ctx?.parent)){
        path += `${ctx.parentProp} #${ctx.index}`;
      }
    }
    else {
      if (Array.isArray(ctx?.parent)){
        path += `${ctx.parentProp} #${ctx.index}`;
      }
    } 
    return path;
  }

  /**
   * Transforms object property path string to client (camel case) and server (pascal case) format 
   * (ex. "mgmtFeePeriod[0].mgmtFeeAmount[1].feeRate" to "MgmtFeePeriod[0].MmgmtFeeAmount[1].FeeRate" and vice versa)
   *
   * @param value - The whole property path by entity (ex. "mgmtFeePeriod[0].mgmtFeeAmount[1].feeRate")
   * @param toDTO - A boolean flag:  true = Pascal Case, false = camel Case
   * @returns Transformed object property path string (ex. "MgmtFeePeriod[0].MmgmtFeeAmount[1].FeeRate")
   *
   */
  
  public formatFieldName(value: string, toDTO: boolean = false):string {
    if (value.indexOf('.') !== -1){
      let valueParts = value?.split('.');
      valueParts = valueParts.map( vp => toDTO ? vp = toPascalCase(vp) : vp = toCamelCase(vp));
      return valueParts.join('.');
    }
    return toDTO ? toPascalCase(value) : toCamelCase(value);
  }


}
