
import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, ElementRef, EventEmitter, forwardRef, Inject, Injector, Input, NgZone, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { BaseLoggingService } from '../../../services/base-logging.service';
import { BaseComponent, UIControlSettings } from '../base/base.component';

type TextSettings = {
  multiline?: boolean;
  showCharCount?: boolean;
  type?: 'text' | 'password';
}

const defaultTextSettings: TextSettings = {
  multiline: false,
  showCharCount: false,
  type: 'text'
}

@Component({
  selector: 'text-v2',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent extends BaseComponent {

  //#region Icons

  faCompressAlt = faCompressAlt;
  faExpandAlt = faExpandAlt;
  
  //#endregion Icons;

  autoCollapse: boolean = true;

  constructor(private ngZone: NgZone, public injector: Injector, cmpFactoryResolver: ComponentFactoryResolver, elRef: ElementRef, @Inject(BaseLoggingService) logService: BaseLoggingService) { 
    super(injector,cmpFactoryResolver,elRef,logService);
    this._settings = {...this._settings, ...defaultTextSettings};
  }

  //#region ElementRefs

  @ViewChild('input') input: ElementRef;
  @ViewChild('textarea') textarea: ElementRef;

  //#endregion


  //#region Input/Output

  @Input() 
  set settings(value: UIControlSettings & TextSettings){
    for (const property in value) {
      this._settings[property] = value[property] ?? this._settings[property];
    }
  }

  get settings() {
    return this._settings;
  }

  @Output() keyUpEnter: EventEmitter<any> = new EventEmitter();
  
  //#endregion

  //#region Component Specific

  toggleAutoCollapse(){
    this.autoCollapse = !this.autoCollapse;
    if (this.autoCollapse)
      this.textarea.nativeElement.style.height = '20px';
    else this.updateSize();
  }

  private updateSize(): void {
    this.ngZone.runOutsideAngular(() => {
      this.textarea.nativeElement.style.height = 'auto';
      this.textarea.nativeElement.style.height = `${this.textarea.nativeElement.scrollHeight}px`;
    });
  }

  //#endregion

  //#region Overrides

  keyEnter(value: string){
    this.focusout(value);
    this.keyUpEnter.emit(value);
  }

  focusin(force?: boolean) {
    if (this.settings.multiline && this.autoCollapse) {
      this.updateSize();
    }
    if (force) {
      if (this.settings.multiline){
        this.textarea.nativeElement.focus();
      }
      else {
        this.input.nativeElement.focus();
      }
    }
    super.focusin();
  }

  focusout(value: string) {
    super.focusout(value);
    if (this.settings.multiline) {
      if (this.autoCollapse) {
        this.textarea.nativeElement.style.height = '20px';
      }
    } 
    else {
      this.input.nativeElement.blur();
    }
  }

  //#endregion
}
