<div class="ui-control rows3" [class]="[labelPositionEnum[settings.labelPosition],modelState[state]]" [class.disabled]="disabled">
    <label-v2 class="ui-header" 
        [label]="focused || !!(value$ | async) ? (settings.labelAlt || settings.label) : settings.label" 
        [class.focused]="focused || !!(value$ | async)" 
        [tooltip]="settings.tooltip"
        (click)="focusin(true)">
    </label-v2>
    <textarea class="ui-body r2s1" #textarea *ngIf="settings.multiline"
        [disabled]="disabled"
        [value]="(value$ | async) || ''" 
        [placeholder]="(focused || !settings.label) ? settings.placeholder : ''"
        (focus)="focusin()"
        (blur)="focusout(textarea.value)"
        (keyup.enter)="textarea.blur()">
    </textarea>
    <input #input class="ui-body r2s1" *ngIf="!settings.multiline"
        [disabled]="disabled"
        [value]="(value$ | async) || ''"
        [title]="(value$ | async) || ''"
        [type]="settings.type" 
        [placeholder]="(focused || !settings.label) ? settings.placeholder : ''"
        (focus)="focusin()"
        (blur)="focusout(input.value)"
        (keyup.enter)="keyEnter(input.value)"
    />
    <div class="ui-toolbar r2s1">
        <fa-icon e2e-id="reset" [icon]="faTimes" *ngIf="settings.canReset && !disabled && !!(value$ | async)" (click)="reset()"></fa-icon>
        <fa-icon *ngIf="settings.multiline" [icon]="!autoCollapse ? faCompressAlt : faExpandAlt" [attr.title]="autoCollapse ? 'Turn auto collapse off' : 'Turn auto collapse on'" (click)="toggleAutoCollapse()"></fa-icon>
        <ng-template inject></ng-template>
        <ng-content></ng-content>
    </div>
    <div class="ui-icon r2s1">
        <ng-content select="[icon]"></ng-content>
    </div>
    <span class="ui-footer r3s1 c1s20" [class]="settings.footerAlignment" 
        *ngIf="settings.showCharCount && !!(value$ | async) && state !== modelState.HasError">characters: {{(value$ | async).length}}
    </span>
    <span class="ui-footer r3s1 c1s20" [class]="settings.footerAlignment" 
        *ngIf="settings.referralMessage && (value$ | async) === settings.referralValue && state !== modelState.HasError">{{settings.referralMessage}}
    </span>
    <validation-v2 class="ui-footer r3s1 c1s20" [class]="settings.footerAlignment" [validations]="validations | async"></validation-v2>
</div>
