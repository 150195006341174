import {
  toPascalCase,
  SharedMeta,
  NumberComponent,
  DateComponent,
  DropdownComponent,
  ApiMicroService,
  MultiSelectComponent,
} from '@aksia-monorepo/shared-ui';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { IAuditLog } from '../interfaces/system.interface';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  //#region Properties

  private readonly auditLog = new BehaviorSubject<IAuditLog[]>(null);
  readonly auditLog$ = this.auditLog.asObservable();
  private readonly auditIsLoading = new BehaviorSubject<boolean>(false);
  readonly auditIsLoading$ = this.auditIsLoading.asObservable();

  public get _auditLog(): IAuditLog[] {
    return this.auditLog.getValue();
  }

  public set _auditLog(next: IAuditLog[]) {
    this.auditLog.next(next);
  }

  public get _auditIsLoading(): boolean {
    return this.auditIsLoading.getValue();
  }

  public set _auditIsLoading(next: boolean) {
    this.auditIsLoading.next(next);
  }

  //#endregion

  constructor(private api: ApiMicroService) {}

  public getAUMAudit(
    property: string,
    entityId: number,
    enitytTypeId: number,
    month: number,
    year: number,
    loaderIds: string[]
  ): void {
    if (month == -1 || year == -1) {
      this._auditLog = [];
      return;
    }

    this._auditLog = null;
    this.api
      .get(
        `aum/auditlogs/${entityId}/${enitytTypeId}/${
          month + 1
        }/${year}/${property}`
      )
      .subscribe(
        (response: IAuditLog[]) => {
          this._auditLog = response.map((z) => ({
            ...z,
            modifiedOn: new Date(z.modifiedOn).toLocaleDateString('en-US'),
          }));
        },
        () => (this._auditLog = [])
      );
  }

  public getHistory(
    url: string,
    prop?: string,
    transform: Function = null,
    filterProperty?: string
  ) {
    this.auditIsLoading.next(true);
    this._auditLog = null;
    this.api.get(url).subscribe(
      (response: IAuditLog[]) => {
        if (filterProperty) {
          response = response.filter(
            (resp) => resp.propertyName === filterProperty
          );
        }
        if (prop) {
          if (response[prop]) {
            this._auditLog = response[prop].sort((a, b) =>
              new Date(a.modifiedOn ?? '1/1/1970') <
              new Date(b.modifiedOn ?? '1/1/1970')
                ? 1
                : -1
            );
          }
        } else {
          if (Array.isArray(response)) {
            this._auditLog = response?.sort((a, b) =>
              a.modifiedOn < b.modifiedOn ? 1 : -1
            );
          }
        }
        if (transform) {
          this._auditLog = transform(this._auditLog);
        }
        this.auditIsLoading.next(false);
      },
      () => (this._auditLog = [])
    );
  }

  public clearLog() {
    this._auditLog = null;
  }

  public getAuditURL(ctx: any) {
    const getAuditProp = (ctx: any) => {
      let auditProp = SharedMeta.getMeta('auditRoute', ctx.model, ctx.prop);
      if (!auditProp) {
        auditProp = toPascalCase(ctx.prop);
      }
      return auditProp;
    };
    if (ctx.model) {
      let auditURL = ctx.model['auditURL'];
      let auditURLParams = ctx.model['auditURLParams'];
      auditURLParams?.forEach((param, index) => {
        const fromParam = param.split('@')[1];
        const prop = param.split('@')[0];
        const value =
          fromParam === 'prop'
            ? getAuditProp(ctx)
            : SharedMeta.searchValueInPath(ctx[fromParam], prop.split('.'));
        auditURL = auditURL.replace(`{${index}}`, value);
      });
      return auditURL;
    }
    return undefined;
  }

  public getAuditFormat(ctx: any) {
    if (ctx instanceof NumberComponent) {
      return ctx.settings?.abbr ? 'currency' : 'decimal';
    } else if (ctx instanceof DateComponent) {
      return 'date';
    } else if (
      ctx instanceof DropdownComponent ||
      ctx instanceof MultiSelectComponent
    ) {
      return 'enum';
    }
    return 'string';
  }

  public getAuditCollection(ctx: DropdownComponent | MultiSelectComponent) {
    let collection;
    if (ctx instanceof DropdownComponent) {
      collection = ctx.source?.map((src) => {
        return { key: src[ctx.settings.key], value: src[ctx.settings.value] };
      });
    } else if (ctx instanceof MultiSelectComponent) {
      collection = ctx.source?.flatMap((src) =>
        src[1].map((srcItem) => {
          return { key: srcItem.id, value: srcItem.name };
        })
      );
    }
    return collection;
  }

  public getAuditResponseProp(ctx: any) {
    if (ctx.model) {
      let auditResponseProp = SharedMeta.getMeta(
        'auditResponseProp',
        ctx.model,
        ctx.prop
      );
      return auditResponseProp;
    }
    return undefined;
  }

  public getAuditSourceField(ctx: any) {
    if (ctx.model) {
      let auditSourceField = ctx.model['auditSourceField'] ?? 'DataSource';
      return auditSourceField;
    }
  }

  public getAuditAsOfField(ctx: any) {
    if (ctx.model) {
      let auditSourceField = ctx.model['auditAsOfField'] ?? 'DataAsOfDate';
      return auditSourceField;
    }
  }
}
