import { SpreadSheetSettings } from '@aksia-monorepo/shared-ui';
import { plainToClass } from 'class-transformer';
import { ManagementCompany, AUMeta, AUMSpreadSheet, Program } from '..';
import { StreamMeta } from '../stream/stream.meta';
import { StreamSpreadSheet } from '../stream/stream.spreadsheet';

export class CompanyUtils {
  static copy(source) {
    const plainCompany = source.toPlain();
    const company = plainToClass(ManagementCompany, plainCompany);
    company.addresses?.forEach(
      (addr, i) =>
        (addr.countryState = plainCompany.addresses?.find((addrDTO) =>
          CompanyUtils.compareAddress(addrDTO, addr)
        )?.state)
    );

    company.investmentPrograms = plainCompany.investmentPrograms.map(
      (program) => {
        const investmentProgram = plainToClass(Program, program);
        return investmentProgram;
      }
    );
    return company;
  }

  static compareAddress(a1, a2) {
    return a1.addressId > 0
      ? a1.addressId === a2.addressId
      : !!a1.latitude
      ? a1.latitude === a2.latitude && a1.longitude === a2.longitude
      : a1.address1 === a2.address1 &&
        a1.address2 === a2.address2 &&
        a1.city === a2.city &&
        a1.zip === a2.zip &&
        a1.country === a2.country;
  }
}
