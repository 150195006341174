import { AuthenticationService, BaseLoggingService, LogCategory } from '@aksia-monorepo/shared-ui';
import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: 'root'
})
export class LoggingService implements BaseLoggingService {

  constructor(private auth: AuthenticationService){}

  addInfo(category: LogCategory, message: string, data?: unknown){
    Sentry.addBreadcrumb({
      category: category,
      message: message,
      data: data,
      level: "info" 
    });
  }

  logInfo(message: string){
    Sentry.captureMessage(`${message} by ${this.auth.user.fullName}`, "info");
  }

  logException(error: unknown){
    Sentry.captureException(error);
  }
}
