import { DefaultValidationMessages, getAbbr, Meta, meta, ModelState, toLocalDate, ValidationStyleEnum } from "@aksia-monorepo/shared-ui";
import { Transform, Type } from "class-transformer";
import { CloseDateTypeEnum, ContractualDateTypeEnum, MinGPCommitmentEnum, SimpleAnswerEnum, RankEnum, AccountingMethodEnum, LiquidityTierEnum } from "../../enums/enums";
import { ContractualExtension, RecyclingTimeLimit, RecyclingProceedsLimit, LpClawbackPerLimit, LpClawbackTimeLimit, ClosedEndInterimDate } from "../entities/entities.model";
import { ClosedEndGPInformation } from "./fund.closedEndGPInformation";
import { ClosedEndGPTargetInvestmentStat } from "./fund.closedEndGPTargetInvestmentStat";
import { ClosedEndKeyTerm } from "./fund.closedEndKeyTerm";
import { ClosedEndStructure } from "./fund.closedEndStructure";
import { ClosedEndTargetReturnProfile } from "./fund.closedEndTargetReturnProfile";
import { Fund } from "./fund.model";

export class ClosedEndDetails extends Meta {
    id: number;
    fundId: number;

    //#region <<< Section - Details Date Info >>>

    @meta({ alias: 'Investment Period Expiration Date', source: CloseDateTypeEnum.toKeyValue() })
    investmentPeriodExpirationDateType?: CloseDateTypeEnum; 

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'Investment Period Expiration Date (mm/dd/yyyy)' })
    investmentPeriodExpirationDate?: Date;

    @meta({ alias: 'Term Expiration Date', source: CloseDateTypeEnum.toKeyValue() })
    termExpirationDateType?: CloseDateTypeEnum; 

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'Term Expiration Date (mm/dd/yyyy)' })
    termExpirationDate?: Date = null;
    
    //#endregion

    //#region <<< Section - Fund Timing >>>

    @meta({
        alias: 'Contractual Final Close',
        source: ContractualDateTypeEnum.toKeyValue(),
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value) {
                    if (!self.contractualFinalCloseExtensions?.length){
                        self.add('contractualFinalCloseExtensions',self.hasModified('contractualFinalCloseDateType'));
                    }
                    if (value === ContractualDateTypeEnum.Fixed_date){
                        self.contractualFinalCloseMonths = undefined;
                        self.contractualFinalCloseDateDesc = undefined;
                    }
                    else {
                        self.contractualFinalCloseDateDesc = undefined;
                        self.contractualFinalCloseDate = undefined;
                    }
                }
                else {
                    self.contractualFinalCloseDateDesc = undefined;
                    self.contractualFinalCloseDate = undefined;
                    self.contractualFinalCloseMonths = undefined;
                    self.contractualFinalCloseUnlimitedExtensions = undefined;
                    self.contractualFinalCloseExtensions = undefined;
                }
            }
        }
    })
    contractualFinalCloseDateType?: ContractualDateTypeEnum;

    @meta({ alias: 'Contractual Final Close (Description)' })
    contractualFinalCloseDateDesc?: string;

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'Contractual Final Close Fixed Date' })
    contractualFinalCloseDate?: Date;

    @meta({ 
        alias: 'Contractual Final Close (within # months)', 
        hardNotLessOrEqualTo: 0,
        softNotLessThan: 6, 
        softNotMoreThan: 24, 
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                self.contractualFinalCloseExtensions?.forEach(ext => ext.validate('duration'));
            }
            
        } 

    })
    contractualFinalCloseMonths?: number;

    @meta({ alias: 'Unlimited Extensions' })
    contractualFinalCloseUnlimitedExtensions?: boolean;

    @Type(() => ContractualExtension)
    @Transform((model) => {
        if (model.value?.length > 0){
            return model.value;
        }
        else if (model.obj['contractualFinalCloseDateType']){
                return [new ContractualExtension()];
        }
        return undefined;
        
    }, {toClassOnly: true})
    @meta({
        alias: 'Contractual Final Close ##',
        navigation: true,
        adds: 'ContractualExtension',
        removes: 'hard',
        auditRoute: 'contractual' 
    })
    contractualFinalCloseExtensions?: Array<ContractualExtension>;

    @Type(() => ContractualExtension)
    @Transform((model) => {
        if (model.value?.length > 0){
            return model.value;
        }
        else if (model.obj['closedEndKeyTerm'] && model.obj['closedEndKeyTerm']['contractualInvestmentPeriodExpirationYears']){
                return [new ContractualExtension()];
        }
        return undefined;
        
    }, {toClassOnly: true})
    @meta({ 
        alias: 'Contractual Investment Period ##',
        navigation: true,
        adds: 'ContractualExtension',
        removes: 'hard',
        auditRoute: 'investmentperiod' 
    })
    contractualInvestmentPeriodExtensions?: Array<ContractualExtension>;

    @Type(() => ContractualExtension)
    @Transform((model) => {
        if (model.value?.length > 0){
            return model.value;
        }
        else if (model.obj['closedEndKeyTerm'] && model.obj['closedEndKeyTerm']['contractualTermExpirationYears']){
                return [new ContractualExtension()];
        }
        return undefined;
        
    }, {toClassOnly: true})
    @meta({ 
        alias: 'Contractual Term ##',
        navigation: true,
        adds: 'ContractualExtension',
        removes: 'hard',
        auditRoute: 'term' 
    })
    contractualTermExtensions?: Array<ContractualExtension>;

    //#endregion

    //#region <<< Section - Capital Call Procedure >>>
 
    @meta({ 
        alias: 'Follow-on Investments % Limit',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.followOnInvestmentsPerLimitRate = undefined;
                }
            }
        } 
    }) 
    followOnInvestmentsPerLimit: SimpleAnswerEnum;

    @meta({ alias: 'Follow-on Investments % Limit (Rate)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessThan: 10, softNotMoreThan: 35 })
    followOnInvestmentsPerLimitRate: number;

    @meta({ 
        alias: 'Follow-on Investments Time Limit',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.followOnInvestmentsTimeLimitMonths = undefined;
                }
            }
        } 
    })
    followOnInvestmentsTimeLimit: SimpleAnswerEnum;
    
    @meta({ alias: 'Follow-on Investments Time Limit (Months)', hardNotLessOrEqualTo: 0, softNotEmpty: true, softNotLessThan: 3, softNotMoreThan: 36 })
    followOnInvestmentsTimeLimitMonths: number;

    @meta({ 
        alias: 'Investments in Progress Time Limit',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.investmentsInProgressTimeLimitMonths = undefined;
                }
            }
        } 
    })
    investmentsInProgressTimeLimit: SimpleAnswerEnum;

    @meta({ alias: 'Investments in Progress Time Limit (Months)', hardNotLessOrEqualTo: 0, softNotEmpty: true, softNotLessThan: 3, softNotMoreThan: 36 })
    investmentsInProgressTimeLimitMonths: number

    //#endregion

    //#region <<< Section - Organizational Expense Cap >>>

    @meta({ 
        alias: 'Organizational Expense Cap',
        source: MinGPCommitmentEnum.toKeyValue(),
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value == MinGPCommitmentEnum.Percentage) {
                    self.organizationalExpenseCapAmount = undefined;
                }
                else if (value === MinGPCommitmentEnum.Amount) {
                    self.organizationalExpenseCapRate = undefined;
                }
                else if (!value) {
                    self.organizationalExpenseCapAmount = undefined;
                    self.organizationalExpenseCapRate = undefined;
                }
            }
        } 
    })
    organizationalExpenseCapType?: MinGPCommitmentEnum;

    @meta({ alias: 'Organizational Expense Cap (Rate)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotLessThan: 0.1, softNotMoreThan: 0.4 })
    organizationalExpenseCapRate?: number;

    @meta({ 
        alias: 'Organizational Expense Cap (Amount)', 
        hardNotLessOrEqualTo: 0, 
        hardNotMoreOrEqualTo: {
            value: Number.POSITIVE_INFINITY,
            type: ValidationStyleEnum.Hard,
            defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
            validateRule(self, value, target, prop) {
                if (!value) return self.message = null;
                if (target instanceof ClosedEndDetails && target?.closedEndKeyTerm instanceof ClosedEndKeyTerm){
                    if (target.closedEndKeyTerm.commitmentCap){
                        return self.message = value >= target.closedEndKeyTerm.commitmentCap ? `${self.defaultMessage} ${getAbbr(target.closedEndKeyTerm.commitmentCap)}` : null;
                    }
                    else if (target.closedEndKeyTerm.commitmentTarget){
                        return self.message = value >= target.closedEndKeyTerm.commitmentTarget ? `${self.defaultMessage} ${getAbbr(target.closedEndKeyTerm.commitmentTarget)}` : null;
                    }
                    else {
                        return self.message = null;
                    }
                }
            },
        },
        softNotLessThan: {
            value: Number.NEGATIVE_INFINITY,
            type: ValidationStyleEnum.Soft,
            defaultMessage: DefaultValidationMessages.softNotLessThan,
            validateRule(self, value, target, prop) {
                if (!value) return self.message = null;
                if (target instanceof ClosedEndDetails && target?.closedEndKeyTerm instanceof ClosedEndKeyTerm){
                    if (target.closedEndKeyTerm.commitmentCap){
                        return self.message = value <= 0.001 * target.closedEndKeyTerm.commitmentCap ? `${self.defaultMessage} ${getAbbr(0.001 * target.closedEndKeyTerm.commitmentCap)}` : null;
                    }
                    else if (target.closedEndKeyTerm.commitmentTarget){
                        return self.message = value <= 0.001 * target.closedEndKeyTerm.commitmentTarget ? `${self.defaultMessage} ${getAbbr(0.001 * target.closedEndKeyTerm.commitmentTarget)}` : null;
                    }
                    else {
                        return self.message = null;
                    }
                }
            },
        },
        softNotMoreThan: {
            value: Number.POSITIVE_INFINITY,
            type: ValidationStyleEnum.Soft,
            defaultMessage: DefaultValidationMessages.softNotMoreThan,
            validateRule(self, value, target, prop) {
                if (!value) return self.message = null;
                if (target instanceof ClosedEndDetails && target?.closedEndKeyTerm instanceof ClosedEndKeyTerm){
                    if (target.closedEndKeyTerm.commitmentCap){
                        return self.message = value >= 0.004 * target.closedEndKeyTerm.commitmentCap ? `${self.defaultMessage} ${getAbbr(0.004 * target.closedEndKeyTerm.commitmentCap)}` : null;
                    }
                    else if (target.closedEndKeyTerm.commitmentTarget){
                        return self.message = value >= 0.004 * target.closedEndKeyTerm.commitmentTarget ? `${self.defaultMessage} ${getAbbr(0.004 * target.closedEndKeyTerm.commitmentTarget)}` : null;
                    }
                    else {
                        return self.message = null;
                    }
                }
            },
        } 
    })
    organizationalExpenseCapAmount?: number;
    
    //#endregion

    //#region <<< Section - Recycling >>>

    @Type(() => RecyclingTimeLimit)
    @Transform((model) => {
        if (model.value?.length > 0){
            return model.value;
        }
        else if (model.obj['closedEndKeyTerm'] && model.obj['closedEndKeyTerm']['hasTimeLimit'] === SimpleAnswerEnum.Yes){
                return [new RecyclingTimeLimit()];
        }
        return undefined;
        
    }, {toClassOnly: true})
    @meta({
        alias: 'Recycling Time Limit ##', 
        navigation: true,
        adds: 'RecyclingTimeLimit',
        removes: 'hard' 
    })
    recyclingTimeLimits?: Array<RecyclingTimeLimit>;

    @Type (() => RecyclingProceedsLimit)
    @Transform((model) => {
        if (model.value?.length > 0){
            return model.value;
        }
        else if (model.obj['closedEndKeyTerm'] && model.obj['closedEndKeyTerm']['hasProceedsLimit'] === SimpleAnswerEnum.Yes){
                return [new RecyclingProceedsLimit()];
        }
        return undefined;
        
    }, {toClassOnly: true})
    @meta({
        alias: 'Recycling Proceeds Limit ##', 
        navigation: true,
        adds: 'RecyclingProceedsLimit',
        removes: 'hard' 
    })
    recyclingProceedsLimits?: Array<RecyclingProceedsLimit>;

    //#endregion

    //#region <<< Section - LP Clawback >>>

    @meta({ 
        alias: 'LP Clawback % Limit(s)',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        auditRoute: 'HasLpClawbackPerLimit',
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value === SimpleAnswerEnum.Yes) {
                    if (!self.lpClawbackPerLimits?.length){
                        self.add('lpClawbackPerLimits');
                    }
                }
                else {
                    self.lpClawbackPerLimits = undefined;
                }
            }
        }   
    })
    lpClawbackPerLimit: SimpleAnswerEnum;

    @Type(() => LpClawbackPerLimit)
    @meta({
        alias: 'LP Clawback % Limit ##', 
        navigation: true,
        adds: 'LpClawbackPerLimit',
        removes: 'hard' 
    })
    lpClawbackPerLimits: Array<LpClawbackPerLimit>;

    @meta({ 
        alias: 'LP Clawback Time Limit(s)',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        auditRoute: 'HasLpClawbackTimeLimit',
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value === SimpleAnswerEnum.Yes) {
                    if (!self.lpClawbackTimeLimits?.length){
                        self.add('lpClawbackTimeLimits');
                    }
                }
                else {
                    self.lpClawbackTimeLimits = undefined;
                }
            }
        }   
    })
    lpClawbackTimeLimit: SimpleAnswerEnum;

    @Type(() => LpClawbackTimeLimit)
    @meta({ 
        alias: 'LP Clawback Time Limit ##',
        navigation: true, 
        adds: 'LpClawbackTimeLimit',
        removes: 'hard'
    })
    lpClawbackTimeLimits: Array<LpClawbackTimeLimit>;

    //#endregion

    //#region <<< Section - Removal/Termination Rights >>>

    @meta({ 
        alias: 'For-Fault GP/IM Removal',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.forFaultGpImVoteThresholdRate = undefined;
                    self.carriedInterestReduction = undefined;
                }
            }
        }   
    })
    forFaultGpImRemoval: SimpleAnswerEnum;

    @meta({ alias: 'For-Fault GP/IM Removal (Vote Threshold)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessOrEqualTo: 50, softNotMoreOrEqualTo: 75 })
    forFaultGpImVoteThresholdRate: number;

    @meta({ 
        alias: 'Carried Interest Reduction',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.carriedInterestReductionRate = undefined;
                }
            }
        }   
    })
    carriedInterestReduction: SimpleAnswerEnum;

    @meta({ alias: 'Carried Interest Reduction (Reduction Rate)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessThan: 10, softNotMoreThan: 50 })
    carriedInterestReductionRate: number;

    @meta({ 
        alias: 'For-Fault Investment Period Termination',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.forFaultInvPeriodVoteThresholdRate = undefined;
                }
            }
        }   
    })
    forFaultInvPeriodTermination: SimpleAnswerEnum;

    @meta({ alias: 'For-Fault Investment Period Termination (Vote Threshold)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessOrEqualTo: 50, softNotMoreOrEqualTo: 75 })
    forFaultInvPeriodVoteThresholdRate: number;

    @meta({ 
        alias: 'For-Fault Fund Termination',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.forFaultFundVoteThresholdRate = undefined;
                }
            }
        }   
    })
    forFaultFundTermination: SimpleAnswerEnum;

    @meta({ alias: 'For-Fault Fund Termination (Vote Threshold)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessOrEqualTo: 50, softNotMoreOrEqualTo: 75 })
    forFaultFundVoteThresholdRate: number;

    @meta({ 
        alias: 'No-Fault GP/IM Removal',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.noFaultGpImVoteThresholdRate = undefined;
                    self.noFaultGpImTimeLimit = undefined;
                    self.noFaultGpImPenalty = undefined;
                }
            }
        }   
    })
    noFaultGpImRemoval: SimpleAnswerEnum;

    @meta({ alias: 'No-Fault GP/IM Removal (Vote Threshold)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessOrEqualTo: 50, softNotMoreOrEqualTo: 80 })
    noFaultGpImVoteThresholdRate: number;

    @meta({ alias: 'No-Fault GP/IM Removal (Time Limit)', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    noFaultGpImTimeLimit: SimpleAnswerEnum;

    @meta({ alias: 'No-Fault GP/IM Removal (Penalty)', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    noFaultGpImPenalty: SimpleAnswerEnum;
    
    @meta({ 
        alias: 'No-Fault Investment Period Termination',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.noFaultInvPeriodVoteThresholdRate = undefined;
                    self.noFaultInvPeriodTimeLimit = undefined;
                    self.noFaultInvPeriodPenalty = undefined;
                }
            }
        }   
    })
    noFaultInvPeriodTermination: SimpleAnswerEnum;

    @meta({ alias: 'No-Fault Investment Period Termination (Vote Threshold)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessOrEqualTo: 50, softNotMoreOrEqualTo: 80 })
    noFaultInvPeriodVoteThresholdRate: number;

    @meta({ alias: 'No-Fault Investment Period Termination (Time Limit)', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    noFaultInvPeriodTimeLimit: SimpleAnswerEnum;

    @meta({ alias: 'No-Fault Investment Period Termination (Penalty)', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    noFaultInvPeriodPenalty: SimpleAnswerEnum;

    @meta({ 
        alias: 'No-Fault Fund Termination',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.noFaultFundVoteThresholdRate = undefined;
                    self.noFaultFundTimeLimit = undefined;
                    self.noFaultFundPenalty = undefined;
                }
            }
        }   
    })
    noFaultFundTermination: SimpleAnswerEnum;

   
    @meta({ alias: 'No-Fault Fund Termination (Vote Threshold)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessOrEqualTo: 50, softNotMoreOrEqualTo: 80 })
    noFaultFundVoteThresholdRate: number;

    @meta({ alias: 'No-Fault Fund Termination (Time Limit)', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    noFaultFundTimeLimit: SimpleAnswerEnum;

    @meta({ alias: 'No-Fault Fund Termination (Penalty)', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    noFaultFundPenalty: SimpleAnswerEnum;

    @meta({ 
        alias: 'Key Person Provision',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.automaticSuspensionLift = undefined;
                    self.keyPersonProVoteThresholdRate = undefined;
                }
            }
        }   
    })
    keyPersonProvision: SimpleAnswerEnum;

    @meta({ alias: 'Automatic Suspension Lift', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    automaticSuspensionLift: SimpleAnswerEnum;

    @meta({ alias: 'Key Person Provision (Vote Threshold)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessOrEqualTo: 50, softNotMoreOrEqualTo: 75 })
    keyPersonProVoteThresholdRate: number;

    //#endregion

    //#region <<< Section - Leverage >>>

    @meta({ alias: 'Bridge Financing Term', hardNotLessOrEqualTo: 0, softNotLessThan: 3, softNotMoreThan: 24 })
    bridgeFinancingTerm: number;

    @meta({ alias: 'Subscription Lines', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    subscriptionLines?: SimpleAnswerEnum;

    //#endregion
    
    //#region <<< Section - Reporting >>>

    @meta({ 
        alias: 'Accounting Method', 
        source: AccountingMethodEnum.toKeyValue() 
    })
    accountingMethod?: AccountingMethodEnum;

    @meta({ alias: 'Accounting Method Description' })
    accountingMethodDescription?: string;
    
    @meta({ 
        alias: 'Deadline for Quarterly Reports', 
        softNotLessThan: 30, 
        softNotMoreThan: 90 
    })
    quarterlyReportsDeadline?: number;

    @meta({
        alias: 'Deadline for Annual Reports',
        softNotLessThan: 60,
        softNotMoreThan: 180
    })
    annualReportsDeadline?: number;

    //#endregion

    //#region <<< Section - Successor Fund Provision >>>

    @meta({ 
        alias: 'Successor Fund Provision',
        source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified),
        softNotEmpty: true,
        updates: (self, value) => {
            if (self instanceof ClosedEndDetails){
                if (value !== SimpleAnswerEnum.Yes) {
                    self.successorFundProvisionThresholdRate = undefined;
                }
            }
        }   
    })
    successorFundProvision: SimpleAnswerEnum;

    @meta({ alias: 'Successor Fund Provision (% Threshold)', hardNotLessOrEqualTo: 0, hardNotMoreThan: 100, softNotEmpty: true, softNotLessThan: 50, softNotMoreThan: 80 })
    successorFundProvisionThresholdRate: number;

    //#endregion

    //#region <<< Section - Fund Terms >>>     

    @Transform( ({value}) =>(value?.length > 0) ? value : [new ClosedEndInterimDate()], { toClassOnly: true })
    @Type(() => ClosedEndInterimDate)
    @meta({
        alias: 'Interim Date ##',
        navigation: true,
        adds: 'ClosedEndInterimDate',
        removes: 'hard',
        auditRoute: 'interimDate'
    })
    interimDates: Array<ClosedEndInterimDate>;

    @meta({ alias: 'Liquidity Tier', source: LiquidityTierEnum.toKeyValue() })
    liquidityTier?: LiquidityTierEnum;

    //#endregion

    //#region <<< Section - Return Profile >>>

    @meta({ alias: 'TVPI Notes' })
    tvpiNotes?: string;

    @meta({ alias: 'Median Quartile Ranking', source: RankEnum.toKeyValue() })
    medianQuartileRanking?: RankEnum;

    //#endregion

    @Type(() => ClosedEndKeyTerm)
    @meta({ navigation: true })
    closedEndKeyTerm: ClosedEndKeyTerm = null;

    @Type(() => ClosedEndStructure)
    @meta({ navigation: true })
    closedEndStructure: ClosedEndStructure = null;

    @Type(() => ClosedEndGPInformation)
    @meta({ navigation: true })
    closedEndGPInformation: ClosedEndGPInformation = null;

    @Type(() => ClosedEndGPTargetInvestmentStat)
    @meta({ navigation: true })
    closedEndGPTargetInvestmentStat: ClosedEndGPTargetInvestmentStat = null;

    @Type(() => ClosedEndTargetReturnProfile)
    @meta({ navigation: true })
    closedEndTargetReturnProfile: ClosedEndTargetReturnProfile = null;

    modifiedBy: string = null;
    
    auditURL = 'basicdata/fund/audit/{0}/closedend/{1}/{2}';
    auditURLParams = ['parent.fundId@model','id@model','@prop'];
    
    constructor() {
        super();
        this.state = ModelState.Ready;
        this.excludeFromNotePath = true;
    }
}