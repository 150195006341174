import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AgmCoreModule } from '@agm/core';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  DateTitlePipe,
  PropsJoinPipe,
  MapFromKeyValuePipe,
  NumberFormatPipe,
  FromJSONPipe,
  PickFromCollectionPipe,
  OrderByPipe,
  UniquePipe,
} from './pipes';
import {
  BaseComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  LocationComponent,
  NumberComponent,
  SearchComponent,
  SpreadsheetComponent,
  TextComponent,
  TimelineComponent,
  LoadingComponent,
  FileUploadComponent,
} from './components';
import * as v2 from './components/@v2';
import { BaseDirective } from './components/base/base.directive';
import { PopoverService } from './services/popover.service';
import { ValidationService } from './services/validation.service';
import { DetectBrowserService } from './services/detect-browser.service';
import { BroadcastService } from './services/broadcast.service';
import { PopoverComponent } from './components/popover/popover.component';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { GetterByTriggerPipe } from './pipes/getter-by-trigger.pipe';
import { ArrayFilterByTermPipe } from './pipes/array-filter-by-term.pipe';
import { IncludesOrEqualsPipe } from './pipes/includes-or-equals.pipe';
import { InjectDirective } from './directives/inject.directive';
import { SearchInputComponent } from './components/@v2/search-input/search-input.component';
import { ComputedPropPipe } from './pipes/computed-prop.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { StickyDirective } from './directives/sticky.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA5RUC7PCdpbA0_RIwLDyYB5_Df1F3PHqY',
      libraries: ['places'],
      language: 'en',
    }),
    OverlayModule,
  ],
  declarations: [
    DateTitlePipe,
    PropsJoinPipe,
    MapFromKeyValuePipe,
    NumberFormatPipe,
    FromJSONPipe,
    BaseComponent,
    CheckComponent,
    DateComponent,
    DropdownComponent,
    LocationComponent,
    NumberComponent,
    SearchComponent,
    SearchInputComponent,
    SpreadsheetComponent,
    TextComponent,
    TimelineComponent,
    BaseDirective,
    LoadingComponent,
    PopoverComponent,
    PickFromCollectionPipe,
    OrderByPipe,
    ArrayFilterPipe,
    GetterByTriggerPipe,
    v2.LabelComponent,
    v2.ValidationComponent,
    v2.TextComponent,
    v2.NumberComponent,
    v2.CheckComponent,
    v2.DropdownComponent,
    v2.MultiSelectComponent,
    v2.DateComponent,
    v2.BaseComponent,
    ArrayFilterByTermPipe,
    IncludesOrEqualsPipe,
    UniquePipe,
    InjectDirective,
    SearchInputComponent,
    ComputedPropPipe,
    ClickOutsideDirective,
    FileUploadComponent,
    StickyDirective,
  ],
  providers: [
    PopoverService,
    ValidationService,
    DetectBrowserService,
    BroadcastService,
    DateTitlePipe,
    PropsJoinPipe,
    MapFromKeyValuePipe,
    NumberFormatPipe,
    FromJSONPipe,
    PickFromCollectionPipe,
    OrderByPipe,
    GetterByTriggerPipe,
    IncludesOrEqualsPipe,
    UniquePipe,
    v2.LabelComponent,
    v2.ValidationComponent,
    StickyDirective,
  ],
  exports: [
    DateTitlePipe,
    PropsJoinPipe,
    MapFromKeyValuePipe,
    NumberFormatPipe,
    FromJSONPipe,
    PickFromCollectionPipe,
    OrderByPipe,
    GetterByTriggerPipe,
    IncludesOrEqualsPipe,
    BaseComponent,
    CheckComponent,
    DateComponent,
    DropdownComponent,
    LocationComponent,
    NumberComponent,
    SearchComponent,
    SearchInputComponent,
    SpreadsheetComponent,
    TextComponent,
    TimelineComponent,
    LoadingComponent,
    PopoverComponent,
    v2.LabelComponent,
    v2.ValidationComponent,
    v2.TextComponent,
    v2.NumberComponent,
    v2.CheckComponent,
    v2.DropdownComponent,
    v2.MultiSelectComponent,
    v2.DateComponent,
    v2.BaseComponent,
    ArrayFilterByTermPipe,
    UniquePipe,
    ArrayFilterPipe,
    ComputedPropPipe,
    FileUploadComponent,
    StickyDirective,
  ],
})
export class SharedUiModule {}
