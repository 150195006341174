import { meta, Meta, toLocalDate } from '@aksia-monorepo/shared-ui';
import { Transform } from 'class-transformer';
import {
  AcquisitionTypeEnum,
  AksiaCoverageGPEnum,
  AksiaCoverageGPNewEnum,
  AksiaStatusEnum,
  CoInvFeeStructureEnum,
  DeptSourcingEnum,
  FixedFloatingDebtEnum,
  InstrumentEnum,
  SimpleAnswerEnum,
  SingleAssetPortfolioEnum,
} from '../../enums/enums';
import { Address } from '../entities/entities.model';

export class CoInvestmentDetails extends Meta {
  id?: number = 0;
  fundId?: number;

  @meta({ alias: 'Project Name' })
  projectName?: string;

  //@Transform( (dto) => dto.value || new Address(), { toClassOnly: true })
  @meta({ alias: 'Company Headquarters' })
  companyHeadquarters?: string;

  //#region Staff Involved

  @meta({ alias: 'Sourcing Analyst' })
  sourcingAnalystName?: string;

  @meta({ alias: 'Intermediary/Broker' })
  intermediaryBroker?: string;

  @meta({
    alias: 'Aksia Coverage of GP',
    auditRoute: 'GPAksiaCoverage',
    source: AksiaCoverageGPNewEnum.toKeyValue(),
  })
  gpAksiaCoverage?: AksiaCoverageGPEnum;

  //#endregion

  //#region Decision Dates

  @meta({ alias: 'Aksia Status', source: AksiaStatusEnum.toKeyValue() })
  status?: AksiaStatusEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Initial Log Date' })
  initialLogDate?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Further Review' })
  dateApprovedFurtherReview?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Due Diligence' })
  dateApprovedDD?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Approved' })
  dateApproved?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Not Approved' })
  dateNotApproved?: Date;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Halted' })
  dateHalted?: Date;

  //#endregion

  //#region Key Terms

  @meta({ alias: 'Instrument', source: InstrumentEnum.toKeyValue() })
  instrument?: InstrumentEnum;

  @meta({
    alias: 'Stapled Deal',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  stapledDeal?: SimpleAnswerEnum;

  @meta({ alias: 'Overview' })
  overview?: string;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Hard Commitment Date' })
  hardCommitmentDate?: Date;

  @meta({ alias: 'Other Dates/Color' })
  otherDatesColor?: string;

  @meta({ alias: 'Transaction Summary' })
  transactionSummary?: string;

  @meta({ alias: 'Merits' })
  merits?: string;

  @meta({ alias: 'Issues' })
  issues?: string;

  @meta({ alias: 'Operating Expense Cap' })
  operatingExpenseCap?: number;

  @meta({ alias: 'Fee Structure', source: CoInvFeeStructureEnum.toKeyValue() })
  feeStructure?: CoInvFeeStructureEnum;

  @meta({ alias: 'Interest Margin' })
  interestMargin?: number;

  @meta({ alias: 'Original Issue Discount' })
  originalIssueDiscount?: number;

  //#endregion

  //#region Syndication & Capacity Notes

  @meta({ alias: 'Total Investment Amount' })
  totalInvestmentAmount?: number;

  @meta({ alias: 'Total Co-Invest Syndicated' })
  totalCoInvestSyndicated?: number;

  @meta({ alias: 'Indicative Capacity to Aksia Clients' })
  indicativeCapacityAksiaClients?: number;

  @meta({ alias: 'Syndication & Capacity Notes' })
  syndicationCapacityNotes?: string;

  //#endregion

  //#region Financial Statistics

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Reporting Date' })
  financialsReportDate?: Date;

  @meta({ alias: 'Valuation Metric' })
  valuationMetric?: string;

  @meta({ alias: 'Enterprise Value / Asset Value' })
  enterpriseValue?: number;

  @meta({ alias: 'Equity Value' })
  equityValue?: number;

  @meta({ alias: 'Net Debt' })
  netDebt?: number;

  @meta({ alias: 'Revenue' })
  revenue?: number;

  @meta({ alias: 'EBITDA/NOI', auditRoute: 'EBITDANOI' })
  ebitdanoi?: number;

  @meta({ alias: 'EV/Sales', auditRoute: 'EVSales' })
  evSales?: number;

  @meta({ alias: 'EV/EBITDA', auditRoute: 'EVEBITDA' })
  evebitda?: number;

  @meta({ alias: 'Revenue CAGR' })
  revenueCAGR?: number;

  @meta({ alias: 'Revenue CAGR Time Period' })
  revenueCAGRTimePeriod?: string;

  @meta({ alias: 'EBITDA CAGR', auditRoute: 'EBITDACAGR' })
  ebitdacagr?: number;

  @meta({
    alias: 'EBITDA CAGR Time Period',
    auditRoute: 'EBITDACAGRTimePeriod',
  })
  ebitdacagrTimePeriod?: string;

  @meta({ alias: 'Loan to Value' })
  loanValue?: number;

  @meta({ alias: 'Attachment Point' })
  attachmentPoint?: number;

  @meta({ alias: 'Detachment Point' })
  detachmentPoint?: number;

  //#endregion

  //#region Real Estate Details

  @meta({
    alias: 'Single Asset/Portfolio',
    source: SingleAssetPortfolioEnum.toKeyValue(),
  })
  singleAssetPortfolio?: SingleAssetPortfolioEnum;

  @meta({ alias: 'Acquisition Type', source: AcquisitionTypeEnum.toKeyValue() })
  acquisitionType?: AcquisitionTypeEnum;

  @meta({
    alias: 'Purchase Price',
  })
  purchasePrice?: number;

  @meta({
    alias: 'Entry Cap Rate',
  })
  entryCapRate?: number;

  @meta({
    alias: 'Exit Cap Rate',
  })
  exitCapRate?: number;

  @meta({
    alias: 'Fixed or Floating Debt',
    source: FixedFloatingDebtEnum.toKeyValue(),
    updates: (self: Meta, value: FixedFloatingDebtEnum) => {
      if (self instanceof CoInvestmentDetails) {
        if (!value) {
          self.floatingRate = null;
          self.fixedRate = null;
        } else if (value === FixedFloatingDebtEnum.Fixed) {
          self.floatingRate = null;
        } else if (value === FixedFloatingDebtEnum.Floating) {
          self.fixedRate = null;
        }
      }
    },
  })
  fixedFloatingDebt?: FixedFloatingDebtEnum;

  @meta({
    alias: 'Floating Rate',
  })
  floatingRate?: number;

  @meta({
    alias: 'Fixed Rate',
  })
  fixedRate?: number;

  @meta({
    alias: 'Debt Sourcing',
    source: DeptSourcingEnum.toKeyValue(),
  })
  debtSourcing?: DeptSourcingEnum;

  @meta({
    alias: 'Stabilized',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  stabilized?: SimpleAnswerEnum;

  @meta({
    alias: 'Stabilized YOC',
  })
  stabilizedYOC?: number;

  @meta({
    alias: 'Development/Re-Development',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  developReDevelop?: SimpleAnswerEnum;

  @meta({
    alias: 'Size (sq. ft)',
  })
  sizeSF?: number;

  @meta({
    alias: 'Size (units)',
  })
  sizeUn?: number;

  //#endregion

  auditURL = 'basicdata/fund/audit/{0}/coInvestmentDetails/{1}/{2}';
  auditURLParams = ['parent.fundId@model', 'id@model', '@prop'];

  constructor(fundId: number) {
    super();
    this.fundId = fundId;
  }
}
