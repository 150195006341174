import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { DateView, Anchor } from '../enums/enums';
import { PopoverCloseEvent, PopoverContent } from '../types/types';

const defaultColumnTitles: Array<string> = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
];
const defaultRowTitles: Array<string> = ['1', '2', '3', '4', '5'];

export class DateSettings {
  initView: DateView;
  applyView: DateView;
  anchor: Anchor;
  format: string;
  yearPage: number;
  minDate: Date;
  maxDate: Date;
  autoOpen: boolean;
}

export class DropdownSettings {
  canAdd: boolean = false;
  canRename: boolean = false;
  canFilter: boolean = false;
  noIcon: boolean = false;
  minWidth: number = 100;
  maxWidth: number = 300;
  maxHeight: number;
  offsetWidth: number = 2;
  key: string = 'key';
  value: string = 'value';
}

export class Location {
  address1: string = null;
  address2: string = null;
  city: string = null;
  countryState: string = null;
  zip: string = null;
  country: string = null;
  latitude: number = null;
  longitude: number = null;
}

export class SpreadSheetSettings {
  horizontalAlign: 'right' | 'left' | 'center' = 'right';
  verticalAlign: 'top' | 'bottom' | 'middle' = 'middle';
  rows: number;
  columns: number;
  allowedKeys: 'all' | 'alpha' | 'numeric' = 'numeric';
  numberFormat: boolean = true;
  permitZero: boolean = false;
  permitNegative: boolean = false;
  markingAllowed: boolean = true;
  numberColorFormat: boolean = false;
  showFormatToggle: boolean = true;
  showLastColumn: boolean = false;
  lastColumnTitle: string = '';
  lastColumnData: Array<unknown> = [];

  _columnTitles: Array<string>;

  get columnTitles() {
    return this._columnTitles;
  }

  set columnTitles(value: Array<string>) {
    this._columnTitles = value;
    this.columns = this._columnTitles.length;
  }

  _rowTitles: Array<string>;

  get rowTitles() {
    return this._rowTitles;
  }

  set rowTitles(value: Array<string>) {
    this._rowTitles = value;
    this.rows = this._rowTitles.length;
  }

  cellStatus: Array<string> = [];
  cellInfo: Array<any> = [];
  markedCells: CellRange;
  selectedCell: Cell;

  constructor(
    columnTitles = defaultColumnTitles,
    rowTitles = defaultRowTitles,
    cellStatus = []
  ) {
    this.columnTitles = columnTitles;
    this.rowTitles = rowTitles;
    this.cellStatus = cellStatus;
  }
}

export class Cell {
  row: number;
  col: number;
  info: { metaId: number; bgColorClass: string; data: Date };

  constructor(
    row: number = null,
    col: number = null,
    info?: { metaId: number; bgColorClass: string; data: Date }
  ) {
    this.row = row;
    this.col = col;
    this.info = info;
  }
}

export class CellRange {
  startRow: number;
  endRow: number;
  startCol: number;
  endCol: number;

  constructor(
    startRow: number = -1,
    endRow: number = -1,
    startCol: number = -1,
    endCol: number = -1
  ) {
    this.startRow = startRow;
    this.endRow = endRow;
    this.startCol = startCol;
    this.endCol = endCol;
  }
}

export class PopoverRef<T = any> {
  private afterClosed = new Subject<PopoverCloseEvent<T>>();
  afterClosed$ = this.afterClosed.asObservable();

  constructor(
    public overlay: OverlayRef,
    public content: PopoverContent,
    public data: T
  ) {
    overlay.backdropClick().subscribe(() => {
      this._close('backdropClick', null);
    });
  }

  close(data?: T) {
    this._close('close', data);
  }

  private _close(type: PopoverCloseEvent['type'], data?: T) {
    this.overlay.dispose();
    this.afterClosed.next({
      type,
      data,
    });
    this.afterClosed.complete();
  }
}
