<div class="master-page" [class.Loading]="isLoading">
  <div class="master-loader" *ngIf="isLoading">
    <div class="blob">
      <p><span></span></p>
      <p><span></span></p>
    </div>
    <div class="o2">
      <span class="proton">O</span>
      <span class="electron">2</span>
    </div>
  </div>
  <div class="master-theme stretch c1s3 r1s1"></div>
  <!-- <div class="master-toolbar c2s1 r2s1 cols20" *ngIf="showMasterToolbar"> -->
    <row class="master-toolbar c1s3 r2s1" [cols]="27" [UiSticky] [stickyClass]="'stuck'">
      <o2-search class="c16s7" *ngIf="auth.isAuthenticated && showMasterToolbar" data-cy="search-txt"  
        [ngClass]="'r2s1 framed auto'" [class.Loading]="searchLoading" 
        [placeholder]="searchPlaceHolder"
        [results]="searchResults"
        [labelProperty]="'name'"
        [isLoading]="searchLoading"
        [position]="'right'"
        (selected)="onSelectedValue($event)"
        (searchText)="onSearchText($event)">
      </o2-search>
      <badge class="primary c27s1 r2s1" [title]="perm.o2User?.abbr" *ngIf="auth.isAuthenticated">
        <span class="badge-command" (click)="signOut()">Sign out</span>
      </badge>
    </row>
  <!-- </div> -->
  <div *ngIf="auth.isAuthenticated && !isBrowserSupported" class="sticky-message">
    <div class="header warning">
      <h1>Browser is not supported!</h1>
    </div>
    <div class="content warning">
      <div class="message">
          <p>{{browserNameAndVersion.name}} is not supported currently. Please use Chrome to avoid issues.</p>
      </div>
    </div>
  </div>
  <div class="master-content c2s1 r3s1 top">
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </div>
  <page-navigation *ngIf="showNavigation"></page-navigation>
  <div class="logo" *ngIf="showLogo">
    <div class="electron2">
      <span class="version">v{{version}}</span>
    </div>
    <div class="proton">
      <div class="logo-o">o</div>
      <div class="logo-2">2</div>
      <div class="shadow"></div>
    </div>
  </div>
</div>
