import { Pipe, PipeTransform } from '@angular/core';
import { currencyExp, getAbbr } from '../functions/helpers';

/* ToDo (FF fix, find regexp like above without lookbehind): const currencyExp = /(\d)(?=(\d{3})+(?!\d))/g;*/

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(
    value: string,
    inline: boolean = false,
    prefix: string = '',
    suffix: string = '',
    abbr: boolean = false,
    abbrSmall: boolean = false,
    decimalAmount: number = 2,
    thousandSep: string = ',',
    usePrecision: boolean = false
  ): string {
    if (
      value === null ||
      value === undefined ||
      value === '' ||
      value === '-'
    ) {
      return value;
    }

    value = value.toString();

    if (!isNaN(Number(value))) {
      let sign = '';
      let formatted = '';

      if (value.startsWith('-')) {
        sign = '-';
      }

      if (inline) {
        formatted = value.toString().replace(currencyExp, thousandSep); // Format: #,###,###.####
        /*ToDo (FF fix): formatted = value.toString().replace(currencyExp, "$1,"); // Format: #,###,###.####*/
      } else if (abbr) {
        formatted = getAbbr(
          parseFloat(value),
          abbrSmall,
          decimalAmount,
          usePrecision
        );
      } else {
        const decimals = value.indexOf('.') == -1 ? 0 : decimalAmount;
        if (decimals) {
          formatted = Number(value)
            .toFixed(decimals)
            .toString()
            .replace(/\.?0+$/, '')
            .replace(currencyExp, thousandSep); // Format: #,###,###.####
        } else {
          formatted = Number(value)
            .toFixed(decimals)
            .toString()
            .replace(currencyExp, thousandSep); // Format: #,###,###.####
        }

        //ToDo (FF fix): formatted = Number(value).toFixed(decimals).toString().replace(currencyExp, "$1,"); // Format: #,###,###.####
      }
      return inline ? `${formatted}` : `${prefix}${formatted}${suffix}`;
    } else return '';
  }
}
