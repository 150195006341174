import { toLocalDate } from "@aksia-monorepo/shared-ui";
import { Transform, Type } from "class-transformer";
import { AUM, AUMeta } from "..";
import { LiquidityStructureEnum } from "../../enums/enums";

export class ProgramFund {
    fundId: number;
    name: string;
    managementCompanyId: number;
    managementCompanyName: string;
    investmentProgramId: number;
    investmentProgramName: string;
    type: string;
    liquidityStructure: LiquidityStructureEnum

    @Type(() => AUM)
    aum: Array<AUM>;

    aumMeta: AUMeta;

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    dateOfFormation?: Date;
    
    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    commencementOfOperations?: Date;

    get inceptionDate() {
        return this.commencementOfOperations ?? this.dateOfFormation;
    }
}