import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'row',
  inputs: ['UiSticky'],
  templateUrl: './page-section-row.component.html',
  styleUrls: ['./page-section-row.component.scss']
})
export class PageSectionRowComponent implements OnInit {
  @Input() isRendered: boolean = true;
  @Input() cols: number = 10;
  @Input() rows: number = 1;
  @Input() zIndex: number;
  @Input() autoexpand: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
