/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, ComponentFactoryResolver, ElementRef, forwardRef, Inject, Injector, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseLoggingService } from '../../../services/base-logging.service';
import { BaseComponent, UIControlSettings } from '../base/base.component';

export type NumberSettings = {
  prefix?: string;
  suffix?: string;
  onlyWholeNumbers?: boolean;
  permitZero?: boolean;
  thousandSep?: string;
}

const defaultNumberSettings: NumberSettings = {
  prefix: undefined,
  suffix: undefined,
  onlyWholeNumbers: false,
  thousandSep: ','
}

const allowedKeys = ['Tab', 'Home', 'End', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

@Component({
  selector: 'number-v2',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent extends BaseComponent {

  constructor(injector: Injector, cmpFactoryResolver: ComponentFactoryResolver, elRef: ElementRef, @Inject(BaseLoggingService) logService: BaseLoggingService) {
    super(injector,cmpFactoryResolver,elRef,logService);
    this._settings = {...this._settings, ...defaultNumberSettings};
  }

  //#region ElementRefs

  @ViewChild('input') input: ElementRef;

  //#endregion

  //#region Input

  @Input() 
  set settings(value: UIControlSettings & NumberSettings){
    for (const property in value) {
      this._settings[property] = value[property] ?? this._settings[property];
    }
    if (this._settings.e2eId){
      this.attach_e2e_attributes(this._settings.e2eId);
    }
  }

  get settings() {
    return this._settings;
  }

  //#endregion

  //#region Component Specific

  filterKeys(event: KeyboardEvent, value: string){
    if (event.key === 'm'){
      this.input.nativeElement.value = (this.getRawValue(value) * 1000000).toString();
    }
    else if (event.key === 'b'){
      this.input.nativeElement.value = (this.getRawValue(value) * 1000000000).toString();
    }

    if (allowedKeys.includes(event.key)|| 
       (event.key === '.' && !value.includes('.') && !this.settings.onlyWholeNumbers) ||
       (event.key === '-' && !value)) {
        return true;
    }
    else {
      event.preventDefault();
    }
  }

  updateValue(value: string){
    this.value$.next(this.getRawValue(value) || '');
  }

  private getRawValue(value: string) {
    const sign = (value.startsWith("-")) ? "-" : "";
    const raw = value.replace(/[^.\d]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
    return Number(`${sign}${raw}`); 
  }

  //#endregion

  //#region Overrides

  focusin(force?: boolean) {
    if (force){
      this.input.nativeElement.focus();
    }
    super.focusin();
  }

  focusout(value) {
    if (this.settings.referralMessage && (value === this.settings.referralValue || +value === this.settings.referralValue)){
      super.focusout(this.settings.referralValue);
    }
    else {
      if (this.settings.permitZero){
        if (!value){
          super.focusout('');
        }
        else super.focusout(this.getRawValue(value));
      }
      else if (+value === 0){
        this.input.nativeElement.value = '';
        super.focusout(this.getRawValue(value) || '');
      }
      else {
        super.focusout(this.getRawValue(value) || '');
      }
    }
  }

  //#endregion

}
