import { Meta } from '@aksia-monorepo/shared-ui';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(data: Array<Meta>, callback: (item: Meta, collection?: Array<Meta>) => boolean, trigger?: number, collection?: Array<Meta>): Array<Meta> {
    if (!data || !callback){
      return data;
    }
    return data?.filter( item => callback(item, collection));
  }

}
