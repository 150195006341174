/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { ValidationResult, ValidationStructure, ValidationStyleEnum } from '@aksia-monorepo/shared-ui';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'validation-v2',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidationComponent implements OnChanges {

  validationStyles = ValidationStyleEnum;

  @Input() validations: Array<ValidationResult>;
  mainMessage: string;
  restQuantity: number;
  restOfMessages: string;
  validationStyle: ValidationStyleEnum;
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.validations.currentValue){
      this.updateValidation();
    }
  }

  updateValidation() {
    if (this.validations?.length > 0){
      this.mainMessage = this.validations[0].message;
      this.validationStyle = this.validations[0]?.type ?? ValidationStyleEnum.Idle;
      this.restQuantity = this.validations.length - 1;
      this.restOfMessages = this.restQuantity > 0 ? "more...\n\r" + this.validations?.map( v => v.message).slice(1)?.join("\n") : null;
    }
    else {
      this.mainMessage = undefined;
      this.validationStyle = ValidationStyleEnum.Idle;
      this.restQuantity = undefined;
      this.restOfMessages = undefined;
    }
  }
  
}
