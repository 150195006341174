import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isLater'
})
export class IsLaterPipe implements PipeTransform {

  transform(value: Date, than?: Date): boolean {
    if (!value){
      return false;
    }
    const today = new Date();
    return than ? value.getFullYear() * 100 + value.getMonth() >= than.getFullYear() * 100 + than.getMonth()
                : value.getFullYear() * 100 + value.getMonth() >= today.getFullYear() * 100 + today.getMonth();
    
  }

}
