import { meta, Meta, ModelState } from "@aksia-monorepo/shared-ui";
import { ClosedEndCountryEnum, ClosedEndIndustryEnum, ClosedEndStageEnum, ClosedEndTagsEnum } from "../../enums/enums";
import { Fund } from "./fund.model";

export class ClosedEndCategorizationFactor extends Meta {
    closedEndCategorizationFactorId: number = 0;
    countryGeneralist?: ClosedEndCountryEnum;
    country?: string;

    @meta({ alias: 'Industry-Generalist', source: ClosedEndIndustryEnum.toKeyValue(), 
        updates: (self, value) => {
            if (self instanceof ClosedEndCategorizationFactor) {
                if (value !== ClosedEndIndustryEnum.IndustrySpecialist){
                    self.industry = undefined;            
                }
            }
        }
    })
    industryGeneralist?: ClosedEndIndustryEnum;

    @meta({ alias: 'Other Industries' })
    industry?: string;
    
    stage?: ClosedEndStageEnum;
    tags?: ClosedEndTagsEnum;
    isDeleted?: boolean;
    modifiedBy?: string;
    modifiedOn?: Date;

    constructor() {
        super();
        this.state = ModelState.Ready;
        this.excludeFromNotePath = true;
    }
}

