import {
  Meta,
  IMetaState,
  meta,
  ValidationStyleEnum,
  DefaultValidationMessages,
  ModelState,
  getAbbr,
  toLocalDate,
} from '@aksia-monorepo/shared-ui';
import { Type, Expose, Transform } from 'class-transformer';
import {
  SimpleAnswerEnum,
  CurrencyEnum,
  LockUpEnum,
  CalendarUnitEnum,
  ActiveTimePeriodEnum,
  MgmtFeeRateTypeEnum,
  MgmtFeeFrequencyEnum,
  IncFeeRateTypeEnum,
  HighWaterMarkEnum,
  RedemptionDayEnum,
  DataSourceEnum,
  PassThroughManagementFeeTypeEnum,
  SimpleAnswerPassEnum,
} from '../../enums/enums';
import { IAuditLog } from '../../interfaces/system.interface';
import {
  SidePocket,
  Lockup,
  Gate,
  MgmtFeeRate,
  Discount,
  IncFeeRate,
} from '../entities/entities.model';

export class OpenShareClass extends Meta implements IMetaState {
  classType = 'OpenShareClass';
  auditRoute = 'openend';
  classId: number;
  fundId: number;
  className: string;
  isDefault = false;
  isDeleted = false;
  __group = 'className';

  @meta({ alias: 'Source' })
  source?: DataSourceEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'As of' })
  asOfDate?: Date;

  @meta({ alias: 'No longer Offered' })
  noLongerOffered?: boolean;

  //#region <<< Section - Side Pockets >>>

  @meta({
    alias: 'Side Pockets',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          self.add('sidePockets', self.hasModified('hasSidePocket'));
        } else {
          self.sidePocketOpt = undefined;
          self.removeAll('sidePockets');
        }
      }
    },
  })
  hasSidePocket?: SimpleAnswerEnum;

  @Type(() => SidePocket)
  @meta({ navigation: true, adds: 'SidePocket', removes: 'soft' })
  sidePockets: Array<SidePocket>;

  @meta({
    alias: 'Opt-in/Opt-out right',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  sidePocketOpt?: SimpleAnswerEnum;

  //#endregion

  //#region <<< Section - Subscriptions >>>

  @meta({
    alias: 'Minimum Initial Investment',
    hardNotLessOrEqualTo: 0,
    hardNotLessThan: {
      value: Number.NEGATIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        prop = 'minimumAdditionalInvestment';
        let min = Math.max(
          self.value,
          target[prop] > 0 ? target[prop] : Number.NEGATIVE_INFINITY
        );
        return !value || value >= min
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${getAbbr(min, true)}`);
      },
    },
    softNotLessThan: 100_000,
    softNotMoreThan: 10_000_000,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        self.validate('minimumAdditionalInvestment');
      }
    },
  })
  minimumInitialInvestment?: number;

  @meta({
    alias: 'Minimum Additional Investment',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        prop = 'minimumInitialInvestment';
        let max = Math.min(
          self.value,
          target[prop] > 0 ? target[prop] : Number.POSITIVE_INFINITY
        );
        return !value || value <= max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${getAbbr(max, true)}`);
      },
    },
    softNotMoreThan: 5_000_000,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        self.validate('minimumInitialInvestment');
      }
    },
  })
  minimumAdditionalInvestment?: number;

  @meta({
    alias: 'Minimum Investment Currency',
    source: CurrencyEnum.toKeyValue(),
    softNotEmpty: true,
  })
  minimumInvestmentCurrency: CurrencyEnum = CurrencyEnum.USD;

  @meta({
    alias: 'Subscription Fee',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.subscriptionFee = undefined;
        }
      }
    },
  })
  hasSubscriptionFee?: SimpleAnswerEnum;

  @meta({
    alias: 'Subscription Fee (Fee)',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 0.5,
    softNotMoreThan: 3,
  })
  subscriptionFee?: number;

  //#endregion

  //#region <<< Section - Redemptions >>>

  @meta({
    alias: 'Lock-up',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          self.add('lockups', self.hasModified('hasLockup'));
        } else {
          self.lockupEndAllow = undefined;
          self.removeAll('lockups');
        }
      }
    },
  })
  hasLockup?: SimpleAnswerEnum;

  @meta({ alias: 'Allow redemptions on lockup end' })
  lockupEndAllow?: boolean;

  @Type(() => Lockup)
  @meta({
    navigation: true,
    adds: (self, prop) => {
      const lockup = new Lockup();

      if (self.hasModified('lockups')) {
        lockup.lockupDurationMinRange =
          self[prop]?.length > 0
            ? self[prop][self[prop].length - 1].lockupDurationMaxRange + 1
            : 1;
        lockup.lockupDurationMaxRange = lockup.lockupDurationMinRange + 1;
        lockup.lockupType = LockUpEnum.Soft;
      }

      self[prop] = [...(self[prop] ?? []), lockup];

      if (self.hasModified('lockups')) {
        self[prop].forEach((item) => {
          item.validateAll();
          item.updateState(ModelState.IsDirty);
        });
      }
    },
    removes: 'soft',
  })
  lockups: Array<Lockup>;

  @meta({
    alias: 'Redemption Frequency',
    hardNotLessOrEqualTo: 0,
    softNotEmpty: true,
    softNotLessThan: 1,
    softNotMoreThan: {
      value: 3,
      type: ValidationStyleEnum.Soft,
      defaultMessage: 'Is usually less than',
      validateRule: (self, value, target, prop) => {
        prop = 'redemptionTermsFrequency';
        let max = target[prop] === CalendarUnitEnum.Days ? 5 : self.value;
        return !value || value <= max
          ? (self.message = null)
          : (self.message = `${self.defaultMessage} ${max}`);
      },
    },
    softEqual: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: 'Did you mean every {0} {1}?',
      validateRule: (self, value, target, prop) => {
        if (target instanceof OpenShareClass) {
          switch (target.redemptionTermsFrequency) {
            case CalendarUnitEnum.Semesters:
              return !value || value !== 2
                ? (self.message = null)
                : (self.message = self.defaultMessage
                    .replace('{0}', 1)
                    .replace('{1}', 'year'));
            case CalendarUnitEnum.Quarters:
              return !value || value !== 2
                ? (self.message = null)
                : (self.message = self.defaultMessage
                    .replace('{0}', 1)
                    .replace('{1}', 'semester'));
            case CalendarUnitEnum.Months:
              return !value || value !== 3
                ? (self.message = null)
                : (self.message = self.defaultMessage
                    .replace('{0}', 1)
                    .replace('{1}', 'quarter'));
            case CalendarUnitEnum.Days:
              return !value || value !== 15
                ? (self.message = null)
                : (self.message = self.defaultMessage
                    .replace('{0}', 2)
                    .replace('{1}', 'weeks'));
            default:
              return (self.message = null);
          }
        }
      },
    },
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        self.validate('redemptionTermsFrequency');
        self.validate('redemptionTermsFrequencyDay');
      }
    },
  })
  redemptionTermsFrequencyAmount?: number;

  @meta({
    alias: 'Redemption Frequency (in)',
    source: CalendarUnitEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (!value) {
          self.redemptionTermsNotice = undefined;
          self.redemptionTermsNoticePeriod = undefined;
          self.redemptionFee = undefined;
          self.redemptionCustomDates = undefined;
        } else if (value === CalendarUnitEnum.Custom) {
          self.redemptionTermsFrequencyAmount = undefined;
          self.redemptionTermsFrequencyDay = undefined;
        } else {
          self.redemptionCustomDates = undefined;
        }
        self.validate('redemptionTermsFrequencyAmount');
        self.validate('redemptionTermsFrequencyDay');
      }
    },
  })
  redemptionTermsFrequency?: CalendarUnitEnum;

  redemptionCustomDates?: string;

  @meta({
    alias: 'Redemption (redeeming on)',
    source: RedemptionDayEnum.toKeyValue(),
    softNotEmpty: true,
  })
  redemptionTermsFrequencyDay?: string;

  @meta({
    alias: 'Redemption (notice)',
    hardNotLessOrEqualTo: 0,
    softNotMoreThan: 120,
  })
  redemptionTermsNotice?: number;

  @meta({
    alias: 'Redemption (notice in)',
    source: ActiveTimePeriodEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        self.validate('redemptionTermsNotice');
      }
    },
  })
  redemptionTermsNoticePeriod?: ActiveTimePeriodEnum;

  @meta({
    alias: 'Redemption (fee)',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotLessThan: 1,
    softNotMoreThan: 10,
  })
  redemptionFee?: number;

  @meta({
    alias: 'Gate',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          self.add('gates', self.hasModified('hasGate'));
        } else {
          self.removeAll('gates');
        }
      }
    },
  })
  hasGate?: SimpleAnswerEnum;

  @Type(() => Gate)
  @meta({
    alias: 'Gate ##',
    navigation: true,
    adds: 'Gate',
    removes: 'soft',
  })
  gates: Array<Gate>;

  @meta({
    alias: 'Holdback',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotLessThan: 2,
    softNotMoreThan: 10,
  })
  holdback?: number;

  //#endregion

  //#region <<< Section - Fees >>>

  @meta({
    alias: 'Management Fee',
    source: SimpleAnswerPassEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value === SimpleAnswerPassEnum.Yes) {
          self.mgmtFeeRateType = MgmtFeeRateTypeEnum.SingleRate;
          self.managementFeePassThrough = undefined;
        } else if (value === SimpleAnswerPassEnum.PassThrough) {
          self.mgmtFeeRateType = undefined;
          self.payable = undefined;
          self.hasManagementFeeDiscounts = undefined;
        } else {
          self.managementFeePassThrough = undefined;
          self.mgmtFeeRateType = undefined;
          self.payable = undefined;
          self.hasManagementFeeDiscounts = undefined;
        }
      }
    },
  })
  hasManagementFee: SimpleAnswerPassEnum;

  @meta({
    alias: 'Pass Through',
    source: PassThroughManagementFeeTypeEnum.toKeyValue(),
  })
  managementFeePassThrough?: PassThroughManagementFeeTypeEnum;

  @meta({
    alias: 'Management Fee Rate Type',
    source: MgmtFeeRateTypeEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        self.removeAll('mgmtFeeRates');
        if (value) {
          self.add('mgmtFeeRates', self.hasModified('mgmtFeeRateType'));
        }
      }
    },
  })
  mgmtFeeRateType: MgmtFeeRateTypeEnum;

  @Type(() => MgmtFeeRate)
  @meta({
    navigation: true,
    alias: 'Management Fee ##',
    adds: 'MgmtFeeRate',
    removes: 'soft',
  })
  mgmtFeeRates: Array<MgmtFeeRate>;

  @meta({
    alias: 'Payable',
    source: MgmtFeeFrequencyEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value === MgmtFeeFrequencyEnum.Other) {
          self.payableDescription = undefined;
        }
      }
    },
  })
  payable: MgmtFeeFrequencyEnum;

  @meta({
    alias: 'Description',
    softNotEmpty: true,
  })
  payableDescription: string;

  @meta({
    alias: 'Discount',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          self.add(
            'managementFeeDiscounts',
            self.hasModified('hasManagementFeeDiscounts')
          );
        } else {
          self.removeAll('managementFeeDiscounts');
        }
      }
    },
  })
  hasManagementFeeDiscounts: SimpleAnswerEnum;

  @Type(() => Discount)
  @meta({
    alias: 'Management Fee Discount ##',
    navigation: true,
    adds: 'Discount',
    removes: 'soft',
    auditRoute: 'ManagementFeeDiscounts',
  })
  managementFeeDiscounts: Discount[];

  @meta({
    alias: 'Incentive Fee',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          self.incFeeRateType = IncFeeRateTypeEnum.SingleRate;
        } else {
          self.incFeeRateType = undefined;
          self.crystalization = undefined;
          self.crystalizationPeriod = undefined;
          self.highwaterMark = undefined;
          self.hasIncentiveFeeDiscounts = undefined;
        }
        self.validate('incFeeRateType');
      }
    },
  })
  hasIncentiveFee: SimpleAnswerEnum;

  @meta({
    alias: 'Rate Type',
    source: IncFeeRateTypeEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        self.removeAll('incFeeRates');
        if (value === IncFeeRateTypeEnum.TieredRate) {
          self.add('incFeeRates', self.hasModified('incFeeRateType'));
          self.add('incFeeRates', self.hasModified('incFeeRateType'));
        } else if (value) {
          self.add('incFeeRates', self.hasModified('incFeeRateType'));
          if (value === IncFeeRateTypeEnum.SlidingScaleRate) {
            self.incFeeRates.forEach(
              (fee) => (fee.hasHurdle = SimpleAnswerEnum.Yes)
            );
          }
        }
      }
    },
  })
  incFeeRateType: IncFeeRateTypeEnum;

  @Type(() => IncFeeRate)
  @meta({
    alias: 'Incentive Fee ##',
    navigation: true,
    adds: 'IncFeeRate',
    removes: 'soft',
  })
  incFeeRates: Array<IncFeeRate>;

  @meta({
    alias: 'Crystalization',
    hardNotLessOrEqualTo: 0,
    softNotLessThan: 1,
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        if (target instanceof OpenShareClass) {
          switch (target.crystalizationPeriod) {
            case CalendarUnitEnum.Days:
              return !value || value <= 5
                ? (self.message = null)
                : (self.message = `${self.defaultMessage} 5`);
            default:
              return !value || value <= 3
                ? (self.message = null)
                : (self.message = `${self.defaultMessage} 3`);
          }
        }
      },
    },
    softEqual: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: 'Did you mean every {0} {1}?',
      validateRule: (self, value, target, prop) => {
        if (target instanceof OpenShareClass) {
          switch (target.crystalizationPeriod) {
            case CalendarUnitEnum.Semesters:
              return !value || value !== 2
                ? (self.message = null)
                : (self.message = self.defaultMessage
                    .replace('{0}', 1)
                    .replace('{1}', 'year'));
            case CalendarUnitEnum.Quarters:
              return !value || value !== 2
                ? (self.message = null)
                : (self.message = self.defaultMessage
                    .replace('{0}', 1)
                    .replace('{1}', 'semester'));
            case CalendarUnitEnum.Months:
              return !value || value !== 3
                ? (self.message = null)
                : (self.message = self.defaultMessage
                    .replace('{0}', 1)
                    .replace('{1}', 'quarter'));
            case CalendarUnitEnum.Days:
              return !value || value !== 15
                ? (self.message = null)
                : (self.message = self.defaultMessage
                    .replace('{0}', 2)
                    .replace('{1}', 'weeks'));
            default:
              return (self.message = null);
          }
        }
      },
    },
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        self.validate('crystalizationPeriod');
      }
    },
  })
  crystalization?: number;

  @meta({
    alias: 'Frequency',
    source: CalendarUnitEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        self.validate('crystalization');
      }
    },
  })
  crystalizationPeriod?: CalendarUnitEnum;

  @meta({
    alias: 'High watermark',
    source: HighWaterMarkEnum.toKeyValue(),
    softNotEmpty: true,
  })
  highwaterMark?: HighWaterMarkEnum;

  @meta({
    alias: 'Discount',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof OpenShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          self.add(
            'incentiveFeeDiscounts',
            self.hasModified('hasIncentiveFeeDiscounts')
          );
        } else {
          self.removeAll('incentiveFeeDiscounts');
        }
      }
    },
  })
  hasIncentiveFeeDiscounts: SimpleAnswerEnum;

  @Type(() => Discount)
  @meta({
    alias: 'Incentive Fee Discount ##',
    navigation: true,
    adds: 'Discount',
    removes: 'soft',
    auditRoute: 'IncentiveFeeDiscounts',
  })
  incentiveFeeDiscounts: Discount[];

  //#endregion

  auditURL = 'shareclass/audit/openend/?propertyPaths=ShareClass_{0}@{1}';
  auditURLParams = ['classId@model', '@prop'];

  constructor(fundId: number, className?: string, isDefault = false) {
    super();
    this.fundId = fundId;
    this.state = ModelState.Ready;
    this.className = className ?? 'Default Class';
    this.isDefault = isDefault;
  }
}
