<div class="ui-control rows3" [class]="[labelPositionEnum[settings.labelPosition],modelState[state]]" [class.disabled]="disabled">
    <label-v2 class="ui-header" 
        [label]="focused || ((value$ | async) !== undefined && (value$ | async) !== null && (value$ | async) !== '') ? (settings.labelAlt || settings.label) : settings.label" 
        [class.focused]="focused || ((value$ | async) !== undefined && (value$ | async) !== null && (value$ | async) !== '')" [tooltip]="settings.tooltip"
        (click)="focusin(true)">
    </label-v2>
    <input class="ui-body r2s1" #input
        [value]="(value$ | async | numberFormat:focused:settings.prefix:settings.suffix:settings.abbr:false:4:settings.thousandSep) || ''"
        [placeholder]="(focused || !settings.label) ? settings.placeholder : ''"
        [disabled]="disabled"
        (keydown)="filterKeys($event, input.value)"
        (keyup)="updateValue(input.value)" 
        (keyup.enter)="input.blur()"
        (focusin)="focusin()"
        (focusout)="focusout(input.value)"
    />
    <div class="ui-toolbar r2s1">
        <fa-icon e2e-id="reset" [icon]="faTimes" *ngIf="settings.canReset && !disabled && ((value$ | async) !== undefined && (value$ | async) !== null && (value$ | async) !== '')" (click)="reset()"></fa-icon>
        <ng-template inject></ng-template>
        <ng-content></ng-content>
    </div>
    <div class="ui-icon r2s1">
        <ng-content select="[icon]"></ng-content>
    </div>
    <span class="ui-footer r3s1 c1s20" [class]="settings.footerAlignment" 
        *ngIf="settings.referralMessage && (value$ | async) === settings.referralValue && state !== modelState.HasError">{{settings.referralMessage}}
    </span>
    <validation-v2 class="ui-footer r3s1 c1s20" [class]="settings.footerAlignment" [validations]="(validations | async)"></validation-v2>
</div>