import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapFromKeyValue'
})
export class MapFromKeyValuePipe implements PipeTransform {

  transform(key: string | Array<string>, source: unknown[], keyProp: string = 'key', valueProp: string = 'value', passThrough: boolean = false): unknown {
    if (Array.isArray(key)){
      let items = source?.filter( item => key.includes(item[keyProp]))?.map( item => item[valueProp])?.join(", ");
      return items ?? null;
    }
    if (key !== undefined && key !== null && key !== ''){
      let item = source?.find( item => item[keyProp] === key);
      if (item){
        return item[valueProp]; 
      }
      return null;
    }
    else return null;
  }

}
