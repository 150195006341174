import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
}

/* Sentry.init({
  dsn: `https://f9e24ce539d3415ba03f1068d2195383@o462085.ingest.sentry.io/5953676`,
  release: environment.version,
  environment: environment.environmentVariable, 
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: ["localhost", "https://aksia.sentry.io/api"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Integrations.Breadcrumbs({
      console: false
    })
  ],
  tracesSampleRate: 1.0
}); */

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
