import { meta, Meta, ModelState } from "@aksia-monorepo/shared-ui";
import { SimpleAnswerEnum } from "../../enums/enums";

export class ClosedEndGPTargetInvestmentStat extends Meta {

    closedEndDataFieldsId: number = 0;

    //#region Portfolio Company Statistics (GP Provided)

    @meta({alias: 'GP Revenue Range High' })
    revenueRangeHigh?: number; 

    @meta({alias: 'GP Revenue Range Low' })
    revenueRangeLow?: number;

    @meta({alias: 'GP EBITDA Range High', auditRoute: 'EBITDARangeHigh' })
    ebitdaRangeHigh?: number; 

    @meta({alias: 'GP EBITDA Range Low', auditRoute: 'EBITDARangeLow' })
    ebitdaRangeLow?: number; 

    @meta({alias: 'GP Enterprise Value Range High' })
    enterpriseValueRangeHigh?: number; 

    @meta({alias: 'GP Enterprise Value Range Low' })
    enterpriseValueRangeLow?: number; 

    @meta({alias: 'GP Equity Investment Range High' })
    equityInvestmentRangeHigh?: number; 

    @meta({alias: 'GP Equity Investment Range Low' })
    equityInvestmentRangeLow?: number; 

    @meta({alias: 'GP Transaction Multiple Range High' })
    transactionMultipleHigh?: number; 

    @meta({alias: 'GP Transaction Multiple Range Low' })
    transactionMultipleLow?: number;
    
    @meta({alias: 'GP Transaction Multiple Range High (Revenue)' })
    transactionMultipleRevenueHigh?: number;
    
    @meta({alias: 'GP Transaction Multiple Range Low (Revenue)' })
    transactionMultipleRevenueLow?: number;

    @meta({alias: 'Number of Portfolio Companies High' })
    numPortfolioCompaniesHigh?: number;
    
    @meta({alias: 'Number of Portfolio Companies Low' })
    numPortfolioCompaniesLow?: number;  

    //#endregion

    //#region Investment Details

    @meta({alias: 'Seed Assets', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    seedAssets?: SimpleAnswerEnum; 

    @meta({alias: 'Program Equity Committed to Seed Assets' })
    programEquityCommittedSeedAssets?: number;  

    @meta({alias: 'Max Development' })
    maxDevelopment?: number;  

    @meta({alias: 'Target Holding Period' })
    targetHoldingPeriod?: number;  

    //#endregion

    @meta({alias: 'Board Seats', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    boardSeats?: SimpleAnswerEnum; 
    
    @meta({ alias: 'Number of Seed Investment' })
    numSeedInvestments?: number;

    @meta({ alias: 'Preferred Company Valuation Low' })
    preferredCompanyValuationLow?: number;

    @meta({ alias: 'Preferred Company Valuation High' })
    preferredCompanyValuationHigh?: number;

    isDeleted?: boolean;  
    modifiedBy?: string;  
    modifiedOn?: Date;
    
    auditURL = 'basicdata/fund/audit/{0}/closedend/{1}/closedEndGPTargetInvestmentStat/{2}';
    auditURLParams = ['grandParent.fundId@model','parent.id@model','@prop'];

    constructor() {
        super();
        this.state = ModelState.Ready;
        this.excludeFromNotePath = true;
    }
}

