import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AuthenticationService, ENVIRONMENT, SharedUiModule } from '@aksia-monorepo/shared-ui';
import { environment } from '../environments/environment';
import { SharedO2Module} from './shared/shared-o2.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { RouteService } from './core/services/route.service';
import { AuditService } from './core/services/audit.service';
import { ExcelService } from './core/services/excel.service';
import { BaseLoggingService } from '@aksia-monorepo/shared-ui';

import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { CsrfHeaderInterceptor } from './core/interceptors/csrf-header.interceptor';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';

import { FromJSONPipe, NumberFormatPipe } from '@aksia-monorepo/shared-ui';

import { AppComponent } from './app.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AppInjector } from './core/services/app-injector.service';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { LoggingService as SentryLoggingService } from './core/services/logging.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedUiModule,
    SharedO2Module,
    HttpClientModule,
    FontAwesomeModule,
    ScrollingModule
  ],
  providers: [
    RouteService,
    AuditService,
    ExcelService,
    {
      provide: AuthenticationService,
      useClass: AuthenticationService
    },
    {
      provide: SentryLoggingService,
      useClass: SentryLoggingService
    },
    {
      provide: BaseLoggingService,
      useExisting: SentryLoggingService,
      deps: [SentryLoggingService, AuthenticationService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CsrfHeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    { 
      provide: ENVIRONMENT, 
      useValue: environment 
    },
    DatePipe,
    DecimalPipe,
    NumberFormatPipe,
    FromJSONPipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  exports: [
    SharedUiModule,
    SharedO2Module,
    ScrollingModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
