import { Component, OnInit, ViewChild, OnDestroy, TemplateRef, isDevMode, NgZone } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { faPercentage, faBuilding, faUser, faFileMedicalAlt, faFileCode } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '@aksia-monorepo/shared-ui';
import { RouteService } from './core/services/route.service';
import { SearchService } from './core/services/search.service';
import { LoadingService } from './core/services/loading.service';
import { SearchResponse, SearchEntity } from './core/interfaces/system.interface';
import { TakeUntilDestroy } from './core/decorators/destroy.decorator';
import { EntityTypeEnum } from './core/enums/enums';
import { AuditService } from './core/services/audit.service';
import { SafeUrl, Title } from '@angular/platform-browser';
import { FundComponent } from './pages/fund/fund.component';
import { CompanyComponent } from './pages/company/company.component';
import { environment } from '../environments/environment';
import { DetectBrowserService } from 'libs/shared-ui/src/lib/services/detect-browser.service';
import { BroadcastService } from '@aksia-monorepo/shared-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthorizationService } from './core/services/authorization.service';

type TestStubType = {
  entityId
}

@Component({
  selector: 'aksia-monorepo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@TakeUntilDestroy
export class AppComponent implements OnInit, OnDestroy { 
  
  //#region Icons

  faUser = faUser;
  faFileMedicalAlt = faFileMedicalAlt;
  faFileCode = faFileCode;
  
  //#endregion
  
  @ViewChild('tmplHistory', { read: TemplateRef }) tmplHistory: TemplateRef<any>;

  version: string = environment.version;
  showLogo: boolean = true;
  showMasterToolbar: boolean = false;
  showNavigation: boolean = false;
  searchResults: any[];
  searchPlaceHolder: string = 'Search for Funds and Companies';
  searchLoading: boolean = false;
  gloabalLoader: boolean = false;
  historyLoader: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean;
  isUnLoading: boolean;
  isDevMode: boolean;
  isBrowserSupported: boolean = true;
  browserNameAndVersion: { name: string, version: string } = null;

  //#region Test props

  testStubUrl: SafeUrl;
  testStubName: string;
  private testComponent: FundComponent | CompanyComponent;

  //#endregion


  private componentDestroy: () => Observable<unknown>;

  constructor(
    private router: Router,
    public auth: AuthenticationService,
    public perm: AuthorizationService,
    public routeService: RouteService,
    private searchService: SearchService,
    private loadingService: LoadingService,
    public auditService: AuditService,
    private detectBrowserService: DetectBrowserService,
    private broadcast: BroadcastService,
    private zone: NgZone,
    private title: Title) {

  }

  ngOnInit() {
    this.isDevMode = isDevMode();
    
    this.router.events
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((nav) => {
        if (nav instanceof NavigationEnd) {
          this.showMasterToolbar = nav.urlAfterRedirects != '/main' && nav.urlAfterRedirects != '/login';
          this.showNavigation = this.showMasterToolbar;
          this.showLogo = nav.urlAfterRedirects != '/login';
        }
      });

    this.searchService.searchEntities$
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe(
        ((res: SearchResponse) => {
          if (this.showMasterToolbar){
            this.flattenEntities(res);
            this.searchLoading = false; 
          } 
        })
      );

    this.loadingService.isMainLoading$
    .pipe(takeUntil(this.componentDestroy()))
    .subscribe( (isLoading: boolean) => {
        let self = this;
        setTimeout(() => { self.isLoading = isLoading },0);
    });

    this.browserNameAndVersion = this.detectBrowserService.getBrowserNameAndVersion();
    if (this.browserNameAndVersion.name !== 'Chrome'){
      this.isBrowserSupported = false;
    }

    this.broadcast.messageOfType('event_signout')
    .pipe(takeUntil(this.componentDestroy()))
    .subscribe(() => {
      this.zone.run(() => {
        this.isLoggedIn = false;
        this.title.setTitle('O2');
        this.router.navigateByUrl('/login');  
      });
    })
  }

  ngOnDestroy() { }

  signOut() {
    this.auth.signOut()
    .pipe(takeUntil(this.componentDestroy()))
    .subscribe(
      (success: any): void => {
        this.auth.clearSecurity();
        this.broadcast.publish({ type: 'event_signout', payload: 'Signed out' });
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        if (error?.status === 401){
          this.auth.clearSecurity();
        }
      }
    )
  }

  onRouterOutletActivate(component: FundComponent | CompanyComponent){
    this.testComponent = component;
  }

  onSelectedValue(entity: { value: SearchEntity, ctrlKey: boolean }): void {
    this.searchResults = [];
    this.searchService._searchEntities = null;
    
    if (!entity.ctrlKey){
      this.loadingService.loading(true);
    }
    
    if (entity.value.entityTypeId == EntityTypeEnum.Fund) {
      this.routeService.goToPage('investmentvehicle', entity.value.entityId, entity.ctrlKey);
    } else {
      this.routeService.goToPage('managementcompany', entity.value.entityId, entity.ctrlKey);
    }
    this.searchResults = [];
  }

  onSearchText(text: string): void {
    if (text === null || text === '') {
      this.searchResults = [];
      return;
    }
    this.searchLoading = true;
    this.searchService.getEntitiesApi(text, ["searchLoader"]);
  }

  public historyAsOfChanged(asOf: string): void {
    if (asOf == null || asOf == "") {
      return;
    }
    const currentAsOf = new Date(asOf);
    if (!currentAsOf) {
      return;
    }
  }

  goTo(page: string) {
    this.routeService.setEntity(null);
    this.routeService.goToPage(page, null);
  }

  private flattenEntities(entities: SearchResponse): void {
    if (entities === null) {
      this.searchResults = [];
      return;
    }

    this.searchResults = [];
    entities.funds.forEach((entity: SearchEntity) => {
      entity['icon'] = faPercentage;
      this.searchResults.push(entity);
    });

    entities.managementCompanies.forEach((entity: SearchEntity) => {
      entity['icon'] = faBuilding;
      this.searchResults.push(entity);
    });
  }

  private isInViewport(el: any) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;
  
    while(el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }
  
    return (
      top < (window.pageYOffset + window.innerHeight) &&
      left < (window.pageXOffset + window.innerWidth) &&
      (top + height) > window.pageYOffset &&
      (left + width) > window.pageXOffset
    );
  }
}

