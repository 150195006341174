import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'includesOrEquals'
})
export class IncludesOrEqualsPipe implements PipeTransform {

  transform(against: unknown | Array<unknown>, value: unknown, prop?: string): unknown {
    if (!against){
      return false;
    }
    if (!prop){
      return Array.isArray(against) ? against.includes(value) : value === against;
    }
    else {
      return Array.isArray(against) ? against.some( ag => ag[prop] === value[prop]) : against[prop] === value[prop];
    }
    
  }

}
