import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromJSON'
})
export class FromJSONPipe implements PipeTransform {
  transform(value: string, prop: string = null, key?: string): unknown {
    let fromJSON;
    try {
      fromJSON = JSON.parse(value);
    }
    catch(err) {
      throw err;
    }
    if (fromJSON){
      if (prop){
        if (Array.isArray(fromJSON)){
          fromJSON = fromJSON.find(item => item.Key === key);
          return fromJSON ? fromJSON[prop] : undefined;
        }
        else {
          return key ? fromJSON[prop] : undefined;
        }
      }
      else {
        return fromJSON;
      }
    }
  }
}
