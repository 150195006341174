/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable no-prototype-builtins */
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, EventEmitter, inject, Inject, Injector, Input, isDevMode, OnDestroy, Optional, Output, Type, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Meta, ValidationResult } from '../../../decorators/meta.decorator';
import { BehaviorSubject, Observable } from 'rxjs';
import { LogCategory, LabelPositionEnum, ModelState, ValidationStyleEnum } from '../../../enums/enums';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { TakeUntilDestroy } from '../../../decorators/destroy.decorator';
import { skip, takeUntil } from 'rxjs/operators';
import { InjectItem } from '../../../types/types';
import { InjectDirective } from '../../../directives/inject.directive';
import { BaseLoggingService } from '../../../services';

export type UIControlSettings = {
  label?: string;
  labelAlt?: string;
  labelPosition?: LabelPositionEnum;
  tooltip?: string;
  placeholder?: string;
  canReset?: boolean;
  abbr?: boolean;
  footerAlignment?: 'Left' | 'Right';
  notifiesState?: boolean;
  notifiesValidation?: boolean;
  disabled: boolean;
  referralValue: number;
  referralMessage: string;
  additionalState: string;
  source?: Array<{key: any, value: string}>;
  e2eId: string;
}

export const defaultSettings: UIControlSettings = {
  label: undefined,
  labelAlt: undefined,
  labelPosition: LabelPositionEnum.Embedded,
  tooltip: undefined,
  placeholder: '',
  canReset: true,
  abbr: false,
  footerAlignment: 'Right',
  notifiesState: true,
  notifiesValidation: true,
  disabled: false,
  referralValue: undefined,
  referralMessage: undefined,
  additionalState: undefined,
  source: undefined,
  e2eId: undefined
}

@Component({
  template: ''
})
@TakeUntilDestroy
export class BaseComponent<T = unknown> implements ControlValueAccessor, OnDestroy, AfterViewInit {

  ngOnDestroy() { }

  //#region Icons

  faTimes = faTimes;
  
  //#endregion Icons;

  //#region Private/Protected Properties

  protected cdRef: ChangeDetectorRef;
  protected _model: Meta;
  protected _prop: string;
  protected _settings: UIControlSettings = {...defaultSettings};
  protected _disabled: boolean;
  
  //#endregion

  //#region Public Properties

  componentDestroy: () => Observable<unknown>;

  state: ModelState = ModelState.Ready;
  fieldState : ModelState = ModelState.Ready;
  @Input() validations: BehaviorSubject<Array<ValidationResult>> = new BehaviorSubject(undefined);
  modelState = ModelState;
  labelPositionEnum = LabelPositionEnum;
  value$: BehaviorSubject<T> = new BehaviorSubject(null);
  focused: boolean;
  isMouseOn: boolean;
  isMouseDown: boolean;
  isMouseDragging: boolean;
  
  //#endregion

  //#region ViewChildren

  @ViewChild(InjectDirective, { static: true }) injectHost: InjectDirective;

  //#endregion

  //#region Input/Output
  
  @Input()
  set settings(value: UIControlSettings){
    for (let k in defaultSettings) {
      this._settings[k] = value[k] ?? this._settings[k] ?? defaultSettings[k];
    }
  }

  get settings() {
    return this._settings;
  }

  @Input() 
  set value(value: T) {
    if (!this.model){
      //this.focused = value ? true : false;
      this.focused = false;
      this.value$.next(value);
      this.notifyValueChange();
    }
  } 
  
  get value(): T {
    return this.value$.value;
  }

  @Input()
  set model(value: Meta) {
    this._model = value;
    if (this._model && this._prop){
      this.value$.next(this._model[this.prop]);
      this.getLabelFromAlias();
      this.attachValidations();
      this.applyInjections();
      const self = this;
      setTimeout( () => { 
        if (self.model?.validateAll){
          self.model.validateAll() 
        }
      },1);
      this.notifyValueChange();
      this.getSource();
    }
  } 
  
  get model(): Meta {
    return this._model;
  }

  @Input()
  set prop(value: string) {
    this._prop = value;

    if (this._model && this._prop){
      this.attach_e2e_attributes();
      this.value$.next(this._model[this.prop]);
      this.getLabelFromAlias();
      this.attachValidations();
      this.notifyValueChange();
      this.getSource();
    }
  }

  get prop(){
    return this._prop;
  }

  @Input()
  set disabled(value: boolean){
    this._disabled = value;
  }

  get disabled() {
    return this._disabled;
  }

  private _injectItems: Array<InjectItem>;
  @Input() set injectItems(value: Array<InjectItem>) {
    this._injectItems = value;
    this.applyInjections();
  }
  
  get injectItems() {
    return this._injectItems;
  }

  private _updatedBy?: any;
  @Input() 
  set updatedBy(value: any){
    if (this._updatedBy !== value){
      this._updatedBy = value;
      this.value$.next(this.model[this.prop]);
    }
    
  } 

  @Output() valueChanged: EventEmitter<unknown> = new EventEmitter<unknown>();

  //#endregion

  onChange: (value: T) => Record<string, unknown>;
  onTouched: () => Record<string, unknown>;

  constructor(
    public injector: Injector, 
    private cmpFactoryResolver: ComponentFactoryResolver, 
    private elRef?: ElementRef,
    private logService?: BaseLoggingService
  ) {

    this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
  }

  ngAfterViewInit(): void {
      //this.applyInjections();
  }

  //#region Public Methods
  focusin() {
    if (!this.disabled){
      this.focused = true;
    }
  }

  focusout(value?: T) {
    this.focused = false;
    this.delegateValue(value ?? this.value$.value);
  }

  reset(ctx?: any) {
    ctx = ctx ?? this;
    ctx.delegateValue(undefined);
  }

  undo() {
    const history = this.model.getInstanceMeta('history', this.prop);
    if (history.length > 1){
      history.pop();
    }
    this.model[this.prop] = history[history.length - 1]
    this.value$.next(history[history.length - 1]);
  }

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: T): void {
    this.value$.next(value);
    setTimeout(() => this.cdRef.detectChanges(), 0);
  }

  registerOnChange(fn: () => Record<string, unknown>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => Record<string, unknown>): void {
    this.onTouched = fn;
  }

  //#endregion

  //#region Private/Protected Methods
  
  private applyInjections() {
    if (this.injectItems?.length > 0 && this.injectHost){
      const viewContainerRef = this.injectHost.viewContainerRef;
      viewContainerRef.clear();

      this.injectItems.forEach( injectItem => {
        if (injectItem?.component){
          const cmpFactory = this.cmpFactoryResolver.resolveComponentFactory(injectItem.component);
          const componentRef = viewContainerRef.createComponent(cmpFactory);
          injectItem.inputs?.forEach( input => {
            componentRef.instance[input.name] = typeof input.value === 'function' ? input.value(this) : input.value;
          })
          injectItem.functions?.forEach(func => componentRef.instance[func]());
        }
      });
    }
  }

  private getLabelFromAlias() {
    if (this._settings.label === undefined){
      let label:string = this._model?.getClassMeta('alias',this.prop);
      if (label && label.endsWith("##") && Array.isArray(this.model?.parent)){
        label = label.replace('##',this.model?.parent.filter( item => !item.markedForDeletion)?.length > 1 ? `#${this.model.index + 1}` : '');
      }
      this._settings.label = label;

      let labelAlt:string = this._model?.getClassMeta('aliasAlt',this.prop);
      if (labelAlt && labelAlt.endsWith("##") && Array.isArray(this.model?.parent)){
        labelAlt = labelAlt.replace('##',this.model?.parent.filter( item => !item.markedForDeletion)?.length > 1 ? `#${this.model.index + 1}` : '');
      }
      this._settings.labelAlt = labelAlt;
    }
  }

  private attachValidations() {
    if (this.model.errorsAndWarnings$){
      this.model.errorsAndWarnings$
      .pipe(
        skip(1),
        takeUntil(this.componentDestroy())
      )
      .subscribe((validations: {metaId: number, prop: string, list: Array<ValidationResult>}) => {
        if (this.model.metaId === validations.metaId && this.prop === validations.prop){
          this.validations.next(validations.list);
          if (validations.list.some( vl => vl.type === ValidationStyleEnum.Hard)){
            this.state = ModelState.IsDirty;
            if (this.settings.notifiesState && this.model.updateState) {
              this.model.updateState(this.state,this.settings.additionalState);
            }
          }
          else {
            this.state = ModelState.IsDirty;
          }
        }
      })
    }
  }

  private delegateValue(value: T){
    if (this.model && this.prop){
      if (this.model[this.prop] !== value){
        if (this.settings.notifiesState){
          this.model.setModified(this.prop);
          this.model.updateState(ModelState.IsDirty,this.settings.additionalState);
        }
        this.model[this.prop] = value;
        this.value$.next(value);
        this.valueChanged.emit(this.value$.value);
      }
      else {
        this.value$.next(value);
      }
    }
    else {
      this.value = value;
      this.valueChanged.emit(value);
    }

    this.logService.addInfo(LogCategory.UpdateValue, `${this.prop} is set to: ${this.value$.value}`, this.model);
  }

  protected attach_e2e_attributes(e2eId?: string) {
    if (isDevMode()){
      const e2eIdValue = e2eId ? e2eId : `${this.prop}_${this.model.metaId}`;
      this.settings.e2eId = e2eIdValue;
      this.elRef?.nativeElement.setAttribute("e2e-id",this.settings.e2eId);
    }
  }

  protected getSource() {
    this.settings.source = this.model.getClassMeta('source', this.prop);
  }

  //#endregion

}
