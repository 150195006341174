import { meta, Meta, ModelState, toLocalDate } from "@aksia-monorepo/shared-ui";
import { Transform } from "class-transformer";
import { SimpleAnswerEnum, RankEnum } from "../../enums/enums";

export class ClosedEndTrackRecord extends Meta {
    
    closedEndTrackRecordId?: number = 0; 
    closedEndDataFieldsId?: number = 0; 

    //#region Return Profile

    @Transform( ({value}) => toLocalDate(value), { toClassOnly: true })
    @meta({ alias: 'As of' }) 
    performanceAsOfDate?: Date; 

    @meta({ alias: 'Gross IRR (%)' }) 
    grossIRR?: number; 

    @meta({ alias: 'Net IRR (%)' }) 
    netIRR?: number; 

    @meta({ alias: 'Gross TVPI' })
    grossMOIC?: number; 

    @meta({ alias: 'Net TVPI' })
    netMOIC?: number;

    @meta({ alias: 'Net DPI' })
    netDPI?: number;

    @meta({ alias: 'Net RVPI' })
    netRVPI?: number;

    @meta({ alias: 'Invested' })
    investedCapital?: number; 

    //#endregion

    isDeleted?: boolean; 
    modifiedBy?: string; 
    modifiedOn?: Date;
    
    auditURL = 'basicdata/fund/audit/{0}/closedend/{1}/closedEndTrackRecord/{2}/{3}';
    auditURLParams = ['grandParent.fundId@model','parent.id@model','closedEndTrackRecordId@model','@prop'];

    constructor() {
        super();
        this.state = ModelState.Ready;
        this.excludeFromNotePath = true;
    }
    
}