import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageNavigationService {
  
  private readonly _navItems = new BehaviorSubject<Array<{ title: string, ref: ElementRef, order: number, level: number}>>([{ title: 'Top', ref: undefined, order: 0, level: 2 }]);
  readonly navItems$ = this._navItems.asObservable();
  public get navItems() {
    return this._navItems.getValue();
  }
  public set navItems(value) {
    this._navItems.next(value);
  }
  constructor() { }

}
