import { ElementRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, PositionStrategy, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { PopoverComponent } from '../components/popover/popover.component';
import { PopoverParams, PopoverSettings } from '../types/types';
import { PopoverRef } from '../classes/ui.model';
import { PopoverStaticPosition } from '../enums/enums';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {

  targetEl: ElementRef<any> = null;
  popoverRef: PopoverRef = null;

  constructor(private overlay: Overlay, private injector: Injector) { }

  open<T>({ origin, content, data, width, height, settings }: PopoverParams<T>): PopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin, width, height, settings }));
    const popoverRef = new PopoverRef<T>(overlayRef, content, data);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

    this.popoverRef = popoverRef;
    return popoverRef;
  }

  close(ref?: PopoverRef){
    if (ref){
      ref.close();
    }
    else {
      this.popoverRef?.close();
    }
  }

  private getOverlayConfig({ origin, width, height, settings }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: settings && settings != null && settings.hasBackdrop != null ? settings.hasBackdrop : true,
      backdropClass: 'popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin, settings),
      scrollStrategy: this.overlay.scrollStrategies.block(),
      maxHeight: this.getMaxHeight(origin, settings != null && settings.position && settings.position != null ? settings.position : null),
      maxWidth: this.getMaxWidth(origin, settings != null && settings.position && settings.position != null ? settings.position : null)
    });
  }

  private getOverlayPosition(origin: HTMLElement, settings: PopoverSettings = null): PositionStrategy {
    if(settings?.position && settings?.position == 6){
      return this.getGlobalPosition()
    }

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions(this.getPositions(origin, settings != null && settings.position && settings.position != null ? settings.position : null ))
      .withFlexibleDimensions(false)
      .withPush(settings != null && settings.canDrag && settings.canDrag != null ? settings.canDrag : false);

    return positionStrategy;
  }

  createInjector(popoverRef: PopoverRef, injector: Injector) {
    const tokens = new WeakMap([[PopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getGlobalPosition(){
    let positionStrategy = this.overlay.position().global();
    let topY = window.innerHeight * 15 / 100;
    return positionStrategy.top(`${topY.toString()}px`).centerHorizontally();
  }

  private getPositions(origin: HTMLElement, position: PopoverStaticPosition = null): ConnectionPositionPair[] {

    if (position != null) {
      return this.staticPositions.find(x => x.id == position).value;
    }

    if (!origin){
      origin = document.body;
    }
    
    // get element position
    const elementPosition: ClientRect = origin?.getBoundingClientRect() || document.body.getBoundingClientRect();

    // get window height/width
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    // left percentage
    const leftPercentage = elementPosition.left / windowWidth;
    // top percentage
    const topPercentage = elementPosition.top / windowHeight;

    if ((leftPercentage > 0.5) && (topPercentage <= 0.5)) { // set it right and bottom
      return this.staticPositions.find(x => x.id == PopoverStaticPosition.RightBottom).value;
    } else if ((leftPercentage > 0.5) && (topPercentage > 0.5)) { // set it right and top
      return this.staticPositions.find(x => x.id == PopoverStaticPosition.RightTop).value;
    } else if ((leftPercentage <= 0.5) && (topPercentage <= 0.5)) { // set it left and bottom
      return this.staticPositions.find(x => x.id == PopoverStaticPosition.LeftBottom).value;
    } else if ((leftPercentage < 0.5) && (topPercentage > 0.5)) { // set it left and top
      return this.staticPositions.find(x => x.id == PopoverStaticPosition.LeftTop).value;
    }
  }

  private getMaxHeight(origin: HTMLElement, position: PopoverStaticPosition = null): string {
    // get element position
    const elementPosition: ClientRect = origin?.getBoundingClientRect() || document.body.getBoundingClientRect();

    // get window height/width
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    // left percentage
    const leftPercentage = elementPosition.left / windowWidth;
    // top percentage
    const topPercentage = elementPosition.top / windowHeight;

    const heightBottom = (windowHeight - (elementPosition.top + elementPosition.height) - 4) + 'px';
    const heightTop = (elementPosition.top - 4) + 'px';

    if (position != null) {
      if (position == PopoverStaticPosition.CenterBottom || position == PopoverStaticPosition.LeftBottom || position == PopoverStaticPosition.RightBottom) {
        return heightBottom;
      } else {
        return heightTop;
      }
    }
    
    if (topPercentage <= 0.5) {
      return heightBottom;
    } else {
      return heightTop;
    }
  }

  private getMaxWidth(origin: HTMLElement, position: PopoverStaticPosition = null): string {
    // get element position
    const elementPosition: ClientRect = origin?.getBoundingClientRect() || document.body.getBoundingClientRect();

    // get window height/width
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    // left percentage
    const leftPercentage = elementPosition.left / windowWidth;
    // top percentage
    const topPercentage = elementPosition.top / windowHeight;

    if ((leftPercentage > 0.5) && (topPercentage <= 0.5)) { // set it right and bottom
      return (elementPosition.left + elementPosition.width - 22) + 'px';
    } else if ((leftPercentage > 0.5) && (topPercentage > 0.5)) { // set it right and top
      return (windowWidth - (windowWidth - elementPosition.right - 22)) + 'px';
    } else if ((leftPercentage <= 0.5) && (topPercentage <= 0.5)) { // set it left and bottom
      return (windowWidth - elementPosition.left - 22) + 'px';
    } else if ((leftPercentage < 0.5) && (topPercentage > 0.5)) { // set it left and top
      return (windowWidth - elementPosition.left - 22) + 'px';
    }
  }

  private staticPositions: any = [
    {
      id: PopoverStaticPosition.RightBottom,
      value: [
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ]
    },
    {
      id: PopoverStaticPosition.RightTop,
      value: [
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom'
        }
      ]
    },
    {
      id: PopoverStaticPosition.LeftBottom,
      value: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        }
      ]
    },
    {
      id: PopoverStaticPosition.LeftTop,
      value: [
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom'
        }
      ]
    },
    {
      id: PopoverStaticPosition.CenterBottom,
      value: [
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        }
      ]
    },
    {
      id: PopoverStaticPosition.CenterTop,
      value: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        }
      ]
    },
    {
      id: PopoverStaticPosition.Center,
      value: [
        {
          originX: 'center',
          originY: 'center',
          overlayX: 'center',
          overlayY: 'center'
        }
      ]
    }
  ];
}
