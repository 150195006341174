import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ENVIRONMENT } from '../environment/environment.token';
import { Environment } from '../environment/environment.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiMicroService {
  cache?: boolean;

  constructor(
    @Inject(ENVIRONMENT) private env: Environment,
    private http: HttpClient,
    @Inject('cache') @Optional() cache?: boolean
  ) {
    this.cache = cache;
  }

  public get(
    route: string,
    additionalParams?: Map<string, any>,
    rootUrl: string = this.env.baseUrl
  ): Observable<any> {
    return this.http.get(
      this.resolveRoute(route, rootUrl),
      this.generateOptions(route, additionalParams)
    );
  }

  public put(
    route: string,
    body: any = null,
    rootUrl: string = this.env.baseUrl
  ): Observable<any> {
    return this.http.put(
      this.resolveRoute(route, rootUrl),
      body,
      this.generateOptions(route)
    );
  }

  public post(
    route: string,
    body: any = null,
    rootUrl: string = this.env.baseUrl,
    additionalParamsMap?: Map<string, any>
  ): Observable<any> {
    return this.http.post(
      this.resolveRoute(route, rootUrl),
      body,
      this.generateOptions(route, additionalParamsMap)
    );
  }

  public postFile(
    route: string,
    formData: FormData,
    rootUrl: string = this.env.baseUrl
  ): Observable<any> {
    const options = this.generateOptions(
      route,
      null,
      null,
      'multipart/form-data'
    );
    //options['headers'] = new HttpHeaders({});
    /* const options = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
      withCredentials: true,
      reportProgress: true,
      ResponseType: 'json',
    }; */
    return this.http.post(this.resolveRoute(route, rootUrl), formData, {
      withCredentials: true,
    });
  }

  public delete(
    route: string,
    rootUrl: string = this.env.baseUrl
  ): Observable<any> {
    return this.http.delete(
      this.resolveRoute(route, rootUrl),
      this.generateOptions(route)
    );
  }

  private resolveRoute(route: string, envURI: string) {
    return `${envURI}/${route}`;
  }

  private generateOptions(
    route: string,
    additionalParamsMap?: Map<string, any>,
    additionalHeadersMap?: Map<string, string>,
    contentType: string = 'application/json'
  ) {
    var options = {};
    options['withCredentials'] = true;

    const params = new HttpParams();
    /* .set('Timestamp', Date.now().toString())
    .set('Route', encodeURIComponent(route));  */

    options['params'] = params;

    const defaultHeaders = {
      'Content-Type': contentType,
      'Cache-Control': 'no-cache',
      ResponseType: 'text',
    };
    const additionalHeaders = additionalHeadersMap
      ? Object.fromEntries(additionalHeadersMap)
      : {};
    const headers = { ...defaultHeaders, ...additionalHeaders };
    options['headers'] = new HttpHeaders(headers);

    return options;
  }
}
