
interface propWithResets {
    prop: string;
    resetBy: string;
}

export function resetBy(prop:string){
    return function (target: any, propertyKey: string) {
        ResetOrchestrator.registerResets(target, propertyKey, prop);
    }
}

export function castReset<T = any>(callBack?: string, whenNot?: any, scope?: any){
    return (target: T, key: keyof T): void => {
        const originalDescriptor = Object.getOwnPropertyDescriptor(target, key);
        let val;

        Object.defineProperty(target, key, {
            set(value) {
                const instance = this;
                const previousValue = val;

                if (previousValue === value) {
                    return;
                }

                val = value;
                
                if (originalDescriptor) {
                    originalDescriptor.set.call(instance, value);
                }

                if (callBack && val !== previousValue && val !== whenNot){
                    instance[callBack].call(scope || instance, value, key);
                }
            },
            get() {
                const instance = this;
                if (originalDescriptor) {
                    return originalDescriptor.get.call(instance);
                }
                return val;
            }
        });
    };
}

export class ResetOrchestrator {
    static propResetsMap: Map<any, Array<propWithResets>> = new Map();
    
    static registerResets(target: any, property: any, prop: string): void {
        let keys: Array<propWithResets> = this.propResetsMap.get(target);
        if (!keys) {
            keys = [];
            this.propResetsMap.set(target, keys);
        }
        keys.push({prop: property, resetBy: prop});
    }

    static reset(target: any, prop: string): void {
        if (target.isDirty){
            let propsWithResets: Array<propWithResets> = this.propResetsMap.get(Object.getPrototypeOf(target));
            for (const property of propsWithResets) {
                for (const p of property?.resetBy?.split(',')) {
                    if (p == prop) {
                        target[property.prop] = null;
                    }
                }
            }
        }
    }

}