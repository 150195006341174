<div class="ui-control rows3" [class]="[labelPositionEnum[settings.labelPosition],modelState[state]]" [class.editable]="settings.canAdd || settings.canRename" [class.isEditing]="mode == modes.adding || mode == modes.renaming" [class.disabled]="disabled">
    <label-v2 class="ui-header" [label]="mode == modes.adding ? 'Add new:' : mode == modes.renaming ? 'Rename:' : settings.label" [class.focused]="focused || ((value$ | async) !== undefined && (value$ | async) !== null && (value$ | async) !== '')" [tooltip]="settings.tooltip" (click)="focusin(true)"></label-v2>
    <input #input class="ui-body r2s1" type="text" 
        [readonly]="mode !== modes.renaming && mode !== modes.adding" 
        [disabled]="disabled"
        [placeholder]="(focused || !settings.label) ? settings.placeholder : ''"
        [value]="(value$ | async | mapFromKeyValue:source:settings.key:settings.value:mode == modes.adding || mode == modes.renaming)"
        [title]="(value$ | async | mapFromKeyValue:source:settings.key:settings.value:mode == modes.adding || mode == modes.renaming) || ''"
        (focusin)="focusin()" (keydown.tab)="focusout()" (keydown.shift.tab)="focusout()" (keyup.tab)="focusin()" (keyup.enter)="onKeyUpEnter(input.value)"
    />
    <div *ngIf="focused && mode === modes.selecting">
        <div *ngIf="settings.canFilter || settings.isAsync" class="ui-list-filter">
            <input class="filter" #search
                [(ngModel)]="searchValue"
                placeholder="Start typing to filter list..." 
                (keyup)="filter()" 
            />
            <div class="border"></div>
            <fa-icon [icon]="faActiveIcon" class="flex-end width-15 font-size-15" 
            [class.Loading]="isLoading" [class.pointer]="faActiveIcon === faTimesCircle" [class.rotating]="faActiveIcon === faSpinner"
            (click)="clearSearch()"></fa-icon>
        </div>
        <ul #list class="ui-list"
            [class.multiple]="settings.canSelectMultiple" 
            [class.uniqueViolation]="uniqueViolation" 
            [class]="[settings.alignList, settings.justifyList]"
            [class.canFilter]="settings.canFilter || settings.isAsync"
            [style.max-height.px]="settings.maxHeight">
            <li *ngFor="let item of (filteredSource || source);trackBy:trackBy"
                [class.selected]="(value$ | async | includesOrEquals:item[settings.key]) && !settings.canSelectMultiple"
                [attr.e2e-id]="settings.e2eId ? settings.e2eId + '_' + item[settings.key] : null"
                (click)="select(item)">
                <fa-icon *ngIf="item.icon" [icon]="item.icon ? item.icon : ''" style="padding-right: 5px;"></fa-icon>
                <span>{{item[settings.value]}}</span>
                <fa-icon *ngIf="(value$ | async | includesOrEquals:item.key) && settings.canSelectMultiple" [icon]="faCheck"></fa-icon>
            </li>
        </ul> 
    </div>
    <div class="ui-toolbar r2s1" [class.Pinned]="mode === modes.adding || mode === modes.renaming">
        <fa-icon class="r1s1" [icon]="faCheck" *ngIf="mode === modes.renaming || mode === modes.adding" (click)="mode === modes.renaming ? applyRenaming(input.value) : applyAdding(input.value)"></fa-icon>
        <fa-icon class="r1s1" e2e-id="reset" [icon]="faTimes" *ngIf="(settings.canReset && !disabled && !!(value$ | async)) || mode === modes.adding || mode === modes.renaming" (click)="resetOrCancel(input)"></fa-icon>
        <fa-icon class="r1s1" [icon]="faPlusCircle" *ngIf="!mode && settings.canAdd && !disabled" (click)="startAdding(input)"></fa-icon>
        <fa-icon class="r1s1" [icon]="faPen" *ngIf="!mode && settings.canRename && !disabled" (click)="startRenaming(input)"></fa-icon>
        <ng-template inject></ng-template>
        <ng-content *ngIf="!mode"></ng-content>
    </div>
    <div class="ui-toolbar r2s1 Pinned">
        <ng-content *ngIf="!mode" select="[pinned]"></ng-content>
    </div>
    <div class="ui-icon r2s1 pointer" (click)="focusin()">
        <fa-icon [icon]="faChevronDown" *ngIf="mode === modes.selecting || mode === modes.idle"></fa-icon>
    </div>
    <validation-v2 class="ui-footer r3s1 c1s20" [class]="settings.footerAlignment" [validations]="validations | async"></validation-v2>
</div>
