import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IEntityLoad } from '../interfaces/system.interface';

export class EntityLoad implements IEntityLoad {
  parentEntityId: number;
  parentEntityTypeId: number;
  parentEntityLabel: string;
  entityTypeId: number;
  entityId: number;
  label: string;
  constructor(
      entityTypeId?: number,
      entityId?: number,
      label: string = "",
      parentEntityId: number = -1,
      parentEntityTypeId: number = 1,
      parentEntityLabel: string = "",
      ) 
  {
      this.parentEntityId = parentEntityId;
      this.parentEntityTypeId = parentEntityTypeId;
      this.parentEntityLabel = parentEntityLabel;
      this.entityId = entityId;
      this.entityTypeId = entityTypeId;
      this.label = label;
  }
}

@Injectable({
  providedIn: 'root'
})
export class RouteService implements OnInit, OnDestroy {

  entity: EntityLoad;
  entity$: BehaviorSubject<EntityLoad> = new BehaviorSubject(null);
  private componentDestroy: () => Observable<unknown>;

  constructor(private router: Router) { }

  ngOnInit() {
    this.entity$
      .pipe(takeUntil(this.componentDestroy()))
      .subscribe((en => {
        this.entity = en;
      }));
  }

  ngOnDestroy() {}

  setEntity(entity: EntityLoad) {
    this.entity$.next(entity);
  }

  goToPage(url: string, entityId: number, newTab: boolean = false) {
    if (entityId != undefined) {
      if (!newTab){
        this.router.navigate([`${url}/${entityId}`]).then((val) => {
          console.warn('Navigation Response:');
          console.warn(val);
        });
      }
      else {
        window.open(`${environment.o2Url}#/${url}/${entityId}`, "_blank");
      }
    }
    else {
      if (!newTab){
        this.router.navigate([url]);
      }
      else {
        window.open(`${environment.o2Url}#/${url}`, "_blank");
      }
    }
  }
}
