import { SpreadSheetSettings } from '@aksia-monorepo/shared-ui';
import { plainToClass } from 'class-transformer';
import {
  Fund,
  AUMeta,
  AUMSpreadSheet,
  ClosedEndDetails,
  ClosedEndKeyTerm,
  ClosedEndStructure,
  ClosedEndGPInformation,
  ClosedEndCategorizationFactor,
  ClosedEndGPTargetInvestmentStat,
  ClosedEndTargetReturnProfile,
  ClosedEndTrackRecord,
  ClosedEndTeamViewModel,
  OpenShareClass,
  ClosedShareClass,
  HybridShareClass,
  Program,
} from '..';
import { LiquidityStructureEnum } from '../../enums/enums';
import { PERIODIC_STREAMS } from '../../services/periodic-streams.service';
import { LeverageMeta } from '../leverage/leverage.meta';
import { StreamMeta } from '../stream/stream.meta';
import { StreamSpreadSheet } from '../stream/stream.spreadsheet';
import { TaxonomyMeta } from '../taxonomy/taxonomy.meta';
import { CoInvestmentDetails } from './fund.coInvestmentDetails';
import { SecondaryDetails } from './fund.secondaryDetails';

export class FundUtils {
  static copy(source: Fund, liquidityStructure?: LiquidityStructureEnum): Fund {
    const plainFund = source.toPlain();
    const fund = plainToClass(Fund, plainFund);
    liquidityStructure = liquidityStructure ?? fund.liquidityStructure;
    fund.investmentProgram = plainToClass(Program, plainFund.investmentProgram);
    fund.taxonomyMeta = plainToClass(TaxonomyMeta, plainFund.taxonomyMeta);
    fund.leverageMeta = plainToClass(LeverageMeta, plainFund.leverageMeta);
    fund.state = source.state;

    if (liquidityStructure === LiquidityStructureEnum.ClosedEnd) {
      fund.closedEndDetails = plainFund.closedEndDetails
        ? plainToClass(ClosedEndDetails, plainFund.closedEndDetails)
        : new ClosedEndDetails();
      fund.closedEndDetails.parent = fund;
      fund.closedEndDetails.closedEndKeyTerm = plainFund.closedEndDetails
        ?.closedEndKeyTerm
        ? plainToClass(
            ClosedEndKeyTerm,
            plainFund.closedEndDetails.closedEndKeyTerm
          )
        : new ClosedEndKeyTerm();
      fund.closedEndDetails.closedEndStructure = plainFund.closedEndDetails
        ?.closedEndStructure
        ? plainToClass(
            ClosedEndStructure,
            plainFund.closedEndDetails.closedEndStructure
          )
        : new ClosedEndStructure();
      fund.closedEndDetails.closedEndGPInformation = plainFund.closedEndDetails
        ?.closedEndGPInformation
        ? plainToClass(
            ClosedEndGPInformation,
            plainFund.closedEndDetails.closedEndGPInformation
          )
        : new ClosedEndGPInformation();
      fund.closedEndDetails.closedEndGPTargetInvestmentStat = plainFund
        .closedEndDetails?.closedEndGPTargetInvestmentStat
        ? plainToClass(
            ClosedEndGPTargetInvestmentStat,
            plainFund.closedEndDetails.closedEndGPTargetInvestmentStat
          )
        : new ClosedEndGPTargetInvestmentStat();
      fund.closedEndDetails.closedEndTargetReturnProfile = plainFund
        .closedEndDetails?.closedEndTargetReturnProfile
        ? plainToClass(
            ClosedEndTargetReturnProfile,
            plainFund.closedEndDetails.closedEndTargetReturnProfile
          )
        : new ClosedEndTargetReturnProfile();
      fund.secondaryDetails = plainFund.secondaryDetails
        ? plainToClass(SecondaryDetails, plainFund.secondaryDetails)
        : new SecondaryDetails(fund.fundId);
    }

    fund.coInvestmentDetails = plainFund.coInvestmentDetails
      ? plainToClass(CoInvestmentDetails, plainFund.coInvestmentDetails)
      : new CoInvestmentDetails(fund.fundId);

    switch (liquidityStructure) {
      case LiquidityStructureEnum.OpenEnd: {
        fund.shareClasses = plainFund.shareClasses.map((shareclass) =>
          plainToClass(OpenShareClass, shareclass)
        );
        fund.shareClasses.forEach(
          (fclass) =>
            (fclass.state =
              source.shareClasses.find(
                (sclass) => sclass.classId === fclass.classId
              )?.state ?? 0)
        );
        break;
      }
      case LiquidityStructureEnum.ClosedEnd: {
        fund.shareClasses = plainFund.shareClasses.map((shareclass) =>
          plainToClass(ClosedShareClass, shareclass)
        );
        fund.shareClasses.forEach(
          (fclass) =>
            (fclass.state =
              source.shareClasses.find(
                (sclass) => sclass.classId === fclass.classId
              )?.state ?? 0)
        );
        break;
      }
      case LiquidityStructureEnum.Hybrid: {
        fund.shareClasses = plainFund.shareClasses.map((shareclass) =>
          plainToClass(HybridShareClass, shareclass)
        );
        break;
      }
    }
    return fund;
  }
}
