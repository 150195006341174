export enum AuthenticationStepEnum {
    NotAuthenticated = 'Not Authenticated',
    VerifyingCredentials = 'Verifying Credentials',
    Requested2FA = 'Two Factor Code is Required',
    Verifying2FA = 'Verifying Two Factor Code',
    IsAuthenticated = 'Authenticated',
    RejectedAuthentication = 'Authentication is rejected'
}

export enum AuthenticationResponseCode {
    UsernameInvalid,
    UserNotFound,
    PasswordIncorrect,
    NeedVerificationCode,
    VerificationCodeExpired,
    VerificationCodeIncorrect,
    VerificationCodeMaxAttempts,
    SessionExpired,
    LocationForbidden,
    Success,
    NeedVerificationCodeAddMobile
}

export enum ChangesStrategy {
  First = 'firstTime', Each = 'eachTime', NonFirst = 'nonFirst'
}

export enum DateView {
  Day = 'Day', Month = 'Month', Year = 'Year'
}

export enum Anchor {
  Left = 'Left', Right = 'Right'
}

export enum PopoverStaticPosition {
  RightBottom = 7,
  RightTop = 1,
  LeftBottom = 2,
  LeftTop = 3,
  CenterBottom = 4,
  CenterTop = 5,
  Center = 6,
}

export enum DropdownMode {
  idle = 1, selecting, adding, renaming
}

export enum ModelState {
  HasError = -4,
  HasWarning = -3,
  NotSaved = -2,
  Saved = -1,
  Ready = 0,
  IsDirty,
  Loading,
  Saving
}

export namespace ModelState {
  export function toName(value: number) {
    return ModelState[value]?.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}

export enum ValidationStyleEnum {
    Idle,
    Soft,
    Hard
}

export enum LabelPositionEnum {
  Top = 'Top',
  Left = 'Left',
  Bottom = 'Bottom',
  Right = 'Right',
  Embedded = 'Embedded'
}

export enum StorageTypeEnum {
  Database = 1,
  LocalStorage,
  DatabaseAndLocalStorage
}

export enum LogCategory {
  RetrieveData = "Retrieve Data",
  SaveData = "Save Data",
  UpdateValue = "Update Value"
}