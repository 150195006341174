import { SpreadSheetSettings, Cell, toEndOfMonth } from "@aksia-monorepo/shared-ui";
import { HttpErrorResponse } from "@angular/common/http";
import { Worksheet } from "exceljs";
import { AUM } from "..";
import { AUMSourceEnum, EntityTypeEnum } from "../../enums/enums";

export class AUMSpreadSheet {
    entityId: number;
    entityTypeId: number;
    entityStartDate: Date;
    aumAmounts: Array<number> = [];
    isVisible: boolean = false;
    showHistory: boolean = false;
    showHistoryToggle: boolean = true;
    showCloseToggle: boolean = true;
    hasFullTrackRecord: boolean = false;
    settings: SpreadSheetSettings = null;

    aumToSpreadSheet(aum: Array<AUM>){
        if (this.entityStartDate){
            const dataAndRows = this.getBulkAumData(aum, this.entityStartDate.getFullYear());
            let ranges = this.getAUMRanges(this.entityStartDate, this.settings.columnTitles, dataAndRows[0], dataAndRows[2], dataAndRows[3]);
            this.aumAmounts = ranges.data;
            this.settings.rowTitles = ranges.rowTitles;
            this.settings.cellStatus = ranges.cellStatus;
            this.settings.cellInfo = ranges.cellInfo;
        }
    }

    spreadSheetToAUM(aumAmounts: Array<number>, aums: Array<AUM>, source: AUMSourceEnum, currency: string): { new: Array<AUM>, modified: Array<AUM> } {
        let aumModified:Array<AUM> = [];
        let aumNew:Array<AUM> = [];
        aumAmounts.forEach((amount: number, i: number) => {
            const yearIndex = Math.floor(i / this.settings.columns);
            const currentYear = +this.settings.rowTitles[yearIndex];
            const currentMonth = i - yearIndex * this.settings.columns + 1;
            this.aumAmounts[i] = amount;

            const currentAum = aums.find(x => x.asOfDate.getFullYear() === currentYear && x.asOfDate.getMonth() === currentMonth - 1);
            if (!currentAum && amount) {
              let newAum = new AUM(this.entityId,this.entityTypeId);
              newAum.asOfDate = new Date(currentYear, currentMonth, 0);
              newAum.amount = amount;
              newAum.source = source;
              newAum.currency = currency;
              aums.push(newAum);
              aumNew.push(newAum);
            } 
            else if (currentAum && currentAum.amount != amount) {
                currentAum.isDeleted = !amount;
                currentAum.source = (currentAum.isDeleted) ? currentAum.source : source;
                currentAum.amount = amount;
                currentAum.currency = currentAum.currency ?? currency;
                aumModified.push(currentAum);
            }
        });
        return { new: aumNew, modified: aumModified };
    }

    excelToSpreadSheet(worksheet: Worksheet) {
        if (worksheet){

        }
    }

    handleAUMErrors(errorResp: HttpErrorResponse){
        let newSettings = this.settings;
        let errors = errorResp.error?.split(/,(?=(((?!\]).)*\[)|[^\[\]]*$)/g);

        errors.forEach(error => {
            let aumErrorMessage: string = error;
            let aumAsOf: string;
            let values = (error as string).match("\\[[^\\]]*]");
            if (values) {
                aumAsOf = values[0]?.split(',')[3];
                let cellIndex = this.getCellIndexByAsOf(aumAsOf);
                newSettings.cellStatus[cellIndex] = 'error';
                //newSettings.cellInfo[cellIndex] = aumErrorMessage;
            }
        });
        this.settings = newSettings;
    }

    private getBulkAumData(aums: Array<AUM>, startYear: number): any[] {
        // create the years array
        const sortedYears = aums.length == 0 ? [new Date().getFullYear()] : aums?.sort((a: AUM, b: AUM) => a?.asOfDate.getFullYear() - b?.asOfDate.getFullYear()).map(x => x?.asOfDate.getFullYear());
        let maxYear = new Date().getFullYear();
        const minYear = !startYear || startYear == null || startYear > sortedYears[0] ? sortedYears[0] : startYear;
        const aumAmounts: number[] = [];
        const aumYears: number[] = [];
        const aumStatus: string[] = [];
        const aumInfo: Array<any> = [];

        while(minYear <= maxYear) {
            aumYears.push(maxYear);
            for (let month = 0; month < 12; month++) {
                let curAum = aums.find(x => new Date(x?.asOfDate).getFullYear() == maxYear && new Date(x?.asOfDate).getMonth() == month);
                aumAmounts.push(!curAum ? null : curAum.amount);
                aumStatus.push(curAum?.relation ? 'inherited' : 'ready');
                aumInfo.push({ metaId: curAum?.metaId, bgColorClass: curAum?.bgColorFromClassification, data: toEndOfMonth(new Date(maxYear, month, 1)) });
            }
            maxYear--;
        }

        return [aumAmounts, aumYears, aumStatus, aumInfo]
    }

    private getAUMRanges(minDate: Date, columnTitles: Array<string>, data: Array<number> = null, aumStatus: Array<string>, aumInfo: Array<string>) 
    : { data: Array<number>, rowTitles: Array<string>, cellStatus: Array<string>, cellInfo: Array<string> } {
        let ranges = {
            data: [],
            rowTitles: [],
            cellStatus: [],
            cellInfo: []
        }
        ranges.data, ranges.rowTitles, ranges.cellStatus, ranges.cellInfo = [];

        let minYM = new Date(minDate).getFullYear() * 100 + new Date(minDate).getMonth();
        let maxYM = new Date().getFullYear() * 100 + new Date().getMonth() - 1;  

        Array.from({ length: (new Date().getFullYear() - new Date(minDate).getFullYear() + 1) }, (_, i) => {
            ranges.rowTitles.push((new Date().getFullYear() - i).toString());
            Array.from(Array(12), (_,j) => {
                let currentDate =  new Date(columnTitles[j%12] + '-' + (new Date().getFullYear() - i).toString());
                let date = currentDate.getFullYear() * 100 + currentDate.getMonth();
                if (date >= minYM && date <= maxYM) {
                    ranges.cellStatus.push(aumStatus[i*12+j]);
                    ranges.cellInfo.push(aumInfo[i*12+j]);
                    if (data){
                        ranges.data.push(data[i*12+j]);
                    }
                }
                else {
                    if (data){
                        ranges.data.push(null);
                    }
                    ranges.cellStatus.push('disabled');
                    ranges.cellInfo.push(null);
                }
            });
        });
        return ranges;
    }

    getCellIndexByAsOf(asOf: string): number {
        let colIndex = this.settings.columnTitles.indexOf(asOf?.split('-')[0]?.trim());
        let rowIndex = this.settings.rowTitles.indexOf(asOf?.split('-')[1]?.trim());
        return rowIndex * this.settings.columns + colIndex;
    }

    constructor(entityId: number, entityTypeId: number, entityStartDate: Date, columnTitles: Array<string>, rowTitles: Array<string>, disabledCells: Array<boolean>) {
        this.entityId = entityId;
        this.entityTypeId = entityTypeId;
        this.entityStartDate = entityStartDate;
        this.settings = new SpreadSheetSettings(columnTitles,rowTitles,disabledCells);
        this.settings.selectedCell = new Cell(0,0);
    }
}