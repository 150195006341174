import {
  Meta,
  IMetaState,
  meta,
  ValidationStyleEnum,
  ModelState,
  toLocalDate,
  DefaultValidationMessages,
  getAbbr,
} from '@aksia-monorepo/shared-ui';
import { Transform, Type } from 'class-transformer';
import {
  CurrencyEnum,
  SimpleAnswerEnum,
  MgmtFeeFrequencyEnum,
  CarriedInterestRateTypeEnum,
  CarriedInterestTieredBaseEnum,
  PrefReturnEnum,
  WaterfallEnum,
  ClawbackGuaranteeEnum,
  MgmtFeeFromEnum,
  MgmtFeeToEnum,
  DataSourceEnum,
  VEntityTypeEnum,
} from '../../enums/enums';
import {
  MgmtFeePeriod,
  MgmtFeeAmount,
  Discount,
  CarriedInterestTieredRate,
} from '../entities/entities.model';
import { Fund } from '../fund/fund.model';

export class ClosedShareClass extends Meta implements IMetaState {
  classType = 'ClosedShareClass';
  auditRoute = 'closedend';
  classId: number;
  fundId: number;
  className: string = 'Default Class';
  isDefault = false;
  isDeleted = false;
  __group = 'className';

  @meta({ alias: 'Source' })
  source?: DataSourceEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'As of' })
  asOfDate?: Date;

  //#region <<< Section - Minimum Investment >>>

  @meta({
    alias: 'Minimum LP Commitment',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule(self, value, target, prop) {
        if (!value) return (self.message = null);
        if (
          target instanceof ClosedShareClass &&
          target.grandParent instanceof Fund
        ) {
          if (
            target.grandParent.closedEndDetails?.closedEndKeyTerm?.commitmentCap
          ) {
            return (self.message =
              value >=
              target.grandParent.closedEndDetails?.closedEndKeyTerm
                ?.commitmentCap
                ? `${self.defaultMessage} ${getAbbr(
                    target.grandParent.closedEndDetails?.closedEndKeyTerm
                      ?.commitmentCap
                  )}`
                : null);
          } else if (
            target.grandParent.closedEndDetails?.closedEndKeyTerm
              ?.commitmentTarget
          ) {
            return (self.message =
              value >=
              target.grandParent.closedEndDetails?.closedEndKeyTerm
                ?.commitmentTarget
                ? `${self.defaultMessage} ${getAbbr(
                    target.grandParent.closedEndDetails?.closedEndKeyTerm
                      ?.commitmentTarget
                  )}`
                : null);
          } else {
            return (self.message = null);
          }
        }
      },
    },
    hardNotMoreOrEqualTo: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreOrEqualTo,
      validateRule(self, value, target, prop) {
        if (!value) return (self.message = null);
        if (
          target instanceof ClosedShareClass &&
          target.grandParent instanceof Fund
        ) {
          if (target.grandParent.vEntityType !== VEntityTypeEnum.CommingledFund)
            return (self.message = null);

          if (
            target.grandParent.closedEndDetails?.closedEndKeyTerm?.commitmentCap
          ) {
            return (self.message =
              value >=
              target.grandParent.closedEndDetails?.closedEndKeyTerm
                ?.commitmentCap
                ? `${self.defaultMessage} ${getAbbr(
                    target.grandParent.closedEndDetails?.closedEndKeyTerm
                      ?.commitmentCap
                  )}`
                : null);
          } else if (
            target.grandParent.closedEndDetails?.closedEndKeyTerm
              ?.commitmentTarget
          ) {
            return (self.message =
              value >=
              target.grandParent.closedEndDetails?.closedEndKeyTerm
                ?.commitmentTarget
                ? `${self.defaultMessage} ${getAbbr(
                    target.grandParent.closedEndDetails?.closedEndKeyTerm
                      ?.commitmentTarget
                  )}`
                : null);
          } else {
            return (self.message = null);
          }
        }
      },
    },
    softNotLessThan: 10_000_000,
    softNotMoreThan: 100_000_000,
  })
  minimumLPCommitment?: number;

  @meta({
    alias: 'Minimum LP Commitment (Currency)',
    source: CurrencyEnum.toKeyValue(),
    softNotEmpty: true,
  })
  minimumLPCommitmentCurrency: CurrencyEnum;

  //#endregion

  //#region <<< Section - Management Fee >>>

  @meta({
    alias: 'Management Fee',
    softNotEmpty: true,
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          const deletedPeriods = self.mgmtFeePeriods?.filter(
            (per) => per.markedForDeletion
          );
          const mgmtFeePeriod = new MgmtFeePeriod();
          mgmtFeePeriod.mgmtFeeAmounts = [new MgmtFeeAmount()];
          self.mgmtFeePeriods = deletedPeriods
            ? [...deletedPeriods, mgmtFeePeriod]
            : [mgmtFeePeriod];
        } else {
          self.mgmtFeePeriods = self.mgmtFeePeriods?.filter((per) => per.id);
          self.mgmtFeePeriods?.forEach((per) => {
            per.mgmtFeeAmounts?.forEach(
              (amt) => (amt.markedForDeletion = true)
            );
            per.markedForDeletion = true;
          });
          self.paymentFrequency = undefined;
          self.paymentFrequencyDescription = undefined;
          self.mgmtFeeOffsetRate = undefined;
          self.hasMgmtFeeDiscounts = undefined;
        }
      }
    },
  })
  mgmtFee: SimpleAnswerEnum;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'Fee Ceased' })
  mgmtFeeCeasedOn: Date;

  @Type(() => MgmtFeePeriod)
  @meta({
    navigation: true,
    alias: 'Management Fee Period ##',
    adds: (self, prop) => {
      let mgmtFeePeriod = new MgmtFeePeriod();
      const lastPeriod = self[prop][self[prop]?.length - 1];
      if (lastPeriod instanceof MgmtFeePeriod) {
        const from = MgmtFeeFromEnum[MgmtFeeToEnum[lastPeriod.mgmtFeeTimeTo]];
        mgmtFeePeriod.mgmtFeeTimeFrom = from;
        if (lastPeriod.mgmtFeeTimeTo === MgmtFeeToEnum.Other) {
          mgmtFeePeriod.mgmtFeeTimeFromDesc = lastPeriod.mgmtFeeTimeToDesc;
        }
      }
      mgmtFeePeriod.add('mgmtFeeAmounts');
      self[prop] = [...(self[prop] ?? []), mgmtFeePeriod];
      if (self.hasModified('mgmtFeePeriods')) {
        self[prop].forEach((item) => {
          item?.validateAll?.();
          item?.updateState(ModelState.IsDirty);
        });
      }
    },
    removes: 'soft',
  })
  mgmtFeePeriods: Array<MgmtFeePeriod>;

  @meta({
    alias: 'Payment Frequency',
    source: MgmtFeeFrequencyEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value === MgmtFeeFrequencyEnum.Other) {
          self.paymentFrequencyDescription = undefined;
        }
      }
    },
  })
  paymentFrequency: MgmtFeeFrequencyEnum;

  @meta({ alias: 'Description' })
  paymentFrequencyDescription: string;

  @meta({
    alias: 'Management Fee Offset %',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotLessThan: 50,
  })
  mgmtFeeOffsetRate: number;

  @meta({
    alias: 'Management Fee Discount',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          let deletedDiscounts = self.mgmtFeeDiscounts?.filter(
            (per) => per.markedForDeletion
          );
          self.mgmtFeeDiscounts = deletedDiscounts
            ? [...deletedDiscounts, new Discount()]
            : [new Discount()];
        } else {
          self.mgmtFeeDiscounts = self.mgmtFeeDiscounts?.filter(
            (dis) => dis.id
          );
          self.mgmtFeeDiscounts?.forEach(
            (dis) => (dis.markedForDeletion = true)
          );
        }
      }
    },
  })
  hasMgmtFeeDiscounts: SimpleAnswerEnum;

  @Type(() => Discount)
  @meta({
    navigation: true,
    adds: 'Discount',
    removes: 'soft',
    auditRoute: 'MgmtFeeDiscounts',
  })
  mgmtFeeDiscounts: Discount[];

  //#endregion

  //#region <<< Section - Carried Interest >>>

  @meta({
    alias: 'Carried Interest',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.carriedInterestRateType = undefined;
          self.carriedInterestPrefReturnType = undefined;
          self.carriedInterestRate = undefined;
          self.carriedInterestPrefReturnRate = undefined;
          self.carriedInterestCatchUpRate = undefined;
          self.carriedInterestTieredBaseType = undefined;
          self.carriedInterestWaterfall = undefined;
          self.gpClawback = undefined;
          self.hasCarriedInterestDiscounts = undefined;
        }
      }
    },
  })
  carriedInterest: SimpleAnswerEnum;

  @meta({
    alias: 'Carried Interest (Rate Type)',
    source: CarriedInterestRateTypeEnum.toKeyValue(),
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value !== CarriedInterestRateTypeEnum.TieredRate) {
          self.carriedInterestTieredBaseType = undefined;
        }
        if (value !== CarriedInterestRateTypeEnum.SingleRate) {
          self.carriedInterestRate = undefined;
        }
      }
    },
  })
  carriedInterestRateType: CarriedInterestRateTypeEnum;

  @meta({
    alias: 'Carried Interest (Based On)',
    source: CarriedInterestTieredBaseEnum.toKeyValue(),
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        self.carriedInterestTieredRates =
          self.carriedInterestTieredRates?.filter((dis) => dis.id);
        self.carriedInterestTieredRates?.forEach(
          (dis) => (dis.markedForDeletion = true)
        );

        if (value) {
          let deletedTieredRates = self.carriedInterestTieredRates?.filter(
            (per) => per.markedForDeletion
          );
          self.carriedInterestTieredRates = deletedTieredRates
            ? [
                ...deletedTieredRates,
                new CarriedInterestTieredRate(),
                new CarriedInterestTieredRate(),
              ]
            : [
                new CarriedInterestTieredRate(),
                new CarriedInterestTieredRate(),
              ];
        }
      }
    },
  })
  carriedInterestTieredBaseType: CarriedInterestTieredBaseEnum;

  @Type(() => CarriedInterestTieredRate)
  @meta({
    navigation: true,
    adds: 'CarriedInterestTieredRate',
    removes: 'soft',
    auditRoute: 'CarriedInterestTieredRates',
  })
  carriedInterestTieredRates: Array<CarriedInterestTieredRate>;

  @meta({
    alias: 'Carried Interest %',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 5,
    softNotMoreThan: 30,
  })
  carriedInterestRate?: number;

  @meta({
    alias: 'Pref Return',
    source: PrefReturnEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (
          value !== PrefReturnEnum.YesWithCatch_Up &&
          value !== PrefReturnEnum.YesWithoutCatch_Up
        ) {
          self.carriedInterestPrefReturnRate = undefined;
          self.carriedInterestCatchUpRate = undefined;
        } else if (value === PrefReturnEnum.YesWithoutCatch_Up) {
          self.carriedInterestCatchUpRate = undefined;
        }
      }
    },
  })
  carriedInterestPrefReturnType?: PrefReturnEnum;

  @meta({
    alias: 'Pref Return %',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotEmpty: true,
    softNotLessThan: 5,
    softNotMoreThan: 10,
  })
  carriedInterestPrefReturnRate?: number;

  @meta({
    alias: 'Catch-Up',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
    softNotLessThan: 50,
  })
  carriedInterestCatchUpRate?: number;

  @meta({
    alias: 'Waterfall',
    source: WaterfallEnum.toKeyValue(),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value !== WaterfallEnum.Other) {
          self.carriedInterestWaterfallDesc = undefined;
        }
      }
    },
  })
  carriedInterestWaterfall?: WaterfallEnum;

  @meta({
    alias: 'Waterfall (Description)',
    softNotEmpty: true,
  })
  carriedInterestWaterfallDesc?: string;

  @meta({
    alias: 'Fair Value Test',
    hardNotLessThan: 100,
    hardNotMoreThan: 250,
    softNotLessThan: 110,
    softNotMoreThan: 175,
  })
  carriedInterestWaterfallFairValueTest?: number;

  @meta({
    alias: 'GP Clawback',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.interimCalculations = undefined;
          self.escrowAccount = undefined;
          self.clawbackGuaranteeType = undefined;
        }
      }
    },
  })
  gpClawback: SimpleAnswerEnum;

  @meta({
    alias: 'Interim Calculations',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  interimCalculations: SimpleAnswerEnum;

  @meta({
    alias: 'Escrow Account',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value !== SimpleAnswerEnum.Yes) {
          self.escrowAccountRate = undefined;
        }
      }
    },
  })
  escrowAccount: SimpleAnswerEnum;

  @meta({
    alias: 'Escrow Account (Rate)',
    hardNotLessOrEqualTo: 0,
    hardNotMoreThan: 100,
  })
  escrowAccountRate: number;

  @meta({
    alias: 'Clawback Guarantee',
    source: ClawbackGuaranteeEnum.toKeyValue(),
  })
  clawbackGuaranteeType: ClawbackGuaranteeEnum;

  @meta({
    alias: 'Carried Interest Discount',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
    softNotEmpty: true,
    updates: (self, value) => {
      if (self instanceof ClosedShareClass) {
        if (value === SimpleAnswerEnum.Yes) {
          let deletedDiscounts = self.carriedInterestDiscounts?.filter(
            (per) => per.markedForDeletion
          );
          self.carriedInterestDiscounts = deletedDiscounts
            ? [...deletedDiscounts, new Discount()]
            : [new Discount()];
        } else {
          self.carriedInterestDiscounts = self.carriedInterestDiscounts?.filter(
            (dis) => dis.id
          );
          self.carriedInterestDiscounts?.forEach(
            (dis) => (dis.markedForDeletion = true)
          );
        }
      }
    },
  })
  hasCarriedInterestDiscounts: SimpleAnswerEnum;

  @Type(() => Discount)
  @meta({
    navigation: true,
    adds: 'Discount',
    removes: 'soft',
    auditRoute: 'CarriedInterestDiscounts',
  })
  carriedInterestDiscounts: Discount[];

  //#endregion

  //#region <<< Section - Discounts >>>

  @meta({
    alias: 'First Close Discount',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  hasFirstCloseDiscount?: SimpleAnswerEnum;

  @meta({
    alias: 'Size Threshold Discount',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  hasSizeThresholdDiscount?: SimpleAnswerEnum;

  @meta({
    alias: 'Other Discount',
    source: SimpleAnswerEnum.toKeyValue().filter(
      (sa) => sa.key !== SimpleAnswerEnum.NotSpecified
    ),
  })
  hasOtherDiscount?: SimpleAnswerEnum;

  //#endregion

  auditURL = 'shareclass/audit/closedend/?propertyPaths=ShareClass_{0}@{1}';
  auditURLParams = ['classId@model', '@prop'];

  constructor(fundId: number, className?: string, isDefault = false) {
    super();
    this.fundId = fundId;
    this.state = ModelState.Ready;
    this.className = className ?? 'Default Class';
    this.isDefault = isDefault;
  }
}
