import { meta, Meta, ModelState } from "@aksia-monorepo/shared-ui";
import { RankEnum, SimpleAnswerEnum } from "../../enums/enums";

export class ClosedEndGPInformation extends Meta {
    closedEndDataFieldsId?: number;
    
    //#region Structure

    @meta({ alias: '3rd Party Management Company Interest', source: SimpleAnswerEnum.toKeyValue().filter(sa => sa.key !== SimpleAnswerEnum.NotSpecified) })
    thirdPartyManagement?: SimpleAnswerEnum;

    //#endregion

    //#region Key Considerations

    @meta({ alias: 'Commingled Fund by Manager', source: RankEnum.toKeyValue().filter(sa => sa.key !== RankEnum._4th) })
    commingledFundByManager?: RankEnum

    @meta({ alias: 'Commingled Fund in Series', source: RankEnum.toKeyValue().filter(sa => sa.key !== RankEnum._4th) })
    commingledFundInSeries?: RankEnum

    //#endregion
    
    modifiedBy?: string;

    auditURL = 'basicdata/fund/audit/{0}/closedend/{1}/closedEndGPInformation/{2}';
    auditURLParams = ['grandParent.fundId@model','parent.id@model','@prop'];

    constructor() {
        super();
        this.state = ModelState.Ready;
        this.excludeFromNotePath = true;
    }
}