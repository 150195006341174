<div class="ui-control rows3" [class]="labelPositionEnum[settings.labelPosition]" [class.disabled]="disabled">
    <label-v2 class="ui-header" [class.checked]="(value$ | async)"
        [label]="focused || !!(value$ | async) ? (settings.labelAlt || settings.label) : settings.label" 
        [class.focused]="focused || !!(value$ | async)" 
        [tooltip]="settings.tooltip"
        (click)="check.click();focusin()"> 
    </label-v2> 
    <input class="ui-body" #check [class.checked]="(value$ | async)" [checked]="(value$ | async)" [disabled]="disabled"
        type="checkbox"
        (click)="focusout()"
    />
    <div class="ui-toolbar r2s1" [class.checked]="(value$ | async)">
        <ng-template inject></ng-template>
        <ng-content></ng-content>
    </div>
    <validation-v2 class="ui-footer r3s1 c1s20" *ngIf="state === modelState.HasError" [class]="settings.footerAlignment" [validations]="validations"></validation-v2>
</div>