<div class="ui-control grow-y rows3" [class]="[labelPositionEnum[settings.labelPosition],modelState[state]]" [class.disabled]="disabled">
    <label-v2 class="ui-header" [label]="settings.label" [class.focused]="focused || (value$ | async)?.length > 0" [tooltip]="settings.tooltip" (click)="focusin(true)"></label-v2>
    <div class="item-container r2s1" (click)="focusin()">
        <div class="item-wrapper" *ngFor="let item of (value$ | async) as items">
            <span class="item">{{item[settings.value]}}</span>
            <fa-icon class="item-remove" [icon]="faMinus" (click)="select(item, true)"></fa-icon>
        </div>
    </div>
    <div *ngIf="popUp">
        <ul #list class="ui-list multiple"
            [class.uniqueViolation]="uniqueViolation" 
            [class]="[settings.alignList, settings.justifyList]"
            [class.canFilter]="settings.canFilter">
            <li *ngIf="settings.canFilter" class="unselectable keepOpen filter-container" [class.hasFilter]="!!filterValue">
                <fa-icon *ngIf="!filterValue" class="c1s1 keepOpen noevent" [icon]="faFilter"></fa-icon>
                <span *ngIf="filterValue" class="c1s1 keepOpen event" (click)="clearSearch()"><fa-icon class="noevent" [icon]="faTimes"></fa-icon></span>
                <input class="c2s9 filter keepOpen" #search
                    [(ngModel)]="filterValue"
                    placeholder="Search" 
                    (keyup)="filter()"/>
            </li>
            <ng-container *ngFor="let groupItem of (filteredSource || source);trackBy:trackBy;">
                <span *ngIf="groupItem[0]" class="group keepOpen" [attr.title]="groupItem[0]">{{groupItem[0]}}</span>
                <li *ngFor="let item of groupItem[1];trackBy:trackBy; let i = index; let first = first" class="keepOpen"
                    [class.selected]="(value$ | async | includesOrEquals:item)"
                    [attr.e2e-id]="settings.e2eId ? settings.e2eId + '_' + item[settings.key] : null"
                    (click)="select(item)">
                    <fa-icon class="c1s1 keepOpen noevent" [class.selected]="(value$ | async | includesOrEquals:item:'id')" [icon]="(value$ | async | includesOrEquals:item:'id') ? faCheckSquare: faSquare"></fa-icon>
                    <span class="c2s9 keepOpen">{{item[settings.value]}}</span>
                </li>
            </ng-container>
        </ul> 
    </div>
    <div class="ui-toolbar r2s1">
        <fa-icon class="r1s1" e2e-id="reset" [icon]="faTimes" *ngIf="(settings.canReset && !disabled && !!(value$ | async))" (click)="resetOrCancel(input)"></fa-icon>
        <ng-template inject></ng-template>
        <ng-content></ng-content>
    </div>
    <div class="ui-toolbar r2s1 Pinned">
        <ng-content select="[pinned]"></ng-content>
    </div>
    <div class="ui-icon r2s1 pointer" (click)="focusin()">
        <fa-icon [icon]="faChevronDown"></fa-icon>
    </div>
    <validation-v2 class="ui-footer r3s1 c1s20" [class]="settings.footerAlignment" [validations]="validations | async"></validation-v2>
</div>
