import { Transform, Type, Expose, plainToClass } from 'class-transformer';
import {
  CompanyStatusEnum,
  EntityTypeEnum,
  LiquidityStructureEnum,
  ProgramLiquidityStructureEnum,
} from '../../enums/enums';
import {
  DefaultValidationMessages,
  inMetaFactory,
  meta,
  Meta,
  ModelState,
  SpreadSheetSettings,
  toLocalDate,
  ValidationStyleEnum,
} from '@aksia-monorepo/shared-ui';
import { Note } from '../note/note.model';
import { ProgramFund } from './programFund.model';
import { AUMeta } from '../aum/aum.meta';
import { AUMSpreadSheet } from '../aum/aum.spreadsheet';
import { Address } from '../entities/entities.model';
import { StreamMeta } from '../stream/stream.meta';
import { PERIODIC_STREAMS } from '../../services/periodic-streams.service';

export class ManagementCompany extends Meta {
  classType = 'ManagementCompany';
  @Expose()
  id: number;
  managementCompanyId: number;

  @Expose()
  dataProviderEntityId?: number;

  @Expose()
  name: string;

  companyType: string;

  @Expose()
  createdOn?: string;

  @Expose()
  createdBy: string;

  funds: Array<{ key: number; value: string }>;

  __group = 'name';

  //#region <<< Section - Status >>>

  @meta({ alias: 'Status', source: CompanyStatusEnum.toKeyValue() })
  managerStatus?: CompanyStatusEnum;

  @meta({ alias: 'Sensitive', isInaudible: true })
  isSensitive?: boolean;

  @meta({ alias: 'Key', isInaudible: true })
  key?: boolean;

  //#endregion

  //#region <<< Section - Historical Info >>>

  @meta({ alias: 'Former Names', isInaudible: true })
  formerNames?: string;

  //#endregion

  //#region <<< Section - Manager Overview >>>

  @meta({ alias: 'Manager Overview' })
  managerOverview?: string;

  //#endregion

  //#region <<< Section - Description >>>

  @meta({ alias: 'Internal Notes', hardNotMoreThan: 1000, isInaudible: true })
  description?: string;

  //#endregion

  //#region <<< Section - AUM >>>

  @meta({ alias: '1st of Month' })
  isAumFirstMonth?: boolean = false;

  @meta({ alias: 'Quarterly' })
  isAumQuarterly?: boolean = false;

  @Type(() => StreamMeta)
  aumMeta: StreamMeta;

  getInceptionDate(investmentProgramId: number = null) {
    if (this.inceptionDate) {
      return this.inceptionDate;
    }
    let defaultDate = new Date(new Date().getFullYear(), 0, 1);
    let programs = this.investmentPrograms.filter(
      (ip) =>
        ip.investmentProgramId == investmentProgramId || !investmentProgramId
    );
    let allFunds =
      programs.length > 0
        ? programs.map((ip) => ip.funds).reduce((acc, curr) => acc.concat(curr))
        : [];
    return allFunds.length > 0
      ? new Date(
          Math.min(
            ...allFunds.map((f) =>
              (
                f.commencementOfOperations ??
                f.dateOfFormation ??
                defaultDate
              ).getTime()
            )
          )
        )
      : defaultDate;
  }

  updateSpreadsheets() {
    let streams = [PERIODIC_STREAMS.AUM];

    streams.forEach((streamName) => {
      if (this[`${streamName}Meta`]) {
        this[`${streamName}Meta`].streamStartDate = this.getInceptionDate();
      }

      if (
        this[`${streamName}Meta`]?.streamSpreadSheet &&
        this[`${streamName}Meta`].updateSpreadsheet
      ) {
        this[`${streamName}Meta`].updateSpreadsheet();

        if (this[`${streamName}Meta`].streamSpreadSheet.isVisible) {
          this[`${streamName}Meta`].streamSpreadSheet.isVisible = false;
          setTimeout(
            () =>
              (this[`${streamName}Meta`].streamSpreadSheet.isVisible = true),
            100
          );
        }
      }
    });
  }

  //#endregion

  //#region <<< Section - Basic Info >>>

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Date Established',
    softNotMoreThan: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotMoreThan,
      validateRule: (self, value, target, prop) => {
        return target.inceptionDate > new Date()
          ? (self.message = 'Is usually not occuring in the future')
          : (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof ManagementCompany) {
        self.updateSpreadsheets();
      }
    },
  })
  inceptionDate?: Date;

  @meta({ alias: 'Affiliates' })
  isAffiliate?: boolean;

  @meta({ alias: 'Name of Document(s)' })
  physicalFileName?: string;

  @meta({ alias: 'Source', isGlobalAuditor: true })
  dataSource?: number;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({ alias: 'As of', isGlobalAuditor: true })
  dataAsOfDate?: Date;

  @Type(() => Address)
  @Transform(
    ({ value }) => {
      return (value as Address[])?.length > 0
        ? (value as Address[]).sort((a, b) =>
            a.isPrimary ? -1 : a.addressId < b.addressId ? -1 : 1
          )
        : [new Address()];
    },
    { toClassOnly: true }
  )
  @meta({
    navigation: true,
    adds: 'Address',
    removes: 'hard',
  })
  addresses?: Array<Address>;

  @Transform(({ value }) => toLocalDate(value), { toClassOnly: true })
  @meta({
    alias: 'Staff As of',
    softNotEmpty: {
      type: ValidationStyleEnum.Soft,
      defaultMessage: DefaultValidationMessages.softNotEmpty,
      validateRule: (self, value, target, prop) => {
        return (target.totalStaff || target.nonInvestmentStaff) && !value
          ? (self.message = self.defaultMessage)
          : (self.message = null);
      },
    },
  })
  staffAsOf?: Date;

  @meta({
    alias: 'Total Staff',
    hardNotLessThan: {
      value: Number.NEGATIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotLessThan,
      validateRule: (self, value, target, prop) => {
        prop = 'nonInvestmentStaff';
        return target[prop] && target[prop] >= value
          ? `${self.defaultMessage} ${target[prop]}`
          : (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof ManagementCompany) {
        self.validate('nonInvestmentStaff');
      }
    },
  })
  totalStaff?: number;

  @meta({
    alias: 'Non-Investment Staff',
    hardNotMoreThan: {
      value: Number.POSITIVE_INFINITY,
      type: ValidationStyleEnum.Hard,
      defaultMessage: DefaultValidationMessages.hardNotMoreThan,
      validateRule: (self, value, target, prop) => {
        prop = 'totalStaff';
        return target[prop] && target[prop] <= value
          ? `${self.defaultMessage} ${target[prop]}`
          : (self.message = null);
      },
    },
    updates: (self, value) => {
      if (self instanceof ManagementCompany) {
        self.validate('totalStaff');
      }
    },
  })
  nonInvestmentStaff?: number;

  @meta({ alias: 'General Email' })
  generalEmail?: string;

  @meta({ alias: 'URL' })
  url?: string;

  sortAddresses() {
    this.addresses.sort((a, b) =>
      a.isPrimary ? -1 : a.addressId < b.addressId ? -1 : 1
    );
  }

  //#endregion

  //#region <<< Section - Program >>>

  programState: ModelState;
  programAUMState: ModelState;

  @Type(() => Program)
  @meta({ navigation: true })
  investmentPrograms?: Program[];

  //#endregion

  //#region <<< Section - Identification >>>

  @meta({
    alias: 'CRD',
    isInaudible: true,
  })
  managerCRD?: number;

  @meta({
    alias: 'CIK',
    isInaudible: true,
  })
  managerCIK?: number;

  //#endregion

  //#region <<< Section - Notes >>>

  @Expose()
  @Type(() => Note)
  @Transform(({ value }) => value ?? new Map())
  _notes: Map<string, Note> = new Map();

  //#endregion

  auditURL = 'basicdata/managementcompany/audit/{0}/{1}';
  auditURLParams = ['managementCompanyId@model', '@prop'];

  constructor(managementcompanyId: number = null) {
    super();
    this.managementCompanyId = managementcompanyId;
    this.state = ModelState.Ready;
    this.programState = ModelState.Ready;
  }
}

@inMetaFactory
export class Program extends Meta {
  classType = 'Program';
  investmentProgramId: number = null;
  dataProviderEntityId?: number;

  @meta({ alias: 'Name', hardNotEmpty: true, isInaudible: true })
  name: string;

  managementCompanyId: number;
  taxonomyId: number = null;
  __group = 'name';

  @meta({
    alias: 'Sector',
    isInaudible: true,
    hardNotEmpty: true,
    updates: (self: Program, value) => {
      if (self instanceof Program) {
        self.strategyId = null;
      }
    },
  })
  sectorId?: number;

  @meta({
    alias: 'Strategy',
    isInaudible: true,
    hardNotEmpty: true,
    updates: (self: Program, value) => {
      if (self instanceof Program) {
        self.substrategyId = null;
      }
    },
  })
  strategyId?: number;

  @meta({
    alias: 'Sub-strategy',
    isInaudible: true,
    hardNotEmpty: true,
  })
  substrategyId?: number;

  @meta({
    alias: 'Primary Region',
    isInaudible: true,
    hardNotEmpty: true,
  })
  primaryRegionId?: number;

  @meta({ alias: '1st of Month', isInaudible: true })
  isAumFirstMonth?: boolean;

  @meta({ alias: 'Master', isInaudible: true })
  isAumMaster?: boolean;

  @meta({ alias: 'Quarterly', isInaudible: true })
  isAumQuarterly?: boolean;

  @Type(() => Date)
  @Expose({ groups: ['meta'] })
  private _inceptionDate?: Date = undefined;

  get inceptionDate() {
    if (this._inceptionDate == undefined) {
      this._inceptionDate = this.getInceptionDate();
    }
    return this._inceptionDate;
  }

  @Expose({ groups: ['meta'] })
  private _programLiquidity: ProgramLiquidityStructureEnum = null;

  get programLiquidity(): ProgramLiquidityStructureEnum {
    if (
      this._programLiquidity === undefined ||
      this._programLiquidity === null
    ) {
      this._programLiquidity = ProgramLiquidityStructureEnum.ClosedEnd;
      this.funds?.forEach((fund) => {
        if (fund.liquidityStructure === LiquidityStructureEnum.OpenEnd) {
          this._programLiquidity = ProgramLiquidityStructureEnum.OpenEnd;
          return;
        }
      });
    }

    return this._programLiquidity;
  }

  @Type(() => ProgramFund)
  @meta({ isInaudible: true })
  funds: Array<ProgramFund>;

  @Type(() => StreamMeta)
  aumMeta: StreamMeta;

  getInceptionDate(): Date | null {
    let minTime = Math.min(
      ...this.funds.map((f) => f.inceptionDate?.getTime()).filter((val) => val)
    );
    return Number.isFinite(minTime) ? new Date(minTime) : null;
  }

  @Expose({ groups: ['meta'] })
  get key() {
    return `_${this.investmentProgramId}_${EntityTypeEnum.Program}`;
  }

  auditURL = 'basicdata/investmentprogram/audit/{0}/{1}';
  auditURLParams = ['investmentProgramId@model', '@prop'];

  constructor() {
    super();
    this.state = ModelState.Ready;
  }
}
