<div
  class="ui-control rows3"
  [class]="[labelPositionEnum[settings.labelPosition]]"
  [class.disabled]="disabled"
>
  <label-v2
    class="ui-header"
    [label]="settings.label"
    [class.focused]="focused || !!(value$ | async)"
    [tooltip]="settings.tooltip"
    (click)="focusin(true)"
  ></label-v2>
  <input
    #input
    class="ui-body r2s1"
    type="text"
    readonly
    [placeholder]="focused || !settings.label ? settings.placeholder : ''"
    [value]="value$ | async | date: settings.format"
    [disabled]="disabled"
    (focusin)="focusin()"
    (keydown.tab)="focusout()"
    (keydown.shift.tab)="focusout()"
    (keyup.tab)="focusin()"
    (keyup.enter)="focusout()"
  />
  <div
    class="ui-calendar"
    [class]="[settings.alignCalendar, settings.justifyCalendar]"
    [hidden]="!focused"
  >
    <div class="ui-calendar-bar">
      <fa-icon
        [ngStyle]="{ opacity: settings.disableBar ? 0.2 : 1 }"
        [icon]="faCaretLeft"
        (click)="!settings.disableBar ? scroll(-1) : undefined"
      ></fa-icon>
      <span
        [ngStyle]="{ opacity: settings.disableBar ? 0.2 : 1 }"
        (click)="!settings.disableBar ? changeView() : undefined"
      >
        {{
          currentDate
            | dateTitle: settings.initView:page:settings.yearPage:pageChanged
        }}
      </span>
      <fa-icon
        [ngStyle]="{ opacity: settings.disableBar ? 0.2 : 1 }"
        [icon]="faCaretRight"
        (click)="!settings.disableBar ? scroll(1) : undefined"
      ></fa-icon>
    </div>
    <div [ngClass]="'ui-calendar-body ' + settings.initView">
      <span
        *ngFor="let item of items; let i = index"
        [ngClass]="{
          active: item.isActive,
          selected:
            (value$ | async | date: 'y') ==
              currentDate.getFullYear().toString() &&
            (value$ | async | date: 'M') ==
              (currentDate.getMonth() + 1).toString() &&
            item.value == (currentDate | date: 'd')
        }"
        (click)="item.isActive ? select(item.value, i) : false"
      >
        {{ item.value }}
      </span>
    </div>
  </div>
  <div class="ui-toolbar r2s1">
    <fa-icon
      class="r1s1"
      e2e-id="reset"
      [icon]="faTimes"
      *ngIf="settings.canReset && !disabled && !!(value$ | async)"
      (click)="reset()"
    ></fa-icon>
    <ng-template inject></ng-template>
    <ng-content></ng-content>
  </div>
  <div class="ui-icon large r2s1 pointer" (click)="focusin()">
    <fa-icon [icon]="faCalendar"></fa-icon>
  </div>
  <validation-v2
    class="ui-footer r3s1 c1s20"
    [class]="settings.footerAlignment"
    [validations]="validations | async"
  ></validation-v2>
</div>

<ng-template #tplWarn let-close="close">
  <div class="header warning">
    <h1>Warning!</h1>
  </div>
  <div class="content warning">
    <div class="message">
      <p>A future date has been chosen. Do you want to proceed?</p>
    </div>
  </div>
  <div class="footer warning">
    <button
      class="framed"
      e2e-id="btn_page_modal_ok"
      (click)="warningCallback(true)"
    >
      Okay
    </button>
    <button
      class="framed"
      e2e-id="btn_page_modal_cancel"
      (click)="warningCallback(false)"
    >
      Cancel
    </button>
  </div>
</ng-template>
